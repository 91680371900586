import createClient, { Middleware } from 'openapi-fetch';
import baseUrl from './baseUrl.json';
import type { paths, components } from './schema';
import type { path } from './graphSchema';
import { authEvent, needsAuth } from 'helpers/Auth';
import { Skill } from '../models/Skill';
import { LlmModel } from '../models/LlmModel';
import * as assets from '../../assets';
import { getAuthInstance } from '../../helpers/Auth';
import { PartyType, search_service } from '../../components/staticComponents/StaticHtmlGenerator';
import { MyAgentsData } from '../../components/rightPanel/subpanel/agents/myAgents/MyAgents';
import { MyTeamsData } from '../../components/rightPanel/subpanel/teams/myTeams/MyTeams';

export type ChatMessages = components['schemas']['ChatMessages'];
export type AgentChatMessages = components['schemas']['AgentChatMessages'];
export type SkillParameters = components['schemas']['SkillParameters'];
export type SkillId = components['schemas']['SkillInstanceTokenConfiguration']['skill_id'];
export type EmbType = components['schemas']['SkillParameters']['emb_type'];
export type DatasetModifyRequest = components['schemas']['DatasetModifyRequest'];

interface DatasetResponse {
  data: {
    _id: string;
  };
}

export interface ValidationError {
  detail: {
    json: {
      [fieldName: string]: string[];
    };
  };
  message: string;
}
export interface ApiError extends Error {
  status?: number;
  body?: ValidationError;
}

interface WorkflowData {
  status: string;
  _id: string;
  index_stats: { [key: string]: unknown };
  per_resource_updates_status: { [key: string]: unknown }[];
}
interface ApiResponse<T> {
  data: T;
  error?: string;
  response?: Response;
}
interface SharePointAttributes {
  site_url: string;
  power_automate_workflow_url: string;
  [key: string]: unknown;
}

class Api {
  private static instance: Api;

  private url: string = baseUrl.baseUrl[0].url;
  private token: string = '';
  private graphToken: string = '';
  private client;
  private graphClient;

  private constructor() {
    this.client = createClient<paths>({
      baseUrl: this.url,
    });
    this.graphClient = createClient<path>({
      baseUrl: 'https://graph.microsoft.com/v1.0',
    });

    // Set up middleware only once
    this.client.use(this.middleware);
    this.client.use(this.errorHandlingMiddleware);
    this.graphClient.use(this.graphMiddleware);
    this.graphClient.use(this.errorHandlingMiddleware);

    window.addEventListener(authEvent.type, () => {
      this.updateWithNewAuth();
    });
  }

  public static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api();
      Api.instance.initialize();
    }
    return Api.instance;
  }

  public errorHandlingMiddleware: Middleware = {
    onResponse: async (response) => {
      if (!response.ok) {
        const error: ApiError = new Error(response.statusText);
        error.status = response.status;
        try {
          const errorBody = await response.json();
          error.body = errorBody;
        } catch (e) {
          // Ignore JSON parsing errors
        }
        throw error;
      }
      return response;
    },
  };

  // This is temporaryily hard-coded until it can be provided by the backend
  public async listLlmModels() {
    const data = [
      { id: 'amazon.titan-tg1-large', name: 'Amazon Titan', image: assets.amazonTitan, tooltip: 'Titan' },
      { id: 'gemini-pro', name: 'Gemini Pro', image: assets.geminiPro, tooltip: 'Pro' },
      { id: 'gemini-1.5-flash', name: 'Gemini 1.5 Flash', image: assets.geminiPro, tooltip: '1.5Flash' },
      { id: 'gemini-1.5-pro', name: 'Gemini 1.5 Pro', image: assets.geminiPro, tooltip: '1.5Pro' },
      { id: 'gpt-4', name: 'OpenAI GPT 4', image: assets.ChatGptIcon, tooltip: '4' },
      { id: 'gpt-4o', name: 'OpenAI GPT 4 Omni', image: assets.ChatGptIcon, tooltip: '4o' },
      { id: 'jais-30b-chat', name: 'Jais (Arabic)', image: assets.jaisArabic, tooltip: 'Jais' },
    ];

    const models: LlmModel[] = [LlmModel.defaultModal];

    models.push(...data.map((data) => new LlmModel(data.id, data.name, data.image, data.tooltip)));

    return models;
  }

  public async fetchTenantDetails(name: string) {
    return this.client.GET('/v1.1/tenant/login/{name}', {
      params: {
        path: { name: name },
      },
      parseAs: 'json',
    }) as Promise<ApiResponse<components['schemas']['TenantResponse']>>;
  }

  public async postQuery(
    activeDataSetId: string,
    skill: Skill,
    query: ChatMessages[],
    model: LlmModel = LlmModel.defaultModal,
    skill_parameters: SkillParameters,
    customAppDatasetId: string,
    isCustomApp: boolean
  ) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    const data: components['schemas']['QueryRequest'] = {
      messages: query,
      skill_parameters: skill_parameters,
    };

    //only include if the custom app is selected.
    if (customAppDatasetId) {
      data.dataset_id = customAppDatasetId;
      skill = Skill.docCompletion;
    }

    // Reset to default (gpt-35-turbo-16k) model when there is no model available in Skill Parameters
    if (data.skill_parameters && !data.skill_parameters.model_name) {
      data.skill_parameters.model_name = model.id as SkillParameters['model_name'];
    }

    if (
      activeDataSetId.match('[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$') &&
      !isCustomApp
    ) {
      skill = Skill.docCompletion;
      data.dataset_id = activeDataSetId;
      skill_parameters.return_sources = true;
    }

    return this.client.POST('/v1.1/skills/{skill_id}/query', {
      params: {
        path: { skill_id: skill },
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },
      body: data,
      parseAs: 'stream',
    });
  }

  /*
        Dataset API
    */

  public async getCompleteUserDetailsByID(userIDs: string[]) {
    try {
      this.graphToken = await (await getAuthInstance()).getCurrentGraphToken();
      // Use Promise.all to fetch details for all userIDs concurrently
      const userPromises = userIDs.map(async (userID) => {
        try {
          const response = await this.graphClient.GET('/users/{userId}', {
            params: { path: { userId: userID } },
          });
          return response.data;
        } catch (error) {
          console.error(`Error fetching details for user ${userID}:`, error);
          return null; // Return null or a default value in case of an error
        }
      }); // Closing brace for the map function
      // Wait for all API calls to finish
      const usersData = await Promise.all(userPromises);
      return usersData.filter((data) => data !== null); // Filter out any null values from errors
    } catch (error) {
      console.error(`Error fetching details for users`, error);
      return []; // Return an empty array in case of a general error
    }
  }

  public async listDatasets(ownedByMe: boolean = true, sharedWithMe: boolean = true, skill_name: SkillId | null) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/datasets', {
      parseAs: 'json',
      params: {
        query: {
          owned_by_me: ownedByMe,
          shared_with_me: sharedWithMe,
          skill_id: skill_name,
        },
      },
    });
  }

  public async updateSharePointDataset(
    datasetId: string,
    datasetName: string,
    skillId: SkillId,
    sharepoint_attributes: { site_url: string; power_automate_workflow_url: string }
  ) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    const body = {
      allow_all_access: false,
      name: datasetName,
      skill_id: skillId,
      sharepoint_attributes: sharepoint_attributes,
    };

    return this.client.POST('/v1.1/datasets/{dataset_id}', {
      body: body,
      params: {
        path: { dataset_id: datasetId },
      },
      parseAs: 'json',
    });
  }

  public async getUserDetailsByIds(userIds: string[]): Promise<Record<string, string>> {
    this.graphToken = await (await getAuthInstance()).getCurrentGraphToken();

    const userDetails: Record<string, string> = {};

    try {
      // Use Promise.all to handle fetching user details concurrently
      await Promise.all(
        userIds.map(async (userId) => {
          try {
            // Fetch user data from the API
            const response = await this.graphClient.GET('/users/{userId}', {
              params: { path: { userId: userId } }, // Ensure you pass the user_id in the path params
            });
            if (response.data) {
              const { displayName, mail } = response.data as { displayName: string; mail: string };
              // Map the ID to the display name
              userDetails[userId] = displayName || userId; // Fallback to userId if displayName is unavailable
              userDetails['email'] = mail;
            } else {
              userDetails[userId] = userId; // Fallback if response doesn't contain data
            }
          } catch (error) {
            console.error(`Error fetching details for userId ${userId}:`, error);
            userDetails[userId] = userId; // Fallback to userId in case of error
          }
        })
      );
    } catch (error) {
      console.error('Error fetching user details:', error);
    }

    return userDetails;
  }

  public async deleteSharedUser(datasetId: string, users: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    return this.client.POST('/v1.1/datasets/{dataset_id}/acls/remove', {
      body: {
        acl: {
          users,
        },
      },
      params: {
        path: { dataset_id: datasetId },
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      parseAs: 'json',
    });
  }

  public async provideFeedback(feedback: { user_rating: number; user_feedback_text: string; user_experience: string }) {
    return await this.client.POST('/v1.1/feedback', {
      body: feedback,
      parseAs: 'json',
    });
  }
  public async lastSubmittedFeedbackTime() {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/feedback/lastfeedback', {
      parseAs: 'json',
    });
  }

  public async getDataset(datasetId: string) {
    return this.client.GET('/v1.1/datasets/{dataset_id}', {
      params: {
        path: { dataset_id: datasetId },
        Authorization: 'Bearer ' + this.token,
      },
      parseAs: 'json',
    });
  }

  public async updateDataset(datasetId: string, datasetDetails: { name: string; description?: string }) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    return this.client.POST('/v1.1/datasets/{dataset_id}', {
      body: datasetDetails,
      params: {
        path: { dataset_id: datasetId },
      },
      parseAs: 'json',
    });
  }

  public async deleteDataset(datasetId: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    const response = await this.client.DELETE('/v1.1/datasets/{dataset_id}', {
      params: {
        path: { dataset_id: datasetId },
      },
    });
  }

  async fetchSkillsData() {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/skills', {
      parseAs: 'json',
    });
  }

  public async deleteDatasetResource(datasetId: string, fileName: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/datasets/{dataset_id}/remove', {
      params: {
        path: { dataset_id: datasetId },
      },
      body: {
        sources: fileName,
      },
      parseAs: 'json',
    });
  }

  public async createDataset(name: string, skill_id: SkillId, sharepoint_attributes?: SharePointAttributes) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    const body: DatasetModifyRequest = {
      name: name,
      skill_id: skill_id,
      search_service: search_service,
      sharepoint_attributes: sharepoint_attributes,
    };

    return this.client.POST('/v1.1/datasets', {
      body: body,
      parseAs: 'json',
    }) as Promise<DatasetResponse>;
  }

  public async prepareDataset(upload_datasetid: string, skill_id: SkillId, vision_summary: boolean = false) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/skills/{skill_id}/prepare', {
      params: {
        path: { skill_id: skill_id },
      },
      body: {
        dataset_id: upload_datasetid,
        enable_vision_based_summary: vision_summary,
        emb_type: 'openai',
      },
      parseAs: 'json',
    }) as Promise<ApiResponse<WorkflowData>>;
  }

  public async fetchSowQuery(dataset_id: string, skill_id: SkillId) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    const data = {
      dataset_id: dataset_id,
    };
    return this.client.POST('/v1.1/skills/{skill_id}/query', {
      params: {
        path: {
          skill_id: skill_id,
        },
      },
      body: data,
      parseAs: 'json',
    });
  }

  public async getWorkflowForDataset(datasetId: string, workflowId: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/datasets/{dataset_id}/workflow/{workflow_id}', {
      params: {
        path: {
          dataset_id: datasetId,
          workflow_id: workflowId,
        },
      },
      parseAs: 'json',
    }) as Promise<ApiResponse<WorkflowData>>;
  }

  public async crawlWebsite(datasetid: string, webLink: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/datasets/{dataset_id}/crawl', {
      params: {
        path: { dataset_id: datasetid },
      },
      body: {
        start_url: webLink,
      },
      parseAs: 'json',
    });
  }

  public async validateInputFileURL(url: string, model: SkillParameters['model_name']) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/skills/validate_input', {
      body: {
        url: url,
        model_name: model,
      },
    });
  }

  public async shareAgent(agentId: string, userOIds: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/agents/{agent_id}/acls/add', {
      params: {
        path: { agent_id: agentId },
      },
      body: {
        acl: {
          users: userOIds,
        },
      },
      parseAs: 'json',
    });
  }

  public async deleteAgentSharedUser(agentId: string, users: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    return this.client.POST('/v1.1/agents/{agent_id}/acls/remove', {
      body: {
        acl: {
          users,
        },
      },
      params: {
        path: { agent_id: agentId },
      },
      parseAs: 'json',
    });
  }

  // Upload dataset -> use a "chunk size" that says how many files to send in a single request
  private readonly FILE_CHUNK_SIZE = 1;
  public async uploadDataset(datasetId: string, files: File[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    const fileChunks = [];
    for (let i = 0; i < files.length; i += this.FILE_CHUNK_SIZE) {
      fileChunks.push(files.slice(i, i + this.FILE_CHUNK_SIZE));
    }

    const responses = [];

    for (let i = 0; i < fileChunks.length; i++) {
      const formData = new FormData();
      for (let j = 0; j < fileChunks[i].length; j++) {
        formData.append(`file${i + j}`, fileChunks[i][j]);
      }

      responses.push(
        await this.client.POST('/v1.1/datasets/{dataset_id}/ingest', {
          params: { path: { dataset_id: datasetId } },
          body: {},
          bodySerializer: (body) => {
            // Hopefully we won't keep this forever and can switch to just "requestBody: formData".
            return formData; // However, the current version of openapi-fetch doesn't support multipart/form-data in that way
          },
        })
      );
    }
  }

  public async fetchUserByEmail(email: string) {
    this.graphToken = await (await getAuthInstance()).getCurrentGraphToken();
    return this.graphClient.GET("/users/?$filter=mail eq '{email_id}'", {
      params: { path: { email_id: email } },
      parseAs: 'json',
    });
  }

  public async getMyAgentsList() {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/agents', {});
  }

  public async getAgentsList(ownedByMe: boolean, sharedWithMe: boolean) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/agents', {
      params: {
        query: {
          owned_by_me: ownedByMe,
          shared_with_me: sharedWithMe,
        },
      },
    });
  }

  //History API
  public async fetchHistoryList(currentPage: number, limitCount: number) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return await this.client.GET(`/v1.1/conversations`, {
      params: {
        query: { skip_count: currentPage, limit_count: limitCount },
      },
      parseAs: 'json',
    });
  }

  public async createAgent(createAgentFormData: components['schemas']['AgentModifyRequest']) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/agents', {
      body: createAgentFormData,
      parseAs: 'json',
    });
  }

  public async updateAgent(
    createAgentFormData: components['schemas']['AgentUpdateRequest'],
    updatedAgentDetails: MyAgentsData
  ) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    if (createAgentFormData.name === updatedAgentDetails.name) {
      //if agent is updated without changing the name then dont pass the name in payload.
      const { name, ...updatedAgentFormData } = createAgentFormData;
      createAgentFormData = updatedAgentFormData;
    }
    return this.client.POST('/v1.1/agents/{agent_id}', {
      params: {
        path: {
          agent_id: updatedAgentDetails._id,
        },
      },
      body: createAgentFormData,
      parseAs: 'json',
    });
  }

  public async fetchToolSet() {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return await this.client.GET(`/v1.1/agents/tools`, {
      params: {
        query: { for_ui: true },
      },
      parseAs: 'json',
    });
  }

  public async deleteAgent(agentId: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    return this.client.DELETE('/v1.1/agents/{agent_id}', {
      params: {
        path: { agent_id: agentId },
      },
    });
  }

  public async getMyTeamsList() {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/teams', {});
  }

  public async getTeamsList(ownedByMe: boolean, sharedWithMe: boolean) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.GET('/v1.1/teams', {
      params: {
        query: {
          owned_by_me: ownedByMe,
          shared_with_me: sharedWithMe,
        },
      },
    });
  }

  public async createTeam(createTeamFormData: components['schemas']['TeamModifyRequest']) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/teams', {
      body: createTeamFormData,
      parseAs: 'json',
    });
  }

  public async updateTeam(
    createTeamFormData: components['schemas']['TeamUpdateRequest'],
    updatedTeamDetails: MyTeamsData
  ) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    if (createTeamFormData.name === updatedTeamDetails.name) {
      const { name, ...updatedTeamFormData } = createTeamFormData;
      createTeamFormData = updatedTeamFormData;
    }
    return this.client.POST('/v1.1/teams/{team_id}', {
      params: {
        path: {
          team_id: updatedTeamDetails._id,
        },
      },
      body: createTeamFormData,
      parseAs: 'json',
    });
  }

  public async shareTeam(teamId: string, userOIds: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/teams/{team_id}/acls/add', {
      params: {
        path: { team_id: teamId },
      },
      body: {
        acl: {
          users: userOIds,
        },
      },
      parseAs: 'json',
    });
  }

  public async removeTeamSharedUser(teamId: string, users: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    return this.client.POST('/v1.1/teams/{team_id}/acls/remove', {
      body: {
        acl: {
          users,
        },
      },
      params: {
        path: { team_id: teamId },
      },
      parseAs: 'json',
    });
  }

  public async deleteTeam(teamId: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    return this.client.DELETE('/v1.1/teams/{team_id}', {
      params: {
        path: { team_id: teamId },
      },
    });
  }

  public async postAgentOrTeamQuery(
    query: AgentChatMessages[],
    model: LlmModel,
    skill_parameters: SkillParameters,
    partyId: string,
    partyType: components['schemas']['AgentQueryRequest']['party_type'],
    partyConversationId: string,
    maxRoundsForTeams: number
  ) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    const data: components['schemas']['AgentQueryRequest'] = {
      messages: query,
      party_id: partyId,
      party_type: partyType,
      save_conversation: true,
      stream_response: false,
    };

    if (partyType === PartyType.Agent) {
      data.skill_parameters = skill_parameters;
      data.skill_parameters.return_sources = true;
      data.skill_parameters.model_name = model.id as SkillParameters['model_name'];
    } else if (partyType === PartyType.Team) {
      data.max_rounds = maxRoundsForTeams;
      data.skill_parameters = {
        ...data.skill_parameters,
        return_sources: true,
      };
    }

    if (partyConversationId) {
      data.conversation_id = partyConversationId;
    }

    return this.client.POST('/v1.1/agent_chat_session/query', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },
      body: data,
      parseAs: 'stream',
    });
  }

  public async fetchMessages(conversationId: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return await this.client.GET('/v1.1/conversations/{conversation_id}/messages', {
      params: {
        path: { conversation_id: conversationId },
      },
      parseAs: 'json',
    });
  }

  public async addTitles(title: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    const response = await this.client.POST('/v1.1/conversations', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },

      body: {
        title: title,
      },
    });
    return response;
  }

  public async updateConversation(conversationId: string, msg: string, count: number, modelAPPDataset: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    await this.client.POST('/v1.1/conversations/{conversation_id}/messages', {
      params: {
        path: { conversation_id: conversationId },
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },
      body: {
        content: msg,
        message_index: count,
        name: modelAPPDataset,
      },
    });
  }

  public async deleteHistory(conversationId: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    await this.client.DELETE(`/v1.1/conversations/{conversation_id}/messages`, {
      params: {
        path: { conversation_id: conversationId },
      },
      headers: {
        Accept: 'text/event-stream',
      },
    });
  }

  public async getUserKey() {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    try {
      const response = await this.client.GET('/v1.1/apikey/trial', {
        headers: {
          Accept: 'application/json',
        },
      });
      return response.data; // Return the response data
    } catch (error) {
      console.error('Error fetching user key:', error);
      throw error; // Re-throw the error if needed for higher-level handling
    }
  }

  public async deleteApiKey(apiKeyId: string) {
    // Retrieve the current access token
    this.token = await (await getAuthInstance()).getCurrentAccessToken();

    // Perform the DELETE request using this.client
    await this.client.DELETE('/v1.1/apikey/trial', {
      headers: {
        Accept: 'application/json',
      },
      body: { api_key: apiKeyId },
      parseAs: 'text',
    });
  }

  public async createApiKey(name: string) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    const response = await this.client.POST('/v1.1/apikey/trial', {
      header: {
        Accept: 'application/json',
      },
      body: { name: name },
    });

    return response;
  }

  public async addShareDataset(datasetId: string, users: string[]) {
    this.token = await (await getAuthInstance()).getCurrentAccessToken();
    return this.client.POST('/v1.1/datasets/{dataset_id}/acls/add', {
      body: {
        acl: {
          users,
        },
      },
      params: {
        path: { dataset_id: datasetId },
      },
      parseAs: 'json',
    });
  }

  /*
        Private methods
    */
  private async initialize() {
    if (needsAuth) {
      this.token = await (await getAuthInstance()).getCurrentAccessToken();
      this.graphToken = await (await getAuthInstance()).getCurrentGraphToken();
    }

    this.middleware = {
      onRequest: async (req) => {
        req.headers.set('Authorization', 'Bearer ' + this.token);
        return req;
      },
      onResponse: async (res) => {
        if (res.status === 401) {
          // Unauthorized, so re-authenticate
          (await getAuthInstance()).login();
        }
        return res;
      },
    };

    this.graphMiddleware = {
      onRequest: async (req) => {
        req.headers.set('Authorization', 'Bearer ' + this.graphToken);
        return req;
      },
      onResponse: async (res) => {
        if (res.status === 401) {
          // Unauthorized, so re-authenticate
          (await getAuthInstance()).login();
        }
        return res;
      },
    };
    // this.url = url;
    // this.client = createClient<paths>({
    //     baseUrl: this.url
    // });
  }

  public updateWithNewAuth() {
    // User has re-authenticated, so recreate the client with updated header
    this.initialize();
  }

  middleware: Middleware = {
    onRequest: async (req) => {
      req.headers.set('Authorization', 'Bearer ' + this.token);
      return req;
    },
    onResponse: async (res) => {
      if (res.status === 401) {
        // Unauthorized, so re-authenticate
        (await getAuthInstance()).login();
      }
      return res;
    },
  };

  // Added graphMiddleware for the graphClient
  graphMiddleware: Middleware = {
    onRequest: async (req) => {
      req.headers.set('Authorization', 'Bearer ' + this.graphToken);
      return req;
    },
    onResponse: async (res) => {
      if (res.status === 401) {
        // Unauthorized, so re-authenticate
        (await getAuthInstance()).login();
      }
      return res;
    },
  };
}

export default Api.getInstance();
