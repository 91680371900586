import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Api, { ApiError } from '../../../../data/api/Api';
import { useAppSelector, useAppDispatch } from '../../../../reduxStore/redux-hooks';
import { setInitialHistoryState } from '../../../../reduxStore/slices/historySlice';
import ChatHistoryList from './chatHistoryList/ChatHistoryList';
import { useTranslation } from 'react-i18next';
import { selectedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import {
  chatPageNavigation,
  SectionDivider,
  PartyType,
  CustomAppJsonName,
  customAppsWithSpecificSkills,
} from '../../../staticComponents/StaticHtmlGenerator';
import { useLocation } from 'react-router-dom';
import { AppSkillId } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { UseTrackedNavigate } from '../../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { activeAgentMethod } from '../../../../reduxStore/slices/AgentsSlice';
import { activeTeamMethod } from '../../../../reduxStore/slices/TeamsSlice';
import { MyAgentsData } from '../agents/myAgents/MyAgents';
import { MyTeamsData } from '../teams/myTeams/MyTeams';
import useResetActiveItems from '../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import customAppsData from '../../../../components/rightPanel/subpanel/customApps/CustomApps.json';
import { customAppHistorySelected, saveSelectedCustomApp } from '../../../../reduxStore/slices/CustomAppsSlice';
import { showSnackbarErrorMessage } from '../../../../components/staticComponents/staticUtiles';

export interface HistoryList {
  conversation_id: string;
  last_updated: number;
  party_id: string;
  party_type: string;
  title: string;
}

interface Acl {
  users: string[]; // Array of user IDs
}
export interface Dataset {
  _id: string;
  name: string;
  owners: string[];
  acl: Acl;
}

export interface HistoryResponse {
  data: HistoryList[];
  response: Response;
}

export interface HistoryConversationData {
  content: string;
  conversed_time: number;
  media: string;
  message_index: number;
  name: string;
  role: string;
}
export interface HistoryConversationResponse {
  data: HistoryConversationData[];
  response: Response;
}

const History = () => {
  const { t } = useTranslation();
  const navigate = UseTrackedNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [filteredHistoryList, setFilteredHistoryList] = useState<HistoryList[]>([]);
  const [historyList, setHistoryList] = useState<HistoryList[]>([]);
  const [enableLoader, setEnableLoader] = useState(false);
  const { historyState, refreshTitleFlag } = useAppSelector((state) => state.history);
  const fetchTitleLimitCount = 50;
  const currentPage = 0;
  const resetActiveItems = useResetActiveItems();

  const getHistoryList = async () => {
    setEnableLoader(true);
    try {
      const historyList = await Api.fetchHistoryList(currentPage, fetchTitleLimitCount);
      const listOfConversations = historyList.data as HistoryList[];
      const sortedConversationList = listOfConversations.sort((a, b) => b.last_updated - a.last_updated);
      setFilteredHistoryList(sortedConversationList);
      setHistoryList(sortedConversationList);
      setEnableLoader(false);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  useEffect(() => {
    getHistoryList();
  }, [refreshTitleFlag]);

  const isDateToday = (dateToCheck: Date) => {
    const today = new Date();
    return (
      dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    );
  };

  const filterHistoryList = (historyName: string) => {
    setFilteredHistoryList(historyList.filter((list) => list.title.toLowerCase().includes(historyName)));
  };

  const isWithinLast7DaysButNotToday = (dateToEvaluate: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to the start of the day for accurate comparison
    const oneWeekAgo = new Date().setDate(today.getDate() - 7);
    return (
      dateToEvaluate.getTime() < today.getTime() &&
      dateToEvaluate.getTime() >= oneWeekAgo &&
      !isDateToday(dateToEvaluate)
    );
  };

  const fetchAndProcessConversationMessages = async (
    conversationId: string,
    conversationHeading: string,
    party_id: string,
    party_type: string
  ) => {
    try {
      if (location.pathname.toLowerCase() !== chatPageNavigation.toLowerCase()) {
        navigate(chatPageNavigation);
      }
      const conversationMessagesResponse = (await Api.fetchMessages(conversationId)) as HistoryConversationResponse;
      await makeAssistantActiveBasedOnRetrievedHistory(party_id, party_type, conversationMessagesResponse);
      await setModelActiveFromHistory(conversationMessagesResponse);

      dispatch(
        setInitialHistoryState({
          historyResponse: conversationMessagesResponse.data,
          activeHistory: conversationHeading,
          clearValue: false,
          conversationID: conversationId,
          conversationMessageCounter: conversationMessagesResponse.data.length,
          agentIdentifier: party_type,
        })
      );
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const setModelActiveFromHistory = (conversationMessagesResponse: HistoryConversationResponse) => {
    const modelAndDatasetID = conversationMessagesResponse.data[conversationMessagesResponse.data.length - 1].name;
    const modelFromHistory = modelAndDatasetID.split(':')[0];
    dispatch(
      setInitialHistoryState({
        selectedModelHistory: modelFromHistory,
      })
    );
  };

  const checkIfDatasetHistoryIsFecthed = async (conversationMessagesResponse: HistoryConversationResponse) => {
    try {
      const modelAndDatasetID = conversationMessagesResponse.data[conversationMessagesResponse.data.length - 1].name;
      const activeDatasetId = modelAndDatasetID.split(':')[2];

      if (!activeDatasetId) {
        resetActiveItems(t('welcomepage.Home'));
        return;
      }

      const customAppsDetails = customAppsData.apps_custom;
      const datasetSelected = customAppsDetails.find((customApp) => customApp.dataset_id === activeDatasetId);

      if (datasetSelected) {
        //Make Custom App active while loading conversation messages based on dataset id
        resetActiveItems(t('welcomepage.Apps'));
        dispatch(
          saveSelectedCustomApp({
            isCustomAppSelected: true,
            selectedCustomApp: datasetSelected.sticky_header,
            selectedCustomAppSkill: '',
            welcomeMessage: customAppsWithSpecificSkills.includes(datasetSelected.sticky_header)
              ? t('rightPanel.customapps.activateCustomApp', { selectedCustomApp: datasetSelected.sticky_header })
              : CustomAppJsonName.WiproConsulting === datasetSelected.sticky_header
                ? t('rightPanel.customapps.WiproConsulting', { selectedCustomApp: datasetSelected.sticky_header })
                : CustomAppJsonName.GSG === datasetSelected.sticky_header
                  ? t('rightPanel.customapps.GSG', { selectedCustomApp: datasetSelected.sticky_header })
                  : t('rightPanel.customapps.appsWelcomeMessage', { selectedCustomApp: datasetSelected.sticky_header }),
          })
        );
        dispatch(
          selectedDataSet({
            activeDataSetName: datasetSelected.name,
            activeDataSetId: datasetSelected.dataset_id,
          })
        );
        dispatch(customAppHistorySelected(true));
      } else {
        //Make Documents Dataset active while loading messages
        const listDatasetsResponse = await Api.listDatasets(true, true, AppSkillId.DocCompletion);
        const listSharepointDatasetsResponse = await Api.listDatasets(true, true, AppSkillId.sharePoint);

        const fetchedDatasets = listDatasetsResponse.data as Dataset[];
        const fetchedSharepointDataset = listSharepointDatasetsResponse.data as Dataset[];
        const activeDataset = fetchedDatasets.find((dataset) => dataset._id === activeDatasetId);
        if (!activeDataset) {
          const activeSharepointDataset = fetchedSharepointDataset.find((dataset) => dataset._id === activeDatasetId);
          if (activeSharepointDataset) {
            dispatch(
              selectedDataSet({
                activeDataSetId: activeSharepointDataset._id,
                activeDataSetName: activeSharepointDataset.name as string,
              })
            );
            resetActiveItems(t('welcomepage.Documents'));
          }
        } else if (activeDataset) {
          dispatch(
            selectedDataSet({
              activeDataSetId: activeDataset._id,
              activeDataSetName: activeDataset.name as string,
            })
          );
          resetActiveItems(t('welcomepage.Documents'));
        }
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const makeAssistantActiveBasedOnRetrievedHistory = async (
    party_id: string,
    party_type: string,
    conversationMessagesResponse: HistoryConversationResponse
  ) => {
    try {
      if (party_type === PartyType.Agent) {
        const agentsList = (await Api.getMyAgentsList()).data as MyAgentsData[];
        const isAgentExists = agentsList.find((agent) => agent._id === party_id);
        if (isAgentExists) {
          dispatch(activeAgentMethod(isAgentExists as MyAgentsData));
          resetActiveItems(t('welcomepage.Agent'));
        }
      } else if (party_type === PartyType.Team) {
        const teamsList = (await Api.getMyTeamsList()).data as MyTeamsData[];
        const isTeamExists = teamsList.find((agent) => agent._id === party_id);
        if (isTeamExists) {
          dispatch(activeTeamMethod(isTeamExists as MyTeamsData));
          resetActiveItems(t('welcomepage.Teams'));
        }
      } else {
        await checkIfDatasetHistoryIsFecthed(conversationMessagesResponse);
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const todayHistoryData = filteredHistoryList.filter((conversation) =>
    isDateToday(new Date(conversation.last_updated * 1000))
  );
  const last7DaysHistoryData = filteredHistoryList.filter((conversation) =>
    isWithinLast7DaysButNotToday(new Date(conversation.last_updated * 1000))
  );

  return (
    <Box>
      <Box sx={{ paddingBottom: '1rem' }}>
        <Box>
          <TextField
            label="Search"
            variant="standard"
            onChange={(event) => filterHistoryList(event.target.value)}
            size="small"
            fullWidth
          />
        </Box>
      </Box>
      <Box>
        {historyState.activeHistory && (
          <>
            <Box>
              <Box>
                <Typography sx={{ fontWeight: 700, color: '#6F6C6C' }}>{t('history.activeHistorySession')}</Typography>
                <Typography
                  sx={{
                    paddingTop: '1rem',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '18.75px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '275px',
                  }}
                >
                  {historyState.activeHistory}
                </Typography>
              </Box>
            </Box>
            <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '30px', marginTop: '0px' }} />
          </>
        )}

        <Box>
          <Box>
            <Typography sx={{ fontWeight: 700, color: '#6F6C6C' }}>{t('history.todayHistory')}</Typography>
            <ChatHistoryList
              enableLoader={enableLoader}
              historyData={todayHistoryData}
              fetchAndProcessConversationMessages={fetchAndProcessConversationMessages}
              historyDayIdentifier={t('history.todayHistory')}
            />
          </Box>
        </Box>
        <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '25px', marginTop: '0px' }} />

        <Box>
          <Box>
            <Typography sx={{ fontWeight: 700, color: '#6F6C6C', marginBottom: '1rem' }}>
              {t('history.lastSevenDaysHistory')}
            </Typography>
            <Box>
              <ChatHistoryList
                enableLoader={enableLoader}
                historyData={last7DaysHistoryData}
                fetchAndProcessConversationMessages={fetchAndProcessConversationMessages}
                historyDayIdentifier={t('history.lastSevenDaysHistory')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default History;
