import { Box, Typography } from '@mui/material';
import DatasetList from './myDatasets/MyDatasets';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import EditDataset from './editDataset/EditDataset';
import * as assets from '../../../../assets';
import ImageButton from '../../../sharedComponents/ImageButton';
import { AppSkillId, CustomAppName } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { useEffect, useState } from 'react';

export const buttonStyle: React.CSSProperties = {
  padding: '3px 10px',
  border: '1px solid #000',
  cursor: 'pointer',
  boxShadow: '0 0 5px rgba(0,0,0,0.3)',
  lineHeight: '1',
};

function Dataset() {
  const { t } = useTranslation();
  const { activeDataSetName, activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { ownersDataList } = useAppSelector((state) => state.dataset.ownerDataSet);
  const { sharedDataList } = useAppSelector((state) => state.dataset.sharedDataSet);
  const [activeOwnersList, setActiveOwnersList] = useState(ownersDataList[activeDataSetId]);
  const [activeSharedList, setActiveSharedList] = useState(sharedDataList[activeDataSetId]);
  const { selectedCustomApp } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { isCustomAppHistorySelected } = useAppSelector((state) => state.customApps);
  const datasetListCustomAppSkill = selectedCustomApp.includes(CustomAppName.SOWPredictor)
    ? AppSkillId.SOWPredictor
    : selectedCustomApp.includes(CustomAppName.SOWCommitAI)
      ? AppSkillId.SOWCommitAI
      : selectedCustomApp.includes(CustomAppName.SOWFinAI)
        ? AppSkillId.SOWFinAI
        : AppSkillId.DocCompletion;

  useEffect(() => {
    setActiveOwnersList(ownersDataList[activeDataSetId]);
    setActiveSharedList(sharedDataList[activeDataSetId]);
  }, [sharedDataList, ownersDataList]);

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.activeDataset')}
      </Typography>
      {!activeDataSetName ||
        (isCustomAppHistorySelected && (
          <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
            {t('rightPanel.dataset.noActiveDataset')}
          </Typography>
        ))}
      <Box color={activeDataSetName ? '#000' : '#A6A6A6'} fontSize={'16px'} sx={{ marginTop: '10px' }}>
        {!isCustomAppHistorySelected && (
          <BasicPopover
            srcComponent={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight={activeDataSetName ? '700' : '400'}>
                  {activeDataSetName ? activeDataSetName : t('rightPanel.noDataset')}
                </Typography>
                <Typography>
                  <Box>
                    <ImageButton srcFile={assets.dots} style={buttonStyle} />
                  </Box>
                </Typography>
              </Box>
            }
            contentComponent={
              <EditDataset
                activeDataSetName={activeDataSetName}
                activeDataSetId={activeDataSetId}
                currentDatasetId={activeDataSetId}
                currentDatasetName={activeDataSetName}
                isFromDatasetList={false}
                isItemVisible={true}
                ownerListDataset={activeOwnersList}
                sharedListDataset={activeSharedList}
              />
            }
          />
        )}
      </Box>
      <Box>
        <Box sx={{ margin: '1rem 0' }}>
          <DatasetList selectedApp={datasetListCustomAppSkill} />
        </Box>
      </Box>
    </Box>
  );
}

export default Dataset;
