import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import './CreateAgent.css';
import { useTranslation } from 'react-i18next';
import Api, { ApiError } from '../../../../../data/api/Api';
import { LlmModel } from '../../../../../data/models/LlmModel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import * as assets from '../../../../../assets';
import { Controller, useForm } from 'react-hook-form';
import { ActiveNavItemContext } from '../../../RightPanelContext';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import {
  agentActivationMethod,
  confirmAgentUpdate,
  setIsAgentView,
} from '../../../../../reduxStore/slices/AgentsSlice';
import Toolset from './toolSet/ToolSet';
import AttachDataset from './attach-dataset/AttachDataset';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import ShareAgent from '../shareAgent/ShareAgent';
import NumberInput from '../../../../../components/sharedComponents/numberInput/NumberInput';
import {
  AgentInstructionsMaxCharacterCount,
  maxOutputTokentooltipPoints,
} from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';
import { MyAgentsData } from '../myAgents/MyAgents';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { resetTeamActivationProcess } from '../../../../../reduxStore/slices/TeamsSlice';
import { setOpenCreateAgentOrTeamPage } from '../../../../../reduxStore/slices/CommonSlice';
import SettingsIcon from '@mui/icons-material/Settings';
import ConversationStyleSlider from '../../../../sharedComponents/ConversationStyleSlider';
import useResetActiveItems from '../../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';

export enum AgentType {
  Basic = 'Basic',
  Dataset = 'Dataset',
  Toolset = 'Toolset',
}

function CreateAgent() {
  const { t } = useTranslation();
  const [llmModels, setLlmModels] = useState([LlmModel.defaultModal]);
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false);
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const listOfCreateAgentAccordions = [AgentType.Dataset, AgentType.Toolset];
  const dispatch = useAppDispatch();
  const temperature = 0.3;
  const [isAgentCreateInProcess, setIsAgentCreateInProcess] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showShareAgentDialog, setShowShareAgentDialog] = useState<boolean>(false);
  const { activationAgent, ShowAgentActivationDialogOnChat } = useAppSelector(
    (state) => state.agents.agentActivationProcess
  );
  const { updateAgentDetails, isAgentToBeUpdated, isAgentOwner, isAgentView } = useAppSelector((state) => state.agents);
  const { isCreateAgentOrTeamPageOpen } = useAppSelector((state) => state.commonSlice);
  const resetActiveItems = useResetActiveItems();

  //register create agent form fields with default values to useForm.
  //useForm is a custom hook for managing forms with ease.
  const { watch, control, getValues, setValue } = useForm({
    defaultValues: {
      dataset_id: null,
      description: '',
      instructions: '',
      model_name: llmModels[0].id as any, //type is defined as 'any' since model standard is something that is defined
      // only in UI and that model do not get generated in the schema file automatically.
      name: '',
      temperature: temperature,
      tools: null,
      type: AgentType.Basic,
      max_output_tokens: 256,
      top_k: 5,
      showParameters: false,
    },
  });

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const handleCreateAgentFormSubmit = async () => {
    resetActiveItems(t('welcomepage.Agent'));
    const agentFormFieldValues = getValues();
    const { showParameters, ...requiredAgentFormFiledValues } = agentFormFieldValues;
    const createAgentFormData = requiredAgentFormFiledValues;
    setIsAgentCreateInProcess(true);
    try {
      if (ShowAgentActivationDialogOnChat) {
        dispatch(
          agentActivationMethod({
            ShowAgentActivationDialogOnChat: false,
            activationAgent: activationAgent,
          })
        );
      }
      const createOrUpdateAgent = isAgentToBeUpdated
        ? Api.updateAgent(createAgentFormData, updateAgentDetails)
        : Api.createAgent(createAgentFormData);
      await createOrUpdateAgent.then((createAgentResponse) => {
        if (createAgentResponse.data) {
          dispatch(resetTeamActivationProcess());
          const createAgentData = createAgentResponse.data as MyAgentsData;
          //once agents is successfully created hold these agent details to show information on the chat for the user to perform some action.
          if (!isAgentToBeUpdated) {
            dispatch(
              agentActivationMethod({
                ShowAgentActivationDialogOnChat: true,
                activationAgent: createAgentData,
              })
            );
            setShowShareAgentDialog(true);
          } else {
            setActiveNavItem(null);
            dispatch(
              showUserActionContentOnChat(
                t('rightPanel.agent.createAgent.updateAgentSuccess', {
                  updatingAgentName: createAgentData.name ? createAgentData.name : updateAgentDetails.name,
                })
              )
            );
            if (isCreateAgentOrTeamPageOpen) {
              enqueueSnackbar(t('quickView.updateAgent', { agentName: createAgentData.name }));
            }
            dispatch(setOpenCreateAgentOrTeamPage(false));
            dispatch(confirmAgentUpdate(false));
          }
        }
      });
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
    setIsAgentCreateInProcess(false);
  };

  const closeSubPanel = () => {
    setActiveNavItem(null);
    setShowShareAgentDialog(false);
    dispatch(setOpenCreateAgentOrTeamPage(false));
    dispatch(confirmAgentUpdate(false));
    dispatch(setIsAgentView(false));
  };

  const skillsSelected = (selectedSkills: string[]) => {
    const agentType = selectedSkills.length > 0 || isAgentToBeUpdated ? AgentType.Toolset : AgentType.Basic;
    const agentTools = selectedSkills.length > 0 ? selectedSkills : null;
    setValue('tools', agentTools as any);
    setValue('type', agentType);
  };

  const attachDataset = (datasetId: string) => {
    const agentType = datasetId || isAgentToBeUpdated ? AgentType.Dataset : AgentType.Basic;
    const datasetIdValue = datasetId ? datasetId : isAgentToBeUpdated ? updateAgentDetails._id : null;
    setValue('type', agentType);
    setValue('dataset_id', datasetIdValue as any);
  };

  //prefill the data for edit agent.
  const updateAgentFields = () => {
    setValue('name', updateAgentDetails.name);
    setValue('description', updateAgentDetails.description);
    setValue('model_name', updateAgentDetails.model_name);
    setValue('temperature', updateAgentDetails.temperature);
    setValue('max_output_tokens', updateAgentDetails.max_output_tokens ? updateAgentDetails.max_output_tokens : 256);
    setValue('instructions', updateAgentDetails.instructions);
    setValue('tools', updateAgentDetails.tools as any);
    setValue('dataset_id', updateAgentDetails.dataset_id as any);
    setValue('type', updateAgentDetails.type as AgentType);
  };

  useEffect(() => {
    // Load available LLM Models
    Api.listLlmModels().then((response) => {
      setLlmModels(response);
      // Prefill the data for edit agent.
      if (isAgentToBeUpdated) {
        updateAgentFields();
      } else {
        // Default model is gpt-3.5, which is not supported for toolset agents.
        // Set gpt-4 as the default value in the create agent page.
        const gpt4Index = response.findIndex((model) => model.id === 'gpt-4');
        setValue('model_name', response[gpt4Index].id);
      }
    });
  }, []);

  const disableCreateOrUpdateAgentAccordions = (panel: string) => {
    return (
      (watch('type') === AgentType.Toolset && panel === AgentType.Dataset) ||
      (watch('type') === AgentType.Dataset && panel === AgentType.Toolset) ||
      (isAgentToBeUpdated && updateAgentDetails.type === AgentType.Basic)
    );
  };

  const disableCreateOrUpdateAgentButton = () => {
    return (
      watch('name').trim() === '' ||
      watch('instructions').trim() === '' ||
      (isAgentToBeUpdated && updateAgentDetails.type === AgentType.Toolset && getValues().tools === null)
    );
  };

  const showParameters = () => {
    setValue('showParameters', !getValues().showParameters);
  };

  return (
    <Box className="create-agent-form">
      <Box className="create-agent-header">
        <Typography>
          {isAgentToBeUpdated
            ? t('rightPanel.agent.createAgent.updateAgent')
            : t('rightPanel.agent.createAgent.createNewAgent')}
        </Typography>
      </Box>
      <Box marginBottom={'40px'} className="agent-name-field">
        <Box sx={{ marginLeft: { xs: '0', sm: '0', lg: '-106px' }, display: { xs: 'none', sm: 'none', lg: 'block' } }}>
          <img width={66} height={66} src={assets.agentIconEdit} alt="agent edit icon" />
        </Box>
        <Box flexGrow={1}>
          <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.nameOfAgent') + '*'}</InputLabel>
          {/* The Controller from react-hook-form is used into our form,
          providing it with the necessary props and state to manage its 
          value, validation, and error handling, */}
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                fullWidth
                placeholder={t('rightPanel.agent.createAgent.placeHolderForAgentName')}
                disabled={isAgentView}
              />
            )}
          />
          <Box paddingTop={'5px'} fontSize={'14px'} color={'#3C82F6'}>
            {t('rightPanel.agent.createAgent.createAgentHelperText')}
          </Box>
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.description')}</InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.agent.createAgent.placeHolderForDescription')}
              disabled={isAgentView}
            />
          )}
        />
      </Box>
      <Box height={'100px'} display={'flex'} gap={'50px'}>
        <Box width={'40%'}>
          <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.languageModel')}</InputLabel>
          <Controller
            control={control}
            name="model_name"
            rules={{ required: false }}
            render={({ field }) => (
              <Select
                {...field}
                variant="standard"
                defaultValue={llmModels[0].id}
                sx={{ width: '50%', display: 'inline' }}
                disabled={isAgentView}
              >
                {llmModels.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>

        <Box width={'5%'} display={'flex'} alignItems={'center'} marginLeft={'-30px'} paddingBottom={'15px'}>
          <Tooltip title={t('rightPanel.agent.createAgent.parametersSetting')} arrow>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {watch('showParameters') ? (
                <SettingsIcon sx={{ color: '#3C82F6', cursor: 'pointer' }} onClick={showParameters} />
              ) : (
                <SettingsIcon sx={{ cursor: 'pointer' }} onClick={showParameters} />
              )}
            </Box>
          </Tooltip>
        </Box>
        {watch('showParameters') && (
          <>
            <Box width={'15%'}>
              <ConversationStyleSlider control={control} isAgentView={isAgentView} />
            </Box>

            <Box width={'15%'}>
              <Box sx={{ display: 'flex' }}>
                <InputLabel sx={{ paddingBottom: '5px' }} className="mui-form-label">
                  {t('rightPanel.agent.createAgent.maxOutputToken')}
                </InputLabel>
                <Tooltip
                  title={
                    <Box component={'ul'} paddingLeft={'10px'}>
                      {maxOutputTokentooltipPoints.map((point) => {
                        const [tooltipTitle, tooltipDescription] = t(
                          `rightPanel.agent.createAgent.maxOutputTokenTooltipPoints.${point}`
                        ).split(': ');
                        return (
                          <Box component="li" key={point}>
                            <strong>{tooltipTitle} : </strong>
                            {tooltipDescription}
                          </Box>
                        );
                      })}
                    </Box>
                  }
                  arrow
                >
                  <Icon sx={{ display: 'flex', marginLeft: '5px', cursor: 'pointer' }}>
                    <InfoIcon sx={{ width: '20px' }} />
                  </Icon>
                </Tooltip>
              </Box>
              <Controller
                control={control}
                name="max_output_tokens"
                rules={{ required: false }}
                render={({ field }) => (
                  <NumberInput
                    disabled={isAgentView}
                    {...field}
                    min={1}
                    onChange={(event, newValue) => setValue('max_output_tokens', newValue ? newValue : 256)}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.agentInstructions') + '*'}</InputLabel>
        <Typography sx={{ fontSize: 14 }}>{t('rightPanel.agent.createAgent.agentInstructionsHelper')}</Typography>

        <Controller
          control={control}
          name="instructions"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              disabled={isAgentView}
              {...field}
              variant="standard"
              fullWidth
              multiline
              placeholder={t('rightPanel.agent.createAgent.placeHolderForInstructions')}
              inputProps={{ maxLength: AgentInstructionsMaxCharacterCount }}
            />
          )}
        />
        {watch('instructions').length > 0 && (
          <Box sx={{ float: 'right' }}>
            <Typography>
              {watch('instructions').length} / {AgentInstructionsMaxCharacterCount}
            </Typography>
          </Box>
        )}
      </Box>
      {(getValues().tools || getValues().dataset_id) && (
        <Box paddingBottom={'10px'} fontSize={'14px'} color={'#3C82F6'} fontWeight={700}>
          {/* This line displays a helper text message to the user, depending on whether they have selected a toolset or attached a dataset. */}
          {getValues().tools
            ? t('rightPanel.agent.createAgent.createAgentHelperTextForToolset')
            : t('rightPanel.agent.createAgent.createAgentHelperTextForDataset')}
        </Box>
      )}
      <Box marginBottom={'40px'} className="create-agent-accordion-section">
        {listOfCreateAgentAccordions.map((panel) => (
          <Accordion
            key={panel}
            expanded={accordionExpanded === panel}
            onChange={handleAccordionChange(panel)}
            className={`create-agent-accordion ${accordionExpanded === panel ? 'accordion-opened' : ''}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${panel}-content`}
              id={`${panel}-header`}
              // This condition ensures that the user can only select one option at a time.
              disabled={disableCreateOrUpdateAgentAccordions(panel)}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <span>
                  {t(
                    `rightPanel.agent.createAgent.${panel === AgentType.Toolset ? 'toolBasedAgent' : 'attachDatasets'}`
                  )}
                </span>
                <img
                  width={20}
                  height={20}
                  src={watch('type') === panel ? assets.greenTickIcon : assets.validIconDisabled}
                  alt="valid icon disabled"
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ color: '#000000' }}>
              {panel === AgentType.Toolset ? (
                <Toolset skillsSelected={skillsSelected} />
              ) : (
                <AttachDataset
                  attachDataset={attachDataset}
                  setIsAgentCreateInProcess={setIsAgentCreateInProcess}
                  attachDatasetTopK={(topKValue: number) => setValue('top_k', topKValue)}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box className="create-agent-actions">
        <Button variant="text" sx={{ textTransform: 'none', textDecoration: 'underline' }} onClick={closeSubPanel}>
          {t('rightPanel.agent.createAgent.cancel')}
        </Button>
        {(!isAgentView || isAgentOwner) && (
          <LoadingButton
            type="button"
            variant="contained"
            sx={{
              textTransform: 'none',
              backgroundColor: '#3C82F6',
            }}
            disabled={disableCreateOrUpdateAgentButton()}
            loading={isAgentCreateInProcess}
            onClick={
              isAgentView && isAgentOwner
                ? () => dispatch(setIsAgentView(false)) // Edit action
                : handleCreateAgentFormSubmit // Create/Update action
            }
          >
            {isAgentView && isAgentOwner
              ? t('rightPanel.agent.editAgent.edit')
              : isAgentToBeUpdated
                ? t('rightPanel.agent.createAgent.updateAgent')
                : t('rightPanel.agent.createAgent.createAgent')}
          </LoadingButton>
        )}
      </Box>
      {showShareAgentDialog && (
        <Box>
          <ShareAgent setShowShareAgentDialog={setShowShareAgentDialog} agent={activationAgent} />
        </Box>
      )}
    </Box>
  );
}

export default CreateAgent;
