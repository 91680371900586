import { Box, List, ListItemButton, Typography, ListItemText, CircularProgress } from '@mui/material';
import React, { useEffect, useCallback, useState } from 'react';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import { useTranslation } from 'react-i18next';
import {
  SectionDivider,
  AppSkillId,
  customAppsWithSpecificSkills,
} from '../../../staticComponents/StaticHtmlGenerator';
import Dataset from '../dataset/Dataset';
import { useSnackbar } from 'notistack';
import Api, { ApiError } from '../../../../data/api/Api';
import customAppsData from '../../../../components/rightPanel/subpanel/customApps/CustomApps.json';
import UseQueryParamNavigation from '../../../../components/sharedComponents/customHooks/useQueryParamNavigation/UseQueryParamNavigation';
import { showSnackbarErrorMessage } from '../../../../components/staticComponents/staticUtiles';
export interface CustomAppSkill {
  _id: string;
  allow_all_access: boolean;
  skill_id: string;
  tenant_id: string;
}
interface listOfCustomAppsUserHasAccess {
  name: string;
  model_name: string;
  model_display_name: string;
  dataset_id: string;
  input_label: string;
  system_prompt: string;
  sticky_header: string;
  ussage: string;
  description: string;
}
export interface ListOfExistingDataset {
  allow_all_access: boolean;
  desc: string;
  files: [];
  name: string;
  owners: string[];
  tenant_id: string;
  _id: string;
}
interface customAppInfo {
  id: string;
  value: string;
  text: string;
  childId: string;
}

export const appMaps: Record<AppSkillId, customAppInfo> = {
  completion: { id: 'chat-AI', value: 'ChatAI', text: 'Chat AI', childId: 'chat' },
  doc_completion: { id: 'document-AI', value: 'DocumentAI', text: 'Document AI', childId: 'doc' },
  sow_predictor: { id: 'marginal-gpt', value: 'Marginal', text: 'SOW Predictor', childId: 'marginal' },
  sow_commit_ai: { id: 'sow_commit_ai', value: 'sow_commit_ai', text: 'SOW Commit AI', childId: 'sow_commit_ai' },
  sow_commit_acc_ai: {
    id: 'sow_commit_acc_ai',
    value: 'sow_commit_acc_ai',
    text: 'SOW Fin AI',
    childId: 'sow_commit_acc_ai',
  },
  qet_app: { id: 'qet_app', value: 'qet_app', text: 'QET Platform', childId: 'qet_app' },
  advanced_summary: {
    id: 'advanced_summary',
    value: 'advanced_summary',
    text: 'Advanced Summary',
    childId: 'advanced_summary',
  },
  sharepoint_completion: {
    id: 'sharepoint_completion',
    value: 'sharepoint_completion',
    text: 'SharePoint Completion',
    childId: 'sharepoint_completion',
  },
};

function CustomApps() {
  const { t } = useTranslation();
  const { selectedCustomApp, selectedCustomAppSkill } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { enqueueSnackbar } = useSnackbar();
  const [isCustomAppLoading, setIsCustomAppLoading] = useState<boolean>(true);
  const [customSkills, setCustomSkills] = useState<CustomAppSkill[]>([]);
  const [listOfCustomApps, setListOfCustomApps] = useState<listOfCustomAppsUserHasAccess[]>([]);
  const [accessibleCustomApps, setAccessibleCustomApps] = useState<listOfCustomAppsUserHasAccess[]>([]);
  const { shouldDisableCustomApps } = useAppSelector((state) => state.customApps);
  const shouldShowDatasets = customAppsWithSpecificSkills.includes(selectedCustomApp);
  const { makeCustomAppActive } = UseQueryParamNavigation();

  const fetchSkillsData = useCallback(async () => {
    try {
      const customAppSkillsList = await Api.fetchSkillsData();
      const appSkills = customAppSkillsList.data as CustomAppSkill[];
      setCustomSkills(appSkills);
      await fetchCustomAppsJsonAccess();
      setIsCustomAppLoading(false);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  }, [enqueueSnackbar]);

  const fetchCustomAppsJsonAccess = async () => {
    try {
      // Get custom apps details from a JSON file
      const customAppsDetails = customAppsData.apps_custom;
      const listOfDatasets = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
        .data as ListOfExistingDataset[];
      // Filter custom apps based on user's access to datasets
      const listOfCustomAppsUserHasAccess = customAppsDetails.filter((customApp) =>
        listOfDatasets.some((dataset) => dataset._id === customApp.dataset_id)
      );
      setAccessibleCustomApps(listOfCustomAppsUserHasAccess);
      setListOfCustomApps(customAppsDetails);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  useEffect(() => {
    fetchSkillsData();
  }, []);

  const renderCustomButton = (key: string, text: string, disabled: boolean, onClick: () => void) => (
    <ListItemButton
      key={key}
      disabled={disabled}
      sx={{
        padding: '5px 10px',
        //paddingLeft: '0px',
      }}
      onClick={onClick}
    >
      {/* <ListItemAvatar sx={{ minWidth: '50px' }}>
        <Avatar alt="default agent" src={assets.ai360Agent} />
      </ListItemAvatar> */}

      <ListItemText
        primary={text} // Use the text property here
        sx={{ padding: '8px 0px', margin: '0px' }}
      />
    </ListItemButton>
  );
  const renderCustomApps = (): JSX.Element[] => {
    const renderapps: JSX.Element[] = [];

    Object.keys(appMaps).map((customKey) => {
      const customAppKey = customKey as AppSkillId; // Cast key to appName type
      if (
        customAppKey !== AppSkillId.Completion &&
        customAppKey !== AppSkillId.DocCompletion &&
        customAppKey !== AppSkillId.sharePoint
      ) {
        //  Rendering all skills and enabled the app for which the user has access.
        const isSkillEnabled =
          !customSkills.some((customSkill) => customSkill.skill_id === customAppKey) ||
          (shouldDisableCustomApps && customAppKey !== selectedCustomAppSkill);
        renderapps.push(
          renderCustomButton(customAppKey, appMaps[customAppKey].text, isSkillEnabled, () =>
            makeCustomAppActive(appMaps[customAppKey].text)
          )
        );
      }
    });

    //  Rendering all Custom Apps from Json and enabled the app for which the user has access.
    if (listOfCustomApps.length > 0) {
      listOfCustomApps.map((customApp) => {
        const isDisabled =
          !accessibleCustomApps.some((jsonApp) => jsonApp.dataset_id === customApp.dataset_id) ||
          shouldDisableCustomApps;

        renderapps.push(
          renderCustomButton(customApp.dataset_id, customApp.sticky_header, isDisabled, () =>
            makeCustomAppActive(customApp.sticky_header)
          )
        );
      });
    }
    return renderapps;
  };

  return (
    <Box id={'customApp-2nd-level-content'}>
      <Box className="active-customApp" marginBottom={'25px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
          {t('rightPanel.customapps.activeApp')}
        </Typography>

        <Box>
          {!selectedCustomApp ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.customapps.noActiveCustomApp')}
            </Typography>
          ) : (
            <List>
              <ListItemButton
                key={selectedCustomApp}
                className="customApp-list"
                sx={{
                  padding: '5px',
                  paddingLeft: '0px',
                  width: '100%',
                }}
              >
                {/* <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
                  <Avatar className="avatar" alt="default agent" src={assets.ai360Agent} />
                </ListItemAvatar> */}
                <Box width={'100%'}>
                  <Typography color={'#0B0C0C'} fontWeight={700} fontSize={'16px'}>
                    {selectedCustomApp}
                  </Typography>
                </Box>
              </ListItemButton>
            </List>
          )}
        </Box>
      </Box>

      <Box marginBottom={'5px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'} sx={{ gap: '3rem' }}>
          {t('rightPanel.customapps.builtForYou')}
        </Typography>
        <Box maxHeight={'13rem'} overflow={'auto'}>
          <>
            {isCustomAppLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Box maxHeight={'13rem'} overflow={'auto'}>
                <>
                  {!customSkills.length && !accessibleCustomApps.length ? (
                    // Display default apps message when there is no access to the custom apps
                    <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
                      {t('rightPanel.customapps.noCustomAppLoaded')}
                    </Typography>
                  ) : (
                    <Box className="apps-list">{renderCustomApps()}</Box>
                  )}
                </>
              </Box>
            )}
          </>
        </Box>
      </Box>
      <SectionDivider sx={{ marginTop: '2px', marginLeft: '0px', marginRight: '0px' }} />
      {shouldShowDatasets && (
        <>
          <Dataset />
        </>
      )}
    </Box>
  );
}

export default CustomApps;
