import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import {
  toggleModal,
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
  setFiles,
} from '../../../../../reduxStore/slices/FileUploadSlice';
import {
  currentDataSet,
  refreshDatasetList,
  selectedDataSet,
  setEditDataset,
} from '../../../../../reduxStore/slices/DataSetSlice';
import {
  AdvanceSummaryResponse,
  saveAdvanceSummaryResponse,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
  setDisableCustomApps,
  SowCommitAiQueryResponse,
  SowPredictorQueryResponse,
} from '../../../../../reduxStore/slices/CustomAppsSlice';
import { showUserActionContentOnChat } from 'reduxStore/slices/NotifyUserActionContentSlice';
import UsePageNavigation from '../../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { UseTrackedNavigate } from '../../../../sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import RadioSelect from './RadioSelect';
import NewDataset from './newDataset/NewDataset';
import ExistingDataset from './existingDataset/ExistingDataset';
import Api, { ApiError, SkillId } from '../../../../../data/api/Api';
import {
  chatPageNavigation,
  AppSkillId,
  customAppsSkillIds,
  MAX_LENGTH_DATASET_NAME,
} from '../../../../staticComponents/StaticHtmlGenerator';
import SharePointDataset from './SharePointDataset/SharePointDataset';
import ExistingSharePointDataset, { SharePointAttributes } from './existingSharePointdataset/ExistingSharePointDatset';
import { isValidUrl, showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';
import ExistingDatasetRadioSelect from './ExistingDatasetRadioSelect';
import { findEmptyFileName } from 'components/staticComponents/staticUtiles';
import { setIsSharePointSelected } from '../../../../../reduxStore/slices/FileUploadSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export interface ListOfExistingDataset {
  allow_all_access: boolean;
  desc: string;
  name: string;
  owners: string[];
  tenant_id: string;
  _id: string;
  ad_tenant_id?: string;
  files: [];
  sharepoint_attributes?: SharePointAttributes;
  skill_id?: string;
}
interface DatasetModalProps {
  open: boolean;
  onClose: () => void;
}
export const supportedFileTypes = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'message/rfc822',
  '.msg',
  'text/plain',
  'text/html',
  'text/htm',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const supportedImageTypes = ['image/png', 'image/jpeg'];

const DatasetModal = ({ open, onClose }: DatasetModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { editDataset } = useAppSelector((state) => state.dataset);
  const { fileUpload, fileSelection, dataset, isSharepointSelected } = useAppSelector((state) => state.fileUpload);
  const [selection, setSelection] = useState<string>(editDataset.addFiles ? 'existing' : 'new');
  const [siteUrl, setSiteUrl] = useState('');
  const [workflowUrl, setWorkflowUrl] = useState('');
  const [sharePointDatasetName, setSharePointDatasetName] = useState('');
  const [datasetRadioSelect, setdatasetRadioSelect] = useState<string>(
    editDataset.addFiles ? t('fileUpload.existingDataset') : t('fileUpload.newDataset')
  );

  const [isExistingDatasetListFetching, setIsExistingDatasetListFetching] = useState<boolean>(true);
  const [isExistingDatasetHasData, setIsExistingDatasetHasData] = useState<boolean>(false);
  const { addMoreFiles } = useAppSelector((state) => state.dataset);
  const [existingDatasetRadioSelect, setExistingDatasetRadioSelect] = useState(
    isSharepointSelected === AppSkillId.sharePoint
      ? t('fileUpload.existingSharePointDataset')
      : t('fileUpload.existingDataset')
  );

  const restoreEditDatasetState = () => {
    dispatch(
      setEditDataset({
        addFiles: false,
        datasetId: '',
      })
    );
  };

  const handleClose = () => {
    setExistingDatasetRadioSelect(t('fileUpload.existingDataset'));
    setdatasetRadioSelect(t('fileUpload.newDataset'));
    setSelection('new');
    dispatch(toggleModal());
    dispatch(setIsSharePointSelected(''));
    if (editDataset.addFiles) restoreEditDatasetState();
    dispatch(updateDatasetState({ listOfUserOwnedDataset: [] }));
    dispatch(setFiles([]));
    onClose();
  };

  const navigate = UseTrackedNavigate();
  const { isWelcomePage } = UsePageNavigation();

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      datasetName: '',
      datasetDescription: '',
    },
    mode: 'onChange',
  });
  useEffect(() => {
    if (open) {
      reset({
        datasetName: fileUpload.uploadedFiles.length > 0 ? fileUpload.uploadedFiles[0].name : '',
        datasetDescription: '',
      });
    }
  }, [open, fileUpload.uploadedFiles, reset]);

  const { isCustomAppSelected, selectedCustomAppSkill } = useAppSelector((state) => state.customApps.choosenCustomApp);
  useEffect(() => {
    if (fileUpload.uploadedFiles.length > 0) {
      modifyDatasetNameExists(getValues().datasetName);
    }
  }, [dataset.listOfUserOwnedDataset, isCustomAppSelected]);
  const validateFileUpload = (uploadedFiles: File[]) => {
    const BYTES_IN_KILOBYTE = 1024;
    const MAX_FILE_SIZE_MB = 100;
    const MAX_FILES = 10;

    // Check the number of files
    if (uploadedFiles.length > MAX_FILES) {
      enqueueSnackbar(t('fileUpload.uploadMax10Files'), { variant: 'error' });
      handleClose();
      return false;
    }

    // Calculate total size of uploaded files
    const TOTAL_FILES_SIZE_IN_BYTES = uploadedFiles.reduce((total, file) => total + file.size, 0);
    const TOTAL_FILES_SIZE_IN_MB = TOTAL_FILES_SIZE_IN_BYTES / (BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE);

    // Check the total size of files
    if (TOTAL_FILES_SIZE_IN_MB > MAX_FILE_SIZE_MB) {
      enqueueSnackbar(t('fileUpload.uploadMax100MB'), { variant: 'error' });
      handleClose();
      return false;
    }
    return true;
  };

  //When same file is uploaded multiple times the dataset name is added with suffix 1,2,3 etc.. to avoid confusion
  const handleDatasetNameSuffix = async () => {
    const file = fileUpload.uploadedFiles[0];
    const originalName = file['name'];

    // Extract file extension and base name
    const extensionIndex = originalName.lastIndexOf('.');
    const extension = extensionIndex > -1 ? originalName.slice(extensionIndex) : '';
    let baseName = extensionIndex > -1 ? originalName.slice(0, extensionIndex) : originalName;
    let finalName = originalName;
    let suffixCounter = 1;

    // Loop to find a unique name by adding a suffix if necessary
    while (checkDatasetNameExists(finalName)) {
      const suffix = `(${suffixCounter})`;
      const maxLengthForBaseName = MAX_LENGTH_DATASET_NAME - extension.length - suffix.length;
      baseName = baseName.substring(0, maxLengthForBaseName);
      finalName = `${baseName}${suffix}${extension}`;
      suffixCounter++;
    }
    return finalName;
  };

  const checkDatasetNameExists = (name: string) => {
    return (
      dataset.listOfUserOwnedDataset &&
      dataset.listOfUserOwnedDataset.some((existDataset) => existDataset.name === name)
    );
  };

  const modifyDatasetNameExists = async (name: string) => {
    const modifiedDatasetName = !checkDatasetNameExists(name) ? name : await handleDatasetNameSuffix();
    setValue('datasetName', modifiedDatasetName);
    return modifiedDatasetName;
  };

  const clearFileUploadAndFileSelectionStates = () => {
    dispatch(
      updateFileUploadState({
        uploadFileMessage: '',
        uploadFile: false,
      })
    );
    dispatch(
      updateFileSelectionState({
        responseMessage: false,
      })
    );
  };

  const handleFileUploadError = async (datasetId: string) => {
    clearFileUploadAndFileSelectionStates();
    enqueueSnackbar(t('fileUpload.fileUploadFail'), { variant: 'error' });
    await Api.deleteDataset(datasetId);
  };

  const readFiles = async () => {
    try {
      // Validate file size and number
      if (!validateFileUpload(fileUpload.uploadedFiles)) {
        return; //Doing a early Return
      }
      handleClose();

      const checkIfUploadedFilesSupport = fileUpload.uploadedFiles.every((file) => {
        if (!file.type) {
          return file.name.endsWith('.msg');
        }
        return supportedFileTypes.includes(file.type);
      });
      if (!checkIfUploadedFilesSupport) {
        enqueueSnackbar(t('fileUpload.supportedFiles'), { variant: 'error' });
        return; //Return and stop processing further if unsupported files are uploaded.
      }

      dispatch(
        updateFileSelectionState({
          responseMessage: true,
        })
      );

      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus1'),
          uploadFile: true,
        })
      );

      if (isCustomAppSelected) {
        dispatch(setDisableCustomApps(true));
      }

      let datasetId: string = '';
      let activateDatasetName: string = '';
      let sowQuery;
      if (datasetRadioSelect === t('fileUpload.newDataset')) {
        const createdDataset = !isCustomAppSelected
          ? await Api.createDataset(getValues().datasetName, AppSkillId.DocCompletion)
          : await Api.createDataset(getValues().datasetName, selectedCustomAppSkill as SkillId);

        datasetId = createdDataset.data._id;
        activateDatasetName = getValues().datasetName;
        try {
          await Api.uploadDataset(createdDataset.data._id, fileUpload.uploadedFiles);
        } catch (error) {
          handleFileUploadError(createdDataset.data._id);
          return;
        }
        dispatch(
          updateDatasetState({
            datasetName: getValues().datasetName,
          })
        );
        dispatch(
          currentDataSet({
            currentDataSetName: getValues().datasetName,
            currentDataSetId: datasetId,
          })
        );
      } else {
        datasetId = dataset.selectedDatasetId;
        activateDatasetName = dataset.datasetName;
        if (fileSelection.isReplaceFileCheckboxChecked) {
          // Filters out user uploaded files which are not in the selected dataset
          const filteredFile = fileUpload.uploadedFiles.filter(
            (file) => !fileUpload.listOfFilteredUploadedFilesExistInDataset.includes(file.name)
          );
          // Makes dataset ingest api call with the filtered files
          try {
            await Api.uploadDataset(datasetId, filteredFile);
          } catch (error) {
            handleFileUploadError(datasetId);
            return;
          }
        }
        // Makes dataset ingest api call with all the user uploaded files
        try {
          await Api.uploadDataset(datasetId, fileUpload.uploadedFiles);
        } catch (error) {
          handleFileUploadError(datasetId);
          return;
        }

        dispatch(
          currentDataSet({
            currentDataSetName: dataset.datasetName,
            currentDataSetId: datasetId,
          })
        );
      }

      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus2'),
        })
      );
      const prepare = await Api.prepareDataset(
        datasetId,
        AppSkillId.DocCompletion,
        fileSelection.isVisionSummaryEnabled
      );

      let workflowData = prepare.data;
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus3'),
        })
      );
      const delay = 1000 * (fileUpload.uploadedFiles.length || 1);
      while (workflowData.status !== 'Completed') {
        const workflow = await Api.getWorkflowForDataset(datasetId, workflowData._id);
        if (workflow.data) {
          workflowData = workflow.data;
        }
        if (workflowData.status === 'Failed') {
          dispatch(
            updateFileUploadState({
              uploadFileMessage: t('fileUpload.uploadFileMessage.failurePrepareMesssage'),
            })
          );
          return;
        }
        await new Promise((r) => setTimeout(r, delay));
      }
      if (workflowData.status === 'Completed') {
        dispatch(
          updateFileUploadState({
            uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus4'),
          })
        );
        // Fetch Custom Apps SOW response
        if (isCustomAppSelected) {
          //need to refactor the code
          const prepareSow = await Api.prepareDataset(datasetId, selectedCustomAppSkill as SkillId);
          // When custom app is sent for prepare, the dataset returns workflow_id instead of _id.
          // So the below sowWorkflowData has given type as any
          const sowWorkflowData: any = prepareSow.data;
          const delayedTime = 1000;
          const sowDelay = delayedTime * (fileUpload.uploadedFiles.length || 1);
          while (sowWorkflowData.status !== 'Completed') {
            const sowWorkflow = await Api.getWorkflowForDataset(datasetId, sowWorkflowData.workflow_id);
            if (sowWorkflow.data) {
              sowWorkflowData.status = sowWorkflow.data.status;
            }
            if (sowWorkflowData.status === 'Failed') {
              return;
            }
            await new Promise((r) => setTimeout(r, sowDelay));
          }
          sowQuery = (await Api.fetchSowQuery(datasetId, selectedCustomAppSkill as SkillId)).data;
        }
        dispatch(
          updateFileUploadState({
            uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus5'),
          })
        );

        dispatch(
          updateFileUploadState({
            uploadFile: false,
          })
        );
        if (addMoreFiles) {
          dispatch(
            showUserActionContentOnChat(
              t('fileUpload.exisitingDatasetUpdateMessage', { fileName: dataset.datasetName })
            )
          );
        }
        dispatch(
          updateFileSelectionState({
            responseMessage: false,
          })
        );

        dispatch(
          updateDatasetState({
            makeActiveDataset: true,
          })
        );

        dispatch(
          updateFileSelectionState({
            isVisionSummaryEnabled: false,
          })
        );
        //Dispatching the SOW response to Chat Box and Showing the active dataset name
        if (isCustomAppSelected) {
          dispatch(
            selectedDataSet({
              activeDataSetName: activateDatasetName,
              activeDataSetId: datasetId,
            })
          );

          dispatch(
            updateDatasetState({
              makeActiveDataset: false,
            })
          );

          dispatch(
            selectedCustomAppSkill === AppSkillId.AdvancedSummary
              ? saveAdvanceSummaryResponse(sowQuery as AdvanceSummaryResponse)
              : customAppsSkillIds.includes(selectedCustomAppSkill)
                ? saveSowCommitAiQueryResponse(sowQuery as SowCommitAiQueryResponse[])
                : saveSowPredictorQueryResponse(sowQuery as SowPredictorQueryResponse)
          );
          dispatch(setDisableCustomApps(false));
        }
        dispatch(updateDatasetState({ listOfUserOwnedDataset: [] }));
        // Refreshing the Datasets list after uploading a file.
        dispatch(refreshDatasetList());
      }
      if (isWelcomePage) {
        navigate(chatPageNavigation);
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const sharePointUpdate = async () => {
    handleClose();
    dispatch(
      updateFileSelectionState({
        responseMessage: true,
      })
    );
    dispatch(
      updateFileUploadState({
        uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus1'),
        uploadFile: true,
      })
    );
    const datasetId = dataset.selectedDatasetId;
    const sharepoint_data = {
      site_url: siteUrl,
      power_automate_workflow_url: workflowUrl,
    };
    await Api.updateSharePointDataset(datasetId, sharePointDatasetName, AppSkillId.sharePoint, sharepoint_data);
    dispatch(
      updateDatasetState({
        datasetName: sharePointDatasetName,
      })
    );
    dispatch(
      currentDataSet({
        currentDataSetName: sharePointDatasetName,
        currentDataSetId: datasetId,
      })
    );
    dispatch(
      updateFileUploadState({
        uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus2'),
      })
    );
    const prepare = await Api.prepareDataset(datasetId, AppSkillId.DocCompletion);

    let workflowData = prepare.data;

    dispatch(
      updateFileUploadState({
        uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus3'),
      })
    );
    while (workflowData.status !== 'Completed') {
      const workflow = await Api.getWorkflowForDataset(datasetId, workflowData._id);
      if (workflow.data) {
        workflowData = workflow.data;
      }
      if (workflowData.status === 'Failed') {
        dispatch(
          updateFileUploadState({
            uploadFileMessage: t('fileUpload.uploadFileMessage.failurePrepareMesssage'),
          })
        );
        return;
      }
      await new Promise((r) => setTimeout(r, 10000));
    }
    if (workflowData.status === 'Completed') {
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus4'),
        })
      );
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus5'),
        })
      );

      dispatch(
        updateFileUploadState({
          uploadFile: false,
        })
      );

      dispatch(
        updateFileSelectionState({
          responseMessage: false,
        })
      );

      dispatch(
        updateDatasetState({
          makeActiveDataset: true,
        })
      );
      dispatch(updateDatasetState({ listOfUserOwnedDataset: [] }));
      // Refreshing the Datasets list after uploading a file.
      dispatch(refreshDatasetList());
      if (isWelcomePage) {
        navigate(chatPageNavigation);
      }
    }
    dispatch(
      showUserActionContentOnChat(t('fileUpload.exisitingDatasetUpdateMessage', { fileName: dataset.datasetName }))
    );
  };

  const sharePointExecution = async () => {
    if (siteUrl.trim() === '' || workflowUrl.trim() === '' || sharePointDatasetName.trim() === '') {
      enqueueSnackbar(t('fileUpload.uploadFileMessage.emptySharepointAttributes'), {
        variant: 'error',
      });
      return;
    }
    if (!isValidUrl(siteUrl)) {
      enqueueSnackbar(t('fileUpload.validSIteURL'), { variant: 'error' });
      return;
    }

    if (!isValidUrl(workflowUrl)) {
      enqueueSnackbar(t('fileUpload.validWorkflowURL'), { variant: 'error' });
      return;
    }
    try {
      handleClose();
      dispatch(
        updateFileSelectionState({
          responseMessage: true,
        })
      );
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus1'),
          uploadFile: true,
        })
      );

      let datasetId: string = '';

      const sharepoint_data = {
        site_url: siteUrl,
        power_automate_workflow_url: workflowUrl,
      };

      const createDataset = await Api.createDataset(sharePointDatasetName, AppSkillId.sharePoint, sharepoint_data);
      datasetId = createDataset.data._id;
      dispatch(
        updateDatasetState({
          datasetName: sharePointDatasetName,
        })
      );
      dispatch(
        currentDataSet({
          currentDataSetName: sharePointDatasetName,
          currentDataSetId: datasetId,
        })
      );
      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus2'),
        })
      );
      const prepare = await Api.prepareDataset(datasetId, AppSkillId.DocCompletion);

      let workflowData = prepare.data;

      dispatch(
        updateFileUploadState({
          uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus3'),
        })
      );
      while (workflowData.status !== 'Completed') {
        const workflow = await Api.getWorkflowForDataset(datasetId, workflowData._id);
        if (workflow.data) {
          workflowData = workflow.data;
        }
        if (workflowData.status === 'Failed') {
          dispatch(
            updateFileUploadState({
              uploadFileMessage: t('fileUpload.uploadFileMessage.failurePrepareMesssage'),
            })
          );
          return;
        }
        await new Promise((r) => setTimeout(r, 10000));
      }
      if (workflowData.status === 'Completed') {
        dispatch(
          updateFileUploadState({
            uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus4'),
          })
        );
        // Fetch Custom Apps SOW response
        dispatch(
          updateFileUploadState({
            uploadFileMessage: t('fileUpload.uploadFileMessage.uploadStatus5'),
          })
        );

        dispatch(
          updateFileUploadState({
            uploadFile: false,
          })
        );

        dispatch(
          updateFileSelectionState({
            responseMessage: false,
          })
        );

        dispatch(
          updateDatasetState({
            makeActiveDataset: true,
          })
        );
        dispatch(updateDatasetState({ listOfUserOwnedDataset: [] }));
        // Refreshing the Datasets list after uploading a file.
        dispatch(refreshDatasetList());
        if (isWelcomePage) {
          navigate(chatPageNavigation);
        }
      }
    } catch (e) {
      console.log('error', e);
      enqueueSnackbar(t('fileUpload.uploadFileMessage.failureSharepointError'), { variant: 'error' });
      dispatch(
        updateFileSelectionState({
          responseMessage: false,
        })
      );
      dispatch(
        updateFileUploadState({
          uploadFileMessage: '',
          uploadFile: false,
        })
      );
      console.log(e);
    }
  };

  const fileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files && files!.length === 0) return;
    if (findEmptyFileName(files!)) {
      enqueueSnackbar(t('fileUpload.fileIsEmpty', { fileName: findEmptyFileName(files!) }), {
        variant: 'error',
      });
      return;
    }
    dispatch(setFiles(Array.from(files as FileList)));
    dispatch(toggleModal());
    e.target.value = '';
  };

  const handleInitialComponentData = async () => {
    try {
      let listOfExistingDataset;
      if (isCustomAppSelected) {
        listOfExistingDataset = (await Api.listDatasets(true, true, selectedCustomAppSkill as SkillId))
          .data as ListOfExistingDataset[];
      } else {
        listOfExistingDataset = (await Api.listDatasets(true, false, AppSkillId.DocCompletion))
          .data as ListOfExistingDataset[];
      }
      dispatch(
        updateDatasetState({
          listOfUserOwnedDataset: listOfExistingDataset,
        })
      );
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    } finally {
      setIsExistingDatasetListFetching(false);
    }
  };

  useEffect(() => {
    handleInitialComponentData();
  }, [isCustomAppSelected]);

  useEffect(() => {
    modifyDatasetNameExists(getValues().datasetName);
  }, [dataset.listOfUserOwnedDataset, isCustomAppSelected]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          minHeight: 450,
          maxWidth: '60vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 6,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '24px',
            }}
          >
            {selection === 'existing'
              ? t('fileUpload.updatedataset')
              : datasetRadioSelect === t('fileUpload.newDataset')
                ? t('fileUpload.newdataset')
                : datasetRadioSelect === t('fileUpload.sharePointDataset')
                  ? t('fileUpload.sharePointDataset')
                  : t('fileUpload.updatedataset')}
          </Typography>
          <Typography sx={{ borderTop: '1px dashed grey' }}></Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              variant={selection === 'new' || selection === '' ? 'contained' : 'outlined'}
              onClick={() => {
                setSelection('new');
                setdatasetRadioSelect(t('fileUpload.newDataset'));
                dispatch(setFiles([]));
              }}
              sx={{ marginRight: 2 }}
              disabled={siteUrl !== '' || workflowUrl !== '' || isExistingDatasetHasData}
            >
              {t('fileUpload.new')}
            </Button>
            <Button
              variant={selection === 'existing' ? 'contained' : 'outlined'}
              onClick={() => {
                setSelection('existing');
                setdatasetRadioSelect(t('fileUpload.existingDataset'));
                dispatch(setFiles([]));
              }}
            >
              {t('fileUpload.existing')}
            </Button>
          </Box>

          {selection === 'new' || selection === '' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                padding: '0.5rem 2.5rem',
                marginTop: `1rem`,
              }}
            >
              <RadioSelect datasetRadioSelect={datasetRadioSelect} setdatasetRadioSelect={setdatasetRadioSelect} />
              {datasetRadioSelect === t('fileUpload.newDataset') ? (
                <NewDataset formValidation={{ control, errors, reset }} />
              ) : (
                <SharePointDataset
                  setSiteUrl={setSiteUrl}
                  setWorkflowUrl={setWorkflowUrl}
                  setSharePointDatasetName={setSharePointDatasetName}
                />
              )}
            </Box>
          ) : (
            <Box>
              <ExistingDatasetRadioSelect
                existingDatasetRadioSelect={existingDatasetRadioSelect}
                setExistingDatasetRadioSelect={setExistingDatasetRadioSelect}
                siteUrl={siteUrl}
                isExistingDatasetHasData={isExistingDatasetHasData}
              />
              {existingDatasetRadioSelect === t('fileUpload.existingSharePointDataset') ? (
                <ExistingSharePointDataset
                  setSiteUrl={setSiteUrl}
                  setWorkflowUrl={setWorkflowUrl}
                  setSharePointDatasetName={setSharePointDatasetName}
                />
              ) : (
                <ExistingDataset
                  isExistingDatasetListFetching={isExistingDatasetListFetching}
                  setIsExistingDatasetHasData={setIsExistingDatasetHasData}
                />
              )}
            </Box>
          )}
        </Box>

        {selection === 'existing' && existingDatasetRadioSelect !== t('fileUpload.existingSharePointDataset') && (
          <Box margin={'1rem 0 1.5rem'} display={'flex'}>
            <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} sx={{ marginRight: '1rem' }}>
              {t('fileUpload.uploadFile').toUpperCase()}
              <input type="file" multiple onChange={fileInput} hidden />
            </Button>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography fontSize={16} color={'#636363'} sx={{ alignSelf: 'center' }}>
                {t('fileUpload.numberofFilesSelected')}
                {fileUpload.uploadedFiles.length}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1.5rem',
          }}
        >
          <Button onClick={handleClose} sx={{ textDecoration: 'underline' }}>
            {t('rightPanel.cancel')}
          </Button>

          {selection === 'new' && (
            <Button
              sx={{
                fontSize: '14px',
                boxShadow: 'none',
                height: '2rem',
                '&:hover': { boxShadow: 'none' },
              }}
              variant="contained"
              onClick={
                datasetRadioSelect === t('fileUpload.newDataset')
                  ? handleSubmit(readFiles)
                  : handleSubmit(sharePointExecution)
              }
              disabled={
                datasetRadioSelect === t('fileUpload.newDataset')
                  ? !getValues().datasetName || fileUpload.uploadedFiles.length === 0
                  : !siteUrl || !workflowUrl || !sharePointDatasetName
              } //Disabled the button if the fields are empty
            >
              {t('fileUpload.createdataset')}
            </Button>
          )}
          {selection === 'existing' && (
            <Button
              sx={{
                fontSize: '14px',
                boxShadow: 'none',
                height: '2rem',
                '&:hover': { boxShadow: 'none' },
              }}
              variant="contained"
              onClick={
                existingDatasetRadioSelect === t('fileUpload.existingSharePointDataset')
                  ? handleSubmit(sharePointUpdate)
                  : existingDatasetRadioSelect === t('fileUpload.existingDataset')
                    ? handleSubmit(readFiles)
                    : handleSubmit(sharePointExecution)
              }
              disabled={
                existingDatasetRadioSelect === t('fileUpload.existingSharePointDataset')
                  ? !siteUrl || !workflowUrl || !sharePointDatasetName
                  : fileUpload.uploadedFiles.length === 0
              } //Disabled the button if the fields are empty
            >
              {t('fileUpload.updatedataset')}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DatasetModal;
