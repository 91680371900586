import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import {
  Box,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Typography,
  InputLabel,
  Icon,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { MyAgentsData } from '../../agents/myAgents/MyAgents';
import { useAppSelector } from '../../../../../reduxStore/redux-hooks';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';

export interface AgentState {
  id: string;
  name: string;
  x: number;
  y: number;
}

export interface AgentTransition {
  source: string;
  target: string;
}

interface AgentsTranistion {
  selectedAgents: MyAgentsData[];
  agentsTransitionGraph: { [key: string]: string[] };
  setAgentsTransitionGraph: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
  agentsTransitionTypeAllowed: boolean;
  setAgentsTransitionTypeAllowed: (agentsTransitionTypeAllowed: boolean) => void;
}

function AgentTransitions({
  selectedAgents,
  agentsTransitionGraph,
  setAgentsTransitionGraph,
  agentsTransitionTypeAllowed,
  setAgentsTransitionTypeAllowed,
}: AgentsTranistion) {
  const { t } = useTranslation();
  const [agents, setAgents] = useState<AgentState[]>([]);
  const [agentsTransition, setAgentsTransition] = useState<AgentTransition[]>([]);
  const [fromAgent, setFromAgent] = useState('');
  const [toAgent, setToAgent] = useState('');
  const svgRef = useRef<SVGSVGElement>(null);
  const gRef = useRef<SVGGElement>(null);
  const zoomRef = useRef<d3.ZoomBehavior<any, any> | null>(null);
  const { isTeamView } = useAppSelector((state) => state.teams);
  const agentNodeWidth = 80;
  const agentNodeHeight = 40;
  const [agentsTransitionHistory, setAgentsTransitionHistory] = useState<{ [key: string]: string[] }[]>([{}]);
  const [agentsTransitionHistoryIndex, setAgentsTransitionHistoryIndex] = useState(0);

  useEffect(() => {
    loadInitialAgentsAndTransitions();

    // Save the initial graph into agentsTransitionHistory
    setAgentsTransitionHistory([agentsTransitionGraph]);
  }, [selectedAgents]);

  useEffect(() => {
    createAgentsTranistionGraph();
  }, [agents, agentsTransition, agentsTransitionGraph]);

  const loadInitialAgentsAndTransitions = () => {
    const initialAgents = selectedAgents.map((agent, index) => ({
      id: agent._id,
      name: agent.name,
      x: 100 + index * 200,
      y: 100,
    }));
    setAgents(initialAgents);

    const initialAgensTransitions: AgentTransition[] = [];
    Object.keys(agentsTransitionGraph).forEach((source) => {
      if (source !== 'initial_state') {
        agentsTransitionGraph[source].forEach((target) => {
          initialAgensTransitions.push({ source, target });
        });
      }
    });
    setAgentsTransition(initialAgensTransitions);
  };

  const createAgentsTranistionGraph = () => {
    const svgElement = svgRef.current;
    const gElement = gRef.current;
    if (!svgElement || !gElement) return;

    const svg = d3.select(svgElement);
    const g = d3.select(gElement);

    const svgWidth = 1300;
    const svgHeight = 500;
    const margin = 50; // Define a margin to create a gap between nodes and SVG boundaries

    const simulation = d3
      .forceSimulation(agents)
      .force('charge', d3.forceManyBody().strength(-100))
      .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2))
      .force(
        'link',
        d3
          .forceLink(
            agentsTransition.map((transition) => ({
              ...transition,
              source: transition.source,
              target: transition.target,
            }))
          )
          .id((d: any) => d.id)
          .distance(200)
      )
      //This collision will ensure that nodes do not collide with each other and will spread them out within the available space.
      .force('collision', d3.forceCollide().radius(80))
      //This boundary force will adjust the positions of the nodes to keep them within the specified boundaries.
      .force('boundary', () => {
        agents.forEach((d) => {
          d.x = Math.max(margin, Math.min(svgWidth - margin, d.x));
          d.y = Math.max(margin, Math.min(svgHeight - margin, d.y));
        });
      })
      .on('tick', () => {
        g.selectAll('.link').remove();
        g.selectAll('.start-dot').remove();
        g.selectAll('.node').remove();
        g.selectAll('.label').remove();
        g.selectAll('.initial-node').remove();
        g.selectAll('.initial-link').remove();

        // Create a group for each node
        const nodeGroups = g.selectAll('.node-group').data(agents).join('g').attr('class', 'node-group');

        // Add rectangle for each node
        nodeGroups
          .append('rect')
          .attr('class', 'node')
          .attr('width', agentNodeWidth)
          .attr('height', agentNodeHeight)
          .attr('x', (d) => d.x - 40)
          .attr('y', (d) => d.y - 20)
          .attr('fill', 'white')
          .attr('stroke', 'grey')
          .attr('rx', 5)
          .attr('ry', 5)
          .style('filter', 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))');

        // Add text label
        nodeGroups
          .append('text')
          .attr('class', 'label')
          .attr('x', (d) => d.x)
          .attr('y', (d) => d.y)
          .attr('dy', 5)
          .attr('text-anchor', 'middle')
          .text((d) => (d.name.length > 6 ? `${d.name.slice(0, 6)}...` : d.name));

        // Add title element for tooltip
        nodeGroups.append('title').text((d) => d.name);

        // Append links (curved / straight lines)
        g.selectAll('.link')
          .data(agentsTransition)
          .join('path')
          .attr('class', 'link')
          .attr('d', (d) => {
            const source = agents.find((agent) => agent.id === d.source) as AgentState;
            const target = agents.find((agent) => agent.id === d.target) as AgentState;
            return generatePath(source, target);
          })
          .attr('stroke', 'black')
          .attr('fill', 'none')
          .attr('marker-end', 'url(#arrow)');

        // Append initial circle node and link if there are transitions
        if (agentsTransition.length > 0 && agents.length > 0 && agentsTransitionGraph.initial_state) {
          const initialStateId = agentsTransitionGraph.initial_state[0];
          const initialState = agents.find((agent) => agent.id === initialStateId);

          if (initialState) {
            const initialNodeX = initialState.x - 100;
            const initialNodeY = initialState.y;

            g.append('circle')
              .attr('class', 'initial-node')
              .attr('r', 10)
              .attr('cx', initialNodeX)
              .attr('cy', initialNodeY)
              .attr('fill', 'black');

            g.append('line')
              .attr('class', 'initial-link')
              .attr('x1', initialNodeX)
              .attr('y1', initialNodeY)
              .attr('x2', initialState.x - 40)
              .attr('y2', initialState.y)
              .attr('stroke', 'black')
              .attr('marker-end', 'url(#arrow)');
          }
        }
      });

    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrow')
      .attr('viewBox', '0 0 10 10')
      .attr('refX', 5)
      .attr('refY', 5)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto-start-reverse')
      .append('path')
      .attr('d', 'M 0 0 L 10 5 L 0 10 z')
      .attr('fill', 'black');

    const zoom = d3.zoom<SVGSVGElement, unknown>().on('zoom', (event) => {
      g.attr('transform', event.transform);
    });

    svg.call(zoom);
    zoomRef.current = zoom;

    // Set initial zoom level
    const initialTransform = d3.zoomIdentity.translate(0, 0).scale(1); // Adjust the scale value as needed
    svg.call(zoom.transform, initialTransform);

    return () => {
      simulation.stop();
    };
  };

  // Function to calculate the intersection point of a line with a rectangle
  const calculateIntersection = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    rectWidth: number,
    rectHeight: number
  ) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    const centerX = x1;
    const centerY = y1;

    let intersectX, intersectY;

    // Calculate the angle of the line with respect to the x-axis
    const angle = Math.atan2(dy, dx);

    // Calculate the half-width and half-height of the rectangle
    const halfWidth = rectWidth / 2;
    const halfHeight = rectHeight / 2;

    // Check for horizontal and vertical lines
    if (Math.abs(dx) < 1e-6) {
      // Vertical line
      intersectX = x1;
      intersectY = centerY + (dy > 0 ? halfHeight : -halfHeight);
    } else if (Math.abs(dy) < 1e-6) {
      // Horizontal line
      intersectX = centerX + (dx > 0 ? halfWidth : -halfWidth);
      intersectY = y1;
    } else {
      // Calculate the intersection points with the rectangle's sides
      const tanAngle = Math.tan(angle);
      const xIntersection = centerX + (dx > 0 ? halfWidth : -halfWidth);
      const yIntersection = centerY + tanAngle * (xIntersection - centerX);

      const yIntersection2 = centerY + (dy > 0 ? halfHeight : -halfHeight);
      const xIntersection2 = centerX + (yIntersection2 - centerY) / tanAngle;

      // Determine which intersection point is closer to the center
      if (
        xIntersection >= centerX - halfWidth &&
        xIntersection <= centerX + halfWidth &&
        yIntersection >= centerY - halfHeight &&
        yIntersection <= centerY + halfHeight
      ) {
        intersectX = xIntersection;
        intersectY = yIntersection;
      } else {
        intersectX = xIntersection2;
        intersectY = yIntersection2;
      }
    }

    return {
      x: intersectX,
      y: intersectY,
    };
  };

  // Custom path generator for straight or curved lines
  const generatePath = (source: AgentState, target: AgentState) => {
    const sourceIntersection = calculateIntersection(
      source.x,
      source.y,
      target.x,
      target.y,
      agentNodeWidth,
      agentNodeHeight
    );
    const targetIntersection = calculateIntersection(
      target.x,
      target.y,
      source.x,
      source.y,
      agentNodeWidth,
      agentNodeHeight
    );

    const distance = Math.sqrt(
      Math.pow(targetIntersection.x - sourceIntersection.x, 2) +
        Math.pow(targetIntersection.y - sourceIntersection.y, 2)
    );

    if (distance < 150) {
      // Use a straight line if the distance is short
      return `M ${sourceIntersection.x},${sourceIntersection.y} L ${targetIntersection.x},${targetIntersection.y}`;
    } else {
      // Use a curved path if the distance is long
      const midX = (sourceIntersection.x + targetIntersection.x) / 2;
      const midY = (sourceIntersection.y + targetIntersection.y) / 2;
      const controlX = midX + (target.y - source.y) / 4;
      const controlY = midY - (target.x - source.x) / 4;

      return `M ${sourceIntersection.x},${sourceIntersection.y} Q ${controlX},${controlY} ${targetIntersection.x},${targetIntersection.y}`;
    }
  };

  const addTransition = () => {
    if (fromAgent && toAgent) {
      setAgentsTransition([...agentsTransition, { source: fromAgent, target: toAgent }]);
      setFromAgent('');
      setToAgent('');
      setAgentsTransitionGraph((prevGraph) => {
        // Create a new object to avoid modifying a frozen object
        const newAgentsTransitionGraph = JSON.parse(JSON.stringify(prevGraph));
        if (!newAgentsTransitionGraph.initial_state || newAgentsTransitionGraph.initial_state.length === 0) {
          newAgentsTransitionGraph.initial_state = [fromAgent];
        }
        if (!newAgentsTransitionGraph[fromAgent]) {
          newAgentsTransitionGraph[fromAgent] = [];
        }
        if (!newAgentsTransitionGraph[fromAgent].includes(toAgent)) {
          newAgentsTransitionGraph[fromAgent].push(toAgent);
        }
        // Update transition history
        setAgentsTransitionHistory((prevHistory) => {
          const newHistory = [...prevHistory.slice(0, agentsTransitionHistoryIndex + 1), newAgentsTransitionGraph];
          return newHistory;
        });
        setAgentsTransitionHistoryIndex(agentsTransitionHistoryIndex + 1);
        return newAgentsTransitionGraph;
      });
    }
  };

  const zoomIn = () => {
    if (zoomRef.current) {
      d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 1.2);
    }
  };

  const zoomOut = () => {
    if (zoomRef.current) {
      d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 0.8);
    }
  };

  const clearAgentTransitionGraph = () => {
    setAgentsTransition([]);
    setAgentsTransitionGraph({});
    setAgentsTransitionHistory([{}]);
    setAgentsTransitionHistoryIndex(0);
  };

  //This function returns the list of states that can be transitioned to from the given `fromAgent`.
  //It filters out the states that are already connected to the `fromAgent` and the `fromAgent` itself.
  const getAvailableToStates = (fromAgent: string) => {
    if (getAvailableFromStates().length === 0) {
      return [];
    }
    const connectedTargets = agentsTransition
      .filter((transition) => transition.source === fromAgent)
      .map((transition) => transition.target);
    return agents.filter((agent) => agent.id !== fromAgent && !connectedTargets.includes(agent.id));
  };

  //This function returns the list of states that can be used as the source of a transition.
  //It filters out the states that are already connected to all available states.
  const getAvailableFromStates = () => {
    if (agentsTransition.length === 0) {
      // If there are no transitions, all states are available as source states.
      return agents;
    }
    const usedStateIds = agentsTransition.flatMap((transition) => [transition.source, transition.target]);
    const availableFromStates = agents.filter((agent) => usedStateIds.includes(agent.id));

    // Filter out nodes that are connected to all available nodes
    return availableFromStates.filter((state) => {
      const connectedTargets = agentsTransition
        .filter((transition) => transition.source === state.id)
        .map((transition) => transition.target);
      const availableTargets = agents.filter((agent) => agent.id !== state.id).map((s) => s.id);
      // A state is available as a source if it is not connected to all other states.
      return connectedTargets.length < availableTargets.length;
    });
  };

  const changeAgentsTransitionType = (isTransitionTypeAllowed: boolean) => {
    setAgentsTransitionTypeAllowed(isTransitionTypeAllowed);
  };

  const undoAgentTransitions = () => {
    if (agentsTransitionHistoryIndex > 0) {
      const previousGraph = agentsTransitionHistory[agentsTransitionHistoryIndex - 1];
      setAgentsTransitionGraph(previousGraph);
      setAgentsTransition(() => {
        const initialAgensTransitions: AgentTransition[] = [];
        Object.keys(previousGraph).forEach((source) => {
          if (source !== 'initial_state') {
            previousGraph[source].forEach((target) => {
              initialAgensTransitions.push({ source, target });
            });
          }
        });
        return initialAgensTransitions;
      });
      setAgentsTransitionHistoryIndex(agentsTransitionHistoryIndex - 1);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
        <InputLabel className="mui-form-label">{t('rightPanel.team.createTeam.agentTransitions')} :</InputLabel>
        <Tooltip title={<Box>{t('rightPanel.team.createTeam.agentTransistionsHelperText')}</Box>} arrow>
          <Icon sx={{ display: 'inline-flex', marginLeft: '5px', cursor: 'pointer', alignItems: 'center' }}>
            <InfoIcon sx={{ width: '20px' }} />
          </Icon>
        </Tooltip>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <Autocomplete
            options={agentsTransition.length === 0 ? agents : getAvailableFromStates()}
            getOptionLabel={(option) => option.name}
            value={agents.find((agent) => agent.id === fromAgent) || null}
            disabled={isTeamView}
            onChange={(event, newValue) => setFromAgent(newValue ? newValue.id : '')}
            renderInput={(params) => (
              <TextField {...params} label={t('rightPanel.team.createTeam.fromAgent')} variant="standard" />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            options={getAvailableToStates(fromAgent)}
            getOptionLabel={(option) => option.name}
            value={agents.find((agent) => agent.id === toAgent) || null}
            disabled={isTeamView}
            onChange={(event, newValue) => setToAgent(newValue ? newValue.id : '')}
            renderInput={(params) => (
              <TextField {...params} label={t('rightPanel.team.createTeam.toAgent')} variant="standard" />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            disabled={!fromAgent || !toAgent}
            variant="contained"
            onClick={addTransition}
            sx={{ textTransform: 'none' }}
          >
            {t('rightPanel.team.createTeam.addTransistion')}
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          border: '1px solid #ccc',
          width: '100%',
          height: 500,
          position: 'relative',
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            float: 'right',
            top: '10px',
            right: '10px',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          {agentsTransition.length > 0 && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup
                  sx={{ display: 'flex', flexDirection: 'row', marginRight: '-10px' }}
                  className="mui-form-label"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isTeamView}
                        checked={agentsTransitionTypeAllowed}
                        onChange={(event) => changeAgentsTransitionType(event.target.checked)}
                      />
                    }
                    label={t('rightPanel.team.createTeam.transitionAllowed')}
                  />
                </FormGroup>
                <Tooltip title={<Box>{t('rightPanel.team.createTeam.agentTransistionsTypeHelperText')}</Box>} arrow>
                  <Icon sx={{ display: 'inline-flex', cursor: 'pointer', alignItems: 'center' }}>
                    <InfoIcon sx={{ width: '20px' }} />
                  </Icon>
                </Tooltip>
              </Box>
            </>
          )}
          <Tooltip title={t('rightPanel.team.createTeam.undo')} enterDelay={500} arrow placement="top">
            <span>
              <Button
                size="small"
                variant="outlined"
                sx={{ color: 'gray', borderColor: 'gray', minWidth: '35px', width: '35px', height: '25px' }}
                onClick={undoAgentTransitions}
                disabled={agentsTransitionHistoryIndex === 0}
              >
                <UndoIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('rightPanel.team.createTeam.clear')} enterDelay={500} arrow placement="top">
            <span>
              <Button
                size="small"
                variant="outlined"
                sx={{ color: 'gray', borderColor: 'gray', minWidth: '35px', width: '35px', height: '25px' }}
                onClick={clearAgentTransitionGraph}
                disabled={!agentsTransition.length}
              >
                <ClearIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('rightPanel.team.createTeam.zoomIn')} enterDelay={500} arrow placement="top">
            <span>
              <Button
                size="small"
                variant="outlined"
                sx={{ color: 'gray', borderColor: 'gray', minWidth: '30px', height: '25px' }}
                onClick={zoomIn}
              >
                <Typography sx={{ fontSize: '1.5rem', lineHeight: '1.5rem' }}>+</Typography>
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={t('rightPanel.team.createTeam.zoomOut')} enterDelay={500} arrow placement="top">
            <span>
              <Button
                size="small"
                variant="outlined"
                sx={{ color: 'gray', borderColor: 'gray', minWidth: '35px', height: '25px' }}
                onClick={zoomOut}
              >
                <Typography sx={{ fontSize: '1.5rem', lineHeight: '1.5rem' }}>-</Typography>
              </Button>
            </span>
          </Tooltip>
        </Box>
        <svg ref={svgRef} width="100%" height="500" style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}>
          <g ref={gRef}></g>
        </svg>
      </Box>
    </Box>
  );
}

export default AgentTransitions;
