import { useAppDispatch, useAppSelector } from '../../../../../../reduxStore/redux-hooks';
import { setFiles, toggleModal } from '../../../../../../reduxStore/slices/FileUploadSlice';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import { MAX_LENGTH_DATASET_NAME, MIN_LENGTH_DATASET_NAME } from '../../../../../staticComponents/StaticHtmlGenerator';
import { enqueueSnackbar } from 'notistack';
import { findEmptyFileName } from 'components/staticComponents/staticUtiles';
import { useEffect } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Input, Typography } from '@mui/material';
import { updateFileSelectionState } from '../../../../../../reduxStore/slices/FileUploadSlice';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface FormValidationProps<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  reset: (values: Partial<T>) => void;
}

interface NewDatasetProps {
  formValidation: FormValidationProps<{
    datasetName: string;
    datasetDescription: string;
  }>;
}

const NewDataset: React.FC<NewDatasetProps> = ({ formValidation }: NewDatasetProps) => {
  const { t } = useTranslation();
  const { fileUpload, fileSelection } = useAppSelector((state) => state.fileUpload);
  const { control, errors, reset } = formValidation;
  const dispatch = useAppDispatch();
  const enableVisionSummary = (checked: boolean) => {
    dispatch(
      updateFileSelectionState({
        isVisionSummaryEnabled: checked,
      })
    );
  };
  useEffect(() => {
    reset({
      datasetName: fileUpload.uploadedFiles.length > 0 ? fileUpload.uploadedFiles[0].name : '',
      datasetDescription: '',
    });
  }, [fileUpload.uploadedFiles, reset]);

  const fileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files && files!.length === 0) return;
    if (findEmptyFileName(files!)) {
      enqueueSnackbar(t('fileUpload.fileIsEmpty', { fileName: findEmptyFileName(files!) }), {
        variant: 'error',
      });
      return;
    }

    dispatch(setFiles(Array.from(files as FileList)));
    dispatch(toggleModal());
    e.target.value = '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Controller
        control={control}
        name="datasetName"
        rules={{
          required: t('rightPanel.dataset.errorsValidationMessages.nameRequired'),
          validate: {
            noOnlySpaces: (value) => {
              const isValid = value.trim().length > MIN_LENGTH_DATASET_NAME;
              return isValid || t('rightPanel.dataset.errorsValidationMessages.nameRequired');
            },
            maxLength: (value) => {
              const isValid = value.trim().length <= MAX_LENGTH_DATASET_NAME;
              return isValid || t('rightPanel.dataset.errorsValidationMessages.nameCharacterLimit');
            },
          },
        }}
        render={({ field }) => {
          const truncatedDatasetName =
            field.value.length > MAX_LENGTH_DATASET_NAME
              ? `${field.value.slice(0, MAX_LENGTH_DATASET_NAME)}`
              : field.value;

          return (
            <FormControl fullWidth variant="standard" sx={{ marginBottom: '1.5rem' }}>
              <Typography fontSize={16} fontWeight={700}>
                {t('fileUpload.enterName')}
              </Typography>
              <Input
                {...field}
                fullWidth
                id="dataset-name"
                value={truncatedDatasetName}
                sx={{ padding: '0.2rem 0rem' }}
                error={!!errors.datasetName}
                inputProps={{ maxLength: MAX_LENGTH_DATASET_NAME + 1 }}
              />
              <Box>
                <FormHelperText
                  error
                  sx={{
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  {!!errors.datasetName && errors.datasetName.message}
                </FormHelperText>
              </Box>
            </FormControl>
          );
        }}
      ></Controller>

      <Controller
        control={control}
        name="datasetDescription"
        rules={{
          maxLength: { value: 50, message: t('rightPanel.dataset.errorsValidationMessages.descriptionCharacterLimit') },
        }}
        render={({ field }) => (
          <FormControl fullWidth variant="standard" sx={{ marginBottom: '1.5rem' }}>
            <Typography fontSize={16} fontWeight={700}>
              {t('fileUpload.description')}
            </Typography>
            <Input
              {...field}
              fullWidth
              id="dataset-description"
              sx={{ padding: '0.2rem 0rem' }}
              error={!!errors.datasetDescription}
            />
            <Box>
              <FormHelperText
                error
                sx={{
                  position: 'absolute',
                  width: '100%',
                }}
              >
                {!!errors.datasetDescription && errors.datasetDescription.message}
              </FormHelperText>
            </Box>
          </FormControl>
        )}
      ></Controller>
      <Box margin={'1rem 0 1.5rem'} display={'flex'}>
        <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} sx={{ marginRight: '1rem' }}>
          {t('fileUpload.uploadFile').toUpperCase()}
          <input type="file" onChange={fileInput} hidden multiple />
        </Button>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography fontSize={16} color={'#636363'} sx={{ alignSelf: 'center' }}>
            {t('fileUpload.numberofFilesSelected')} {fileUpload.uploadedFiles.length}
          </Typography>
        </Box>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={fileSelection.isVisionSummaryEnabled}
            onChange={(event) => enableVisionSummary(event.target.checked)}
          />
        }
        label={t('fileUpload.visionSummary')}
      />
    </Box>
  );
};

export default NewDataset;
