import * as assets from '../../assets';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import ImageButton from '../../components/sharedComponents/ImageButton';
import { useEffect, useRef, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'reduxStore/redux-hooks';
import { findEmptyFileName, showSnackbarErrorMessage } from 'components/staticComponents/staticUtiles';
import { enqueueSnackbar } from 'notistack';
import { setImageUploadData, updateFileSelectionState } from 'reduxStore/slices/FileUploadSlice';
import { supportedImageTypes } from 'components/rightPanel/subpanel/dataset/createDataset/DatasetModal';
import Api, { ApiError, SkillParameters } from '../../data/api/Api';
import { ImageUploadTooltip } from 'components/staticComponents/StaticHtmlGenerator';

interface imageUploadProps {
  disabled: boolean;
}

enum ImageUploadOption {
  imageUrl = 'url',
  browseImage = 'browse',
}

const ImageUpload = ({ disabled }: imageUploadProps) => {
  const [showImageUploadOptions, setShowImageUploadOptions] = useState(false);
  const theme = useTheme();
  const [openImageDialog, setOpenImageDialog] = useState(true);
  const closeImageDialog = () => setOpenImageDialog(false);
  const [imageUploadOption, setImageUploadOption] = useState<
    ImageUploadOption.imageUrl | ImageUploadOption.browseImage
  >(ImageUploadOption.imageUrl);
  const [imageUrl, setImageUrl] = useState('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { selectedModelHistory } = useAppSelector((state) => state.history.historyState);

  const handleImageUploadOptionsClick = () => {
    setOpenImageDialog(true);
    setShowImageUploadOptions(true);
  };

  useEffect(() => {
    if (openImageDialog) {
      setImageUploadOption(ImageUploadOption.imageUrl);
    }
    setImageUrl('');
  }, [openImageDialog]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ImageUploadOption.imageUrl | ImageUploadOption.browseImage;
    setImageUploadOption(value);
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(event.target.value);
  };

  const convertImageToDataUrl = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    closeImageDialog();
    if (files) {
      const fileArray = Array.from(files);
      const BYTES_IN_KILOBYTE = 1024;
      const MAX_FILE_SIZE_MB = 1;
      if (files && files!.length === 0) return;

      if (findEmptyFileName(files!)) {
        enqueueSnackbar(t('fileUpload.fileIsEmpty', { fileName: findEmptyFileName(files!) }), {
          variant: 'error',
        });
        return;
      }

      const TOTAL_FILES_SIZE_IN_BYTES = fileArray.reduce((total, file) => total + file.size, 0);
      const TOTAL_FILES_SIZE_IN_MB = TOTAL_FILES_SIZE_IN_BYTES / (BYTES_IN_KILOBYTE * BYTES_IN_KILOBYTE);

      if (TOTAL_FILES_SIZE_IN_MB > MAX_FILE_SIZE_MB) {
        enqueueSnackbar(t('imageUpload.imageFileLimitExceed', { fileName: findEmptyFileName(files!) }), {
          variant: 'error',
        });
        return;
      }
      const imageFiles = Array.from(files).filter((file) => file.type.startsWith('image/'));
      if (imageFiles.length > 0) {
        const imageUrls = await Promise.all(imageFiles.map((file) => convertImageToDataUrl(file)));
        const newImageFileNames = imageFiles.map((file) => file.name);
        dispatch(
          updateFileSelectionState({
            responseMessage: false,
          })
        );
        dispatch(
          setImageUploadData({
            imageURLList: imageUrls,
            imagesNames: newImageFileNames,
          })
        );
      }
    }
    event.target.value = '';
  };

  const handleClickOkButton = async () => {
    try {
      const validateImageURL = await Api.validateInputFileURL(
        imageUrl,
        selectedModelHistory as SkillParameters['model_name']
      );
      if (validateImageURL) {
        let urlList = imageUrl.split(',');
        urlList = urlList.filter((url, index, self) => url.trim() !== '' && self.indexOf(url) === index);
        const imageUrlPattern = /\.(jpg|png)$/i;
        // Filter the URL list to include only valid image URLs
        const inValidImageUrls = urlList.filter((imageUrl) => !imageUrlPattern.test(imageUrl.trim()));
        const invalidImageUrlPrefix = urlList.filter((imageUrl) => !imageUrl.startsWith('https'));
        if (inValidImageUrls.length > 0 || invalidImageUrlPrefix.length > 0) {
          enqueueSnackbar(t('imageUpload.uploadValidURLsText'), {
            variant: 'error',
          });
          return;
        }
        if (urlList.length >= 1 && urlList[0]) {
          dispatch(
            setImageUploadData({
              imageURLList: urlList,
              imagesNames: [],
            })
          );
        }
        setImageUploadOption(ImageUploadOption.imageUrl);
        closeImageDialog();
      }
    } catch (error) {
      setImageUrl('');
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          [theme.breakpoints.down('sm')]: {
            position: 'static',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            left: '1px',
          }}
        >
          <ImageButton
            disabled={disabled}
            srcFile={assets.imageUploadIcon}
            handleClick={handleImageUploadOptionsClick}
          />
        </Box>
      </Box>
      <Box sx={{ width: '27rem', backgroundColor: 'chocolate' }}>
        {showImageUploadOptions && (
          <Box>
            <Dialog
              open={openImageDialog}
              onClose={closeImageDialog}
              maxWidth="lg"
              PaperProps={{ sx: { width: '35rem' } }}
            >
              <DialogTitle>
                {t('imageUpload.imageUploadOptionsText')}
                <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{ImageUploadTooltip}</div>} arrow>
                  <InfoIcon
                    sx={{
                      fontSize: '19px',
                      cursor: 'pointer',
                      marginLeft: '9px',
                      color: '#4282FE',
                      verticalAlign: 'middle',
                    }}
                  />
                </Tooltip>
              </DialogTitle>
              <DialogContent>
                <RadioGroup value={imageUploadOption} onChange={handleOptionChange}>
                  <FormControlLabel value="url" control={<Radio />} label="URL" />
                  {imageUploadOption === 'url' && (
                    <TextField
                      id="outlined-multiline-flexible"
                      multiline
                      minRows={2}
                      maxRows={6}
                      label="Enter Image URL"
                      value={imageUrl}
                      onChange={handleUrlChange}
                      fullWidth
                    />
                  )}
                  <FormControlLabel value="browse" control={<Radio />} label="Browse" />
                </RadioGroup>
                {imageUploadOption === 'browse' && (
                  <Button variant="contained" style={{ left: '31px' }} onClick={handleBrowseClick}>
                    {t('imageUpload.browseFilesButtonText')}
                  </Button>
                )}
                <Input
                  type="file"
                  inputProps={{
                    accept: supportedImageTypes,
                    multiple: true,
                  }}
                  inputRef={fileInputRef}
                  sx={{
                    position: 'absolute',
                    width: 0,
                    height: 0,
                    overflow: 'hidden',
                    opacity: 0,
                  }}
                  onChange={handleFileSelection}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeImageDialog}>{t('imageUpload.cancel')}</Button>
                <Button onClick={handleClickOkButton}>{t('imageUpload.ok')}</Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ImageUpload;
