import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, TextField, Grid, FormControl, NativeSelect } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Api, { ApiError } from '../../data/api/Api';
import { useTranslation } from 'react-i18next';
import { showSnackbarErrorMessage } from '../../components/staticComponents/staticUtiles';

interface FeedbackPayload {
  user_rating: number;
  user_feedback_text: string;
  user_experience: string;
}
interface FeedbackData {
  feedback_timestamp: string;
}

const FeedbackForm = () => {
  const [feedbackSteps, setFeedbackSteps] = useState(1);
  const [displayFeedback, setDisplayFeedback] = useState<boolean>(false);
  const [feedbackData, setFeedbackData] = useState<FeedbackPayload>({
    user_rating: 0,
    user_feedback_text: '',
    user_experience: 'More than 2hrs or over 25%',
  });
  const { t } = useTranslation();
  const [nextButtonDisable, setNextButtonDisable] = useState(true);

  const productivityInHoursAndPercentage = [
    'More than 2hrs or over 25%',
    'More than 4hrs or over 50%',
    'More than 6hrs or over 75%',
    'More than 8hrs or over 100%',
  ];

  const calculateQuarter = (date: Date): number => {
    const month = date.getMonth();
    return Math.floor(month / 3) + 1;
  };

  const checkFeedbackEligibility = async () => {
    try {
      const lastSubmittedFeedback = await Api.lastSubmittedFeedbackTime();
      const feedbackTimestamp = lastSubmittedFeedback.data as FeedbackData;

      let showFeedback: boolean = true;
      if (feedbackTimestamp) {
        const feedbackDate = new Date(feedbackTimestamp.feedback_timestamp).getMonth();
        const currentQuarter = calculateQuarter(new Date());
        const feedbackQuarter = Math.floor(feedbackDate / 3) + 1;

        if (currentQuarter === feedbackQuarter) {
          showFeedback = false;
        }
      }
      setDisplayFeedback(showFeedback);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      console.error('Error in fetching feedback time:', error);
    }
  };

  useEffect(() => {
    checkFeedbackEligibility();
  }, []);

  const saveFeedback = async () => {
    try {
      await Api.provideFeedback(feedbackData);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      console.error('Error saving feedback:', error);
    }
  };

  const goToNextFeedbackStep = () => {
    setFeedbackSteps((prev) => Math.min(prev + 1, 4));
  };
  const backToPreviousFeedbackStep = () => setFeedbackSteps((prev) => Math.max(prev - 1, 1));

  const handleCloseForm = () => {
    const isFeedbackDataValid = feedbackData.user_rating > 0 || feedbackData.user_feedback_text.trim() !== '';
    if (isFeedbackDataValid) {
      saveFeedback();
    }
    setDisplayFeedback(false);
  };

  const handleRating = async (rating: number) => {
    setNextButtonDisable(false);
    setFeedbackData((prev) => ({ ...prev, user_rating: rating }));
  };

  const submitFeedback = () => {
    saveFeedback();
    setFeedbackSteps((prev) => Math.min(prev + 1, 4));
  };

  const renderStepContent = () => {
    // Define constants
    const STEP_RATING_SELECTION = 1;
    const STEP_RATING_EXPLANATION = 2;
    const STEP_PRODUCTIVITY_INPUT = 3;
    const STEP_FEEDBACK_THANKYOU = 4;

    switch (feedbackSteps) {
      case STEP_RATING_SELECTION:
        return (
          <Box>
            <Grid container justifyContent="center" spacing={2}>
              {Array.from({ length: 10 }, (_, index) => index + 1).map((num) => (
                <Grid item key={num}>
                  <Box
                    onClick={() => handleRating(num)}
                    sx={{
                      width: '28px',
                      height: '28px',
                      borderRadius: '4px',
                      border: '2px solid',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      fontWeight: 700,
                      ...getBoxStyles(num),
                    }}
                  >
                    {num}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case STEP_RATING_EXPLANATION:
        return (
          <Box sx={{ marginTop: '20px' }}>
            <Typography>{t('feedback.ratingExplain')}</Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={feedbackData.user_feedback_text}
              onChange={(event) => setFeedbackData({ ...feedbackData, user_feedback_text: event.target.value })}
              placeholder={t('feedback.typeReason')}
              variant="outlined"
              sx={{ marginTop: '10px', marginBottom: '20px' }}
            />
          </Box>
        );
      case STEP_PRODUCTIVITY_INPUT:
        return (
          <Box>
            <Typography>{t('feedback.productivityPercentage')}</Typography>
            <FormControl fullWidth>
              <NativeSelect
                value={feedbackData.user_experience || productivityInHoursAndPercentage[0]}
                onChange={(e) => setFeedbackData({ ...feedbackData, user_experience: e.target.value })}
              >
                {productivityInHoursAndPercentage.map((productivityOption) => (
                  <option key={productivityOption} value={productivityOption}>
                    {productivityOption}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
        );
      case STEP_FEEDBACK_THANKYOU:
        return (
          <Typography variant="h6" sx={{ fontSize: '18px', marginTop: '30px' }}>
            {t('feedback.thanyouFeedback')}
          </Typography>
        );
      default:
        return null;
    }
  };

  const getBoxStyles = (num: number) => ({
    borderColor: num <= 6 ? '#C4515C' : num <= 8 ? '#EAA300' : '#13A10E',
    color: feedbackData.user_rating === num ? 'white' : '#C4515C',
    backgroundColor: feedbackData.user_rating === num ? '#C4515C' : 'white',
  });

  return (
    <>
      {displayFeedback && (
        <Box
          sx={{
            borderRadius: '8px',
            padding: '20px',
            width: 'auto',
            margin: 'auto',
            fontFamily: 'Arial, sans-serif',
            background: '#F6F9FF',
            boxShadow: '0 0px 3px rgba(0,0,0,0.1)',
            position: 'absolute',
            right: 0,
            bottom: 0,
            zIndex: 9999,
            height: feedbackSteps === 3 || feedbackSteps === 4 ? '380px' : 'inherit',
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: '700' }}>
              {t('feedback.provideFeedback')}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography>Step {feedbackSteps} of 4</Typography>
              <IconButton onClick={handleCloseForm} sx={{ marginLeft: '10px' }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {feedbackSteps === 1 && (
            <>
              <Typography>{t('feedback.platformRate')}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0 5px' }}>
                <Typography sx={{ fontSize: '12px' }}>{t('feedback.notLike')}</Typography>
                <Typography sx={{ fontSize: '12px' }}>{t('feedback.veryLike')}</Typography>
              </Box>
            </>
          )}

          {renderStepContent()}
          <Box
            sx={{
              display: 'flex',
              justifyContent: feedbackSteps > 1 ? 'space-between' : 'end',
              marginTop: '20px',
            }}
          >
            {feedbackSteps > 1 && feedbackSteps < 4 && (
              <Button variant="outlined" onClick={backToPreviousFeedbackStep}>
                {t('feedback.back')}
              </Button>
            )}
            {feedbackSteps < 3 && (
              <Button variant="contained" onClick={goToNextFeedbackStep} disabled={nextButtonDisable}>
                {t('feedback.next')}
              </Button>
            )}
            {feedbackSteps === 3 && (
              <Button variant="contained" onClick={submitFeedback}>
                {t('feedback.submit')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default FeedbackForm;
