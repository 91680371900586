import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TopKState {
  value: number;
}
const initialState: TopKState = {
  value: 5, // Default topK
};

const topkSlice = createSlice({
  name: 'topK',
  initialState,
  reducers: {
    setTopK: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export const { setTopK } = topkSlice.actions;
export default topkSlice.reducer;
