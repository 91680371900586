import { chatPageNavigation } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import UsePageNavigation from '../../../../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import Api, { ApiError } from '../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import { agentSharedUsers, refreshAgentsList } from '../../../../../reduxStore/slices/AgentsSlice';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { MyAgentsData } from '../myAgents/MyAgents';
import { closeBasicPopoverManually, setOpenCreateAgentOrTeamPage } from '../../../../../reduxStore/slices/CommonSlice';
import { UserDetails } from '../../developerKey/DeveloperKey';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';

interface ShareAgentProps {
  setShowShareAgentDialog: (showAgentDialog: boolean) => void;
  agent: MyAgentsData;
  selectedAgentSharedUsers?: UserDetails[];
  selectedAgentOwners?: UserDetails[];
  isNavigatedThroughManageShare?: boolean;
  isNavigatedThroughQuickView?: boolean;
}

interface User {
  id: string;
  userPrincipalName: string;
  displayName: string;
  mail: string;
  mailNickname: string;
  givenName: string;
}

export interface GraphAPIResponse {
  data: {
    '@odata.context': string;
    value: User[];
  };
}

function ShareAgent({
  setShowShareAgentDialog,
  agent,
  selectedAgentSharedUsers,
  selectedAgentOwners,
  isNavigatedThroughManageShare,
  isNavigatedThroughQuickView,
}: ShareAgentProps) {
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const [userEmails, setUserEmails] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const [isShareAgentLoading, setIsShareAgentLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const closeShareAgentModal = () => {
    setShowShareAgentDialog(false);
    if (isNavigatedThroughQuickView) {
      return;
    }
    if (!isNavigatedThroughManageShare) {
      setActiveNavItem(null);
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
    dispatch(setOpenCreateAgentOrTeamPage(false));
  };

  const storeEmails = (emails: string) => {
    setUserEmails(emails);
  };

  const checkIfAgentOwnedOrSharedAlreadyWithUser = async () => {
    try {
      if (!userEmails) {
        return false;
      }

      const listOfUserEmails = userEmails
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email);

      const isAgentSharedWithSameUserOrOwner = listOfUserEmails.map(async (email) => {
        const isAgentSharedWithSameUser = selectedAgentSharedUsers?.find((user) => user.mail === email);
        const isAgentSharedWithSameOwner = selectedAgentOwners?.find((owner) => owner.mail === email);

        if (isAgentSharedWithSameUser) {
          enqueueSnackbar(t('quickView.alreadySharedAgent', { userName: isAgentSharedWithSameUser.displayName }));
          return true;
        }

        if (isAgentSharedWithSameOwner) {
          enqueueSnackbar(
            t('rightPanel.agent.shareAgent.sharedWithSameOwner', { email: isAgentSharedWithSameOwner.mail })
          );
          return true;
        }

        // Check if user is an owner and sharing to the same owner while creating an agent.
        if (!isNavigatedThroughManageShare && !isNavigatedThroughQuickView) {
          const ownerDetails = (await Api.getCompleteUserDetailsByID(agent.owners)) as UserDetails[];
          if (ownerDetails?.some((owner) => owner.mail === email)) {
            enqueueSnackbar(t('rightPanel.agent.shareAgent.sharedWithSameOwner', { email }));
            return true;
          }
        }

        return false;
      });

      // Use Promise.all to wait for all the checks to resolve
      const isAgentSharedWithSameUserOrOwnerResults = await Promise.all(isAgentSharedWithSameUserOrOwner);
      return isAgentSharedWithSameUserOrOwnerResults.includes(true);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      return false;
    }
  };

  const shareAgentWithUsers = async () => {
    if (!userEmails) {
      return;
    }
    if (await checkIfAgentOwnedOrSharedAlreadyWithUser()) {
      return;
    }
    setIsShareAgentLoading(true);
    try {
      const listOfUserEmails = userEmails
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email);

      const agentSharedUserDetails: UserDetails[] = [];

      const emailValidationPromises = listOfUserEmails.map(async (email) => {
        const isEmailValid = (await Api.fetchUserByEmail(email)) as GraphAPIResponse;
        if (!isEmailValid.data.value.length) {
          throw new Error(`Invalid email: ${email}`);
        }
        agentSharedUserDetails.push({
          id: isEmailValid.data.value[0].id,
          displayName: isEmailValid.data.value[0].displayName,
          givenName: isEmailValid.data.value[0].givenName,
          mail: isEmailValid.data.value[0].mail,
        });
        return email;
      });

      // Wait for all email validation promises to resolve.
      await Promise.all(emailValidationPromises);
      await Api.shareAgent(
        agent._id,
        agentSharedUserDetails.map((user) => user.id)
      );

      if (!isNavigatedThroughQuickView) {
        dispatch(
          agentSharedUsers({
            users: agentSharedUserDetails,
          })
        );
      }

      if (isNavigatedThroughManageShare && !isNavigatedThroughQuickView) {
        dispatch(
          showUserActionContentOnChat(
            t('rightPanel.agent.activateAgentSharedWithNewUsers', {
              activeAgentName: agent.name,
            })
          )
        );
      }

      if (isNavigatedThroughQuickView) {
        enqueueSnackbar(t('quickView.shareAgent', { agentName: agent.name }));
      }

      dispatch(refreshAgentsList(true));
      dispatch(closeBasicPopoverManually(true));
      closeShareAgentModal();
      setIsShareAgentLoading(false);
      dispatch(setOpenCreateAgentOrTeamPage(false));
    } catch (error) {
      setIsShareAgentLoading(false);
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  return (
    <Dialog fullWidth sx={{ borderRadius: '4px' }} open={true} aria-labelledby="responsive-dialog-title">
      <Box padding={'40px'}>
        <DialogTitle id="responsive-dialog-title" textAlign={'center'}>
          <Typography fontSize={24} fontWeight={700}>
            {t('rightPanel.agent.shareAgent.dialogTitle')}
          </Typography>
          <Divider sx={{ padding: '10px' }} />
        </DialogTitle>

        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <DialogContentText paddingBottom={'5px'} fontWeight={700} color={'#0B0C0C'}>
            {t('rightPanel.agent.shareAgent.dialogContent')}
          </DialogContentText>
          <TextField
            id="email"
            name="email"
            type="email"
            fullWidth
            variant="standard"
            placeholder={t('rightPanel.agent.shareAgent.inputPlaceHolder')}
            onChange={(event) => storeEmails(event.target.value)}
          />

          {selectedAgentSharedUsers && selectedAgentSharedUsers.length > 0 && (
            <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500', paddingTop: '10px' }}>
              {t('rightPanel.sharedUsers')}
            </Typography>
          )}

          {selectedAgentSharedUsers &&
            selectedAgentSharedUsers.map((sharedUser: UserDetails) => (
              <Chip
                sx={{
                  margin: '5px 0px',
                  marginRight: '10px',
                  padding: '4px 10px',
                  borderRadius: '4px',
                  backgroundColor: '#3F3F3F',
                  color: '#F2F2F2',
                  position: 'relative',
                }}
                key={sharedUser.id}
                label={sharedUser.displayName}
              />
            ))}
        </DialogContent>

        <DialogActions sx={{ paddingTop: '40px' }}>
          <Button
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
            autoFocus
            size="small"
            onClick={closeShareAgentModal}
          >
            {t('rightPanel.cancel')}
          </Button>
          <LoadingButton
            sx={{ textTransform: 'none', backgroundColor: '#3C82F6' }}
            variant="contained"
            loading={isShareAgentLoading}
            onClick={shareAgentWithUsers}
            disabled={!userEmails}
          >
            {t('rightPanel.share')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ShareAgent;
