import React from 'react';
import * as assets from '../../assets';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, tooltipClasses } from '@mui/material';
import ImageButton from '../../components/sharedComponents/ImageButton';
import { useAppSelector } from '../../reduxStore/redux-hooks';

interface ClearChatProps {
  clearChat: () => void;
}

const ClearChat: React.FC<ClearChatProps> = ({ clearChat }) => {
  const clearValue = useAppSelector((state) => state.history.historyState.clearValue);
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t('chatInterface.newSessionTitle')}
      arrow
      placement="top"
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
              marginBottom: '0px',
              backgroundColor: '#000000BA',
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
              '&::before': {
                backgroundColor: '#000000BA',
              },
            },
          },
        },
      }}
    >
      <Box className="clearchat" data-title={t('chatInterface.newSessionTitle')}>
        <Box className="chat-icon">
          <Box className="chat-icon-position" sx={{ left: clearValue ? '0' : '-2.625rem' }}>
            <ImageButton
              srcFile={assets.clearChat}
              disabled={clearValue}
              handleClick={clearChat}
              ariaLabel={t('chatInterface.newSessionTitle')}
              tabIndex={clearValue ? -1 : 0}
            />
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default ClearChat;
