import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  userName: '',
  isOpenRightPanel: false,
  isCreateAgentOrTeamPageOpen: false,
  closeBasicPopover: false,
  maxRoundsForTeams: 2,
  isClearChat: false,
  isUserHasCustomAppAccess: false,
  isResponseBeingGenerated: false,
};

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    setRightPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenRightPanel = action.payload;
    },
    setOpenCreateAgentOrTeamPage: (state, action: PayloadAction<boolean>) => {
      state.isCreateAgentOrTeamPageOpen = action.payload;
    },
    closeBasicPopoverManually: (state, action: PayloadAction<boolean>) => {
      state.closeBasicPopover = action.payload;
    },
    setMaxRoundsForTeams: (state, action: PayloadAction<number>) => {
      state.maxRoundsForTeams = action.payload;
    },
    setClearChat: (state, action: PayloadAction<boolean>) => {
      state.isClearChat = action.payload;
    },
    setUserHasCustomAppAccess: (state, action: PayloadAction<boolean>) => {
      state.isUserHasCustomAppAccess = action.payload;
    },
    setIsResponseBeingGenerated: (state, action: PayloadAction<boolean>) => {
      state.isResponseBeingGenerated = action.payload;
    },
  },
});

export const {
  setUserName,
  setRightPanelOpen,
  setOpenCreateAgentOrTeamPage,
  closeBasicPopoverManually,
  setMaxRoundsForTeams,
  setClearChat,
  setUserHasCustomAppAccess,
  setIsResponseBeingGenerated,
} = commonSlice.actions;

export default commonSlice.reducer;
