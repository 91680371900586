import React from 'react';
import { Button } from '@mui/material';

interface ImageButtonProps {
  disabled?: boolean;
  srcFile: string;
  handleClick?: (event: React.MouseEvent) => void;
  style?: React.CSSProperties;
  className?: string;
  tabIndex?: number;
  ariaLabel?: string;
}

const ImageButton: React.FC<ImageButtonProps> = ({
  disabled = false,
  srcFile,
  handleClick,
  style,
  className,
  ariaLabel,
  tabIndex,
}) => {
  return (
    <Button
      className={className}
      disabled={disabled}
      data-testid="button-img"
      onClick={!disabled && handleClick ? handleClick : undefined}
      sx={{
        padding: 0,
        minWidth: 'auto',
        cursor: disabled ? 'not-allowed !important' : 'pointer !important',
        pointerEvents: 'auto !important',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
      style={style}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      aria-disabled={disabled}
    >
      <img src={srcFile} alt="button" />
    </Button>
  );
};

export default ImageButton;
