import { Box, Icon, InputLabel, Tooltip } from '@mui/material';
import NumberInput from './numberInput/NumberInput';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { topKtooltipPoints } from '../staticComponents/StaticHtmlGenerator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxStore/store';
import { setTopK } from '../../reduxStore/slices/TopKSlice';

interface TopKInputProps {
  topKValue: number;
}

const TopKConfiguration: React.FC<TopKInputProps> = ({ topKValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const top_k = useSelector((state: RootState) => state.topK.value);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <InputLabel sx={{ paddingBottom: '5px' }} className="mui-form-label">
          {t('rightPanel.agent.createAgent.topK')}
        </InputLabel>
        <Tooltip
          title={
            <Box component={'ul'} paddingLeft={'10px'}>
              {topKtooltipPoints.map((point) => {
                const translation = t(`rightPanel.agent.createAgent.topKTooltipPoints.${point}`);
                const [tooltipTitle, tooltipDescription] = translation.includes(': ')
                  ? translation.split(': ')
                  : [translation, ''];
                return (
                  <Box component="li" key={point}>
                    <strong>{tooltipTitle} : </strong>
                    {tooltipDescription}
                  </Box>
                );
              })}
            </Box>
          }
          arrow
        >
          <Icon sx={{ display: 'flex', marginLeft: '5px', cursor: 'pointer' }}>
            <InfoIcon sx={{ width: '20px' }} />
          </Icon>
        </Tooltip>
      </Box>
      <NumberInput
        min={1}
        max={100}
        value={top_k}
        defaultValue={topKValue}
        onChange={(_, value) => {
          const numericValue = Array.isArray(value) ? value[0] : value; // Ensure it's a number
          dispatch(setTopK(numericValue)); // Dispatch only a number
        }}
      />
    </>
  );
};

export default TopKConfiguration;
