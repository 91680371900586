import { Typography, Autocomplete, TextField, Box, CircularProgress, Input, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnackbarErrorMessage } from '../../../../../../staticComponents/staticUtiles';
import Api, { ApiError } from '../../../../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../../../../reduxStore/redux-hooks';
import { updateDatasetState } from '../../../../../../../reduxStore/slices/FileUploadSlice';
import { AppSkillId } from '../../../../../../staticComponents/StaticHtmlGenerator';
import { enqueueSnackbar } from 'notistack';
import { SharePointDataset } from '../../../../../../../components/rightPanel/subpanel/dataset/createDataset/existingSharePointdataset/ExistingSharePointDatset';

export interface ExistingDatasetProps {
  attachDataset: (datasetId: string) => void;
}

const AttachExistingSharePointDataset = ({ attachDataset }: ExistingDatasetProps) => {
  const dispatch = useAppDispatch();
  const [sharedPointDatasets, setSharedPointDatasets] = useState<SharePointDataset[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<SharePointDataset | null>();
  const [isFetchingSharePointDatasets, setIsFetchingSharePointDatasets] = useState<boolean>(true);
  const [siteUrl, setSiteUrl] = useState('');
  const [workflowUrl, setWorkflowUrl] = useState('');
  const [sharePointDatasetName, setSharePointDatasetName] = useState('');
  const { isAgentToBeUpdated, updateAgentDetails } = useAppSelector((state) => state.agents);

  const { t } = useTranslation();

  const fetchlistofSharedPointDatasets = async () => {
    try {
      const sharedDatasetResponse = await Api.listDatasets(true, false, AppSkillId.sharePoint);
      setSharedPointDatasets(sharedDatasetResponse.data as SharePointDataset[]);

      if (isAgentToBeUpdated) {
        prefillAttachExistingDatasetDetails(sharedDatasetResponse.data as SharePointDataset[]);
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    } finally {
      setIsFetchingSharePointDatasets(false);
    }
  };

  const prefillAttachExistingDatasetDetails = (listOfExistingDataset: SharePointDataset[]) => {
    const findAttachedDataset = listOfExistingDataset.find(
      (dataset) => dataset._id === updateAgentDetails.dataset_id
    ) as SharePointDataset;
    attachDataset(findAttachedDataset._id);
    setSelectedDataset(findAttachedDataset);
  };

  useEffect(() => {
    fetchlistofSharedPointDatasets();
  }, []);

  const clearSharePointDatasetSelection = () => {
    setSelectedDataset(null);
    setSiteUrl('');
    setWorkflowUrl('');
    setSharePointDatasetName('');
  };

  const handleSharePointDatasetSelection = (
    event: React.SyntheticEvent<Element, Event>,
    datasetSelected: SharePointDataset | null
  ) => {
    if (!datasetSelected) {
      clearSharePointDatasetSelection();
      return;
    }
    setSelectedDataset(datasetSelected);
    setSiteUrl(datasetSelected.sharepoint_attributes.site_url || '');
    setWorkflowUrl(datasetSelected.sharepoint_attributes.power_automate_workflow_url || '');
    setSharePointDatasetName(datasetSelected.name || '');
    attachDataset(datasetSelected._id);
  };

  const updateSharePointDocument = async () => {
    if (!selectedDataset) {
      return;
    }
    const sharepoint_data = {
      site_url: siteUrl,
      power_automate_workflow_url: workflowUrl,
    };

    try {
      await Api.updateSharePointDataset(
        selectedDataset._id,
        sharePointDatasetName,
        AppSkillId.sharePoint,
        sharepoint_data
      );

      const prepare = await Api.prepareDataset(selectedDataset._id, AppSkillId.DocCompletion);

      let workflowData = prepare.data;

      while (workflowData.status !== 'Completed') {
        const workflow = await Api.getWorkflowForDataset(selectedDataset._id, workflowData._id);
        if (workflow.data) {
          workflowData = workflow.data;
        }
        if (workflowData.status === 'Failed') {
          enqueueSnackbar(t('fileUpload.uploadFileMessage.failurePrepareMesssage'), {
            variant: 'error',
          });
          attachDataset('');
          return;
        }
        await new Promise((r) => setTimeout(r, 10000));
      }

      if (workflowData.status === 'Completed') {
        enqueueSnackbar('Document updated successfully', {
          variant: 'success',
        });
        dispatch(updateDatasetState({ listOfUserOwnedDataset: [] }));
        attachDataset(selectedDataset._id);

        // Call fetchlistofSharedPointDatasets after completion
        await fetchlistofSharedPointDatasets();
      }
    } catch (error) {
      console.error('Error in sharePointUpdate:', error);
      enqueueSnackbar(t('fileUpload.uploadFileMessage.failurePrepareMesssage'), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: '20px', maxWidth: '615px' }}>
        {isFetchingSharePointDatasets ? (
          <CircularProgress />
        ) : (
          <Box>
            <Typography sx={{ fontWeight: 700 }}>{t('fileUpload.useSharePointExistingDataset')}</Typography>
            <Autocomplete
              options={sharedPointDatasets}
              getOptionLabel={(option) => option.name}
              value={selectedDataset}
              onChange={(event, selectedDataset) => handleSharePointDatasetSelection(event, selectedDataset)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={selectedDataset ? selectedDataset.name : t('fileUpload.selectPlaceholder')}
                  variant="standard"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '10rem',
                  overflow: 'auto',
                },
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  key={option._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Typography>{option.name}</Typography>
                  </Box>
                </Box>
              )}
            />
            <Typography fontSize={16} fontWeight={700} sx={{ marginTop: '1rem' }}>
              {t('fileUpload.siteUrl')}
            </Typography>
            <Input
              disabled
              fullWidth
              id="site-url"
              sx={{ marginBottom: '1rem' }}
              value={selectedDataset ? selectedDataset.sharepoint_attributes.site_url : ''}
            />
            <Typography fontSize={16} fontWeight={700}>
              {t('fileUpload.workflowURl')}
            </Typography>
            <Input
              disabled
              fullWidth
              id="site-url"
              value={selectedDataset ? selectedDataset.sharepoint_attributes.power_automate_workflow_url : ''}
            />
            {selectedDataset && selectedDataset.files.length !== 0 && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', margin: '1rem 0' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>

                  <Typography>{selectedDataset.files.length}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '6.5rem',
                    overflowY: 'scroll',
                    gap: '.25rem',
                  }}
                >
                  {selectedDataset.files.map((file, key) => (
                    <Typography
                      key={key}
                      sx={{
                        padding: '.25rem .25rem',
                        background: '#F6F6F6B2',
                        borderRadius: '.25rem',
                      }}
                    >
                      {file.name}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        <Button
          sx={{
            backgroundColor: '#3C82F6',
          }}
          variant="contained"
          disabled={
            !selectedDataset?.sharepoint_attributes.site_url ||
            !selectedDataset?.sharepoint_attributes.power_automate_workflow_url
          }
          onClick={updateSharePointDocument}
        >
          {t('fileUpload.updatedataset')}
        </Button>
      </Box>
    </>
  );
};

export default AttachExistingSharePointDataset;
