import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChoosenSelectedCustomApp {
  isCustomAppSelected: boolean;
  selectedCustomApp: string;
  selectedCustomAppSkill: string;
  welcomeMessage: string;
}
export interface SowCommitAiQueryResponse {
  row: {
    GenAI: string;
    title: string;
  };
}
export interface SowPredictorQueryResponse {
  [key: string]: {};
}

export interface AdvanceSummaryResponse {
  adv_summary: string;
  doc_summary: string;
}

const initialState = {
  choosenCustomApp: {
    isCustomAppSelected: false,
    selectedCustomApp: '',
    selectedCustomAppSkill: '',
    welcomeMessage: '',
  },
  responseTable: {
    sowQueryResponse: '',
    selectedCustomAppSkills: '',
  },
  sowCommitAiQueryResponse: [] as SowCommitAiQueryResponse[],
  sowPredictorQueryResponse: {},
  advanceSummaryResponse: {
    adv_summary: '',
    doc_summary: '',
  },
  shouldClearChat: false,
  shouldDisableCustomApps: false,
  isCustomAppHistorySelected: false,
};

export const customAppsSlice = createSlice({
  name: 'customApps',
  initialState,
  reducers: {
    saveSelectedCustomApp: (state, action: PayloadAction<ChoosenSelectedCustomApp>) => {
      state.choosenCustomApp = action.payload;
    },
    saveSowCommitAiQueryResponse: (state, action: PayloadAction<SowCommitAiQueryResponse[]>) => {
      state.sowCommitAiQueryResponse = action.payload;
    },
    saveSowPredictorQueryResponse: (state, action: PayloadAction<SowPredictorQueryResponse>) => {
      state.sowPredictorQueryResponse = action.payload;
    },
    saveAdvanceSummaryResponse: (state, action: PayloadAction<AdvanceSummaryResponse>) => {
      state.advanceSummaryResponse = action.payload;
    },
    clearChat: (state, action: PayloadAction<boolean>) => {
      state.shouldClearChat = action.payload;
    },
    setDisableCustomApps: (state, action: PayloadAction<boolean>) => {
      state.shouldDisableCustomApps = action.payload;
    },
    customAppHistorySelected: (state, action: PayloadAction<boolean>) => {
      state.isCustomAppHistorySelected = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  saveSelectedCustomApp,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
  saveAdvanceSummaryResponse,
  clearChat,
  setDisableCustomApps,
  customAppHistorySelected,
} = customAppsSlice.actions;

export default customAppsSlice.reducer;
