import React, { useState, useEffect, useRef } from 'react';
import Login from './pages/Login';
import { Chat } from './pages/chat/Chat';
import Disclaimer from './pages/Disclaimer';
import './i18n';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Auth, getAuthInstance } from './helpers/Auth';
import ProtectedRoute from './components/ProtectedRoute';

// MSAL imports
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { ActiveNavItemContext } from './components/rightPanel/RightPanelContext';
import PageNotFound from './components/sharedComponents/pageNotFound/PageNotFound';
import { useTranslation } from 'react-i18next';
import { getTenantDetailsAndInitAuthInstance, getTenantName, storeQueryParameter } from './MsalConfig';
import {
  assistantsQueryParams,
  chatPageNavigation,
  disclaimerPageNavigation,
  loginPageNavigation,
  welcomePageNavigation,
} from './components/staticComponents/StaticHtmlGenerator';
import { InitializeGA } from './components/sharedComponents/googleAnalytics/GoogleAnalytics';
import { SubPanelAssistants } from './components/rightPanel/subpanel/SubPanelEnums';

function App() {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
  const navigate = useNavigate();
  const [activeNavItem, setActiveNavItem] = useState<null | string>(null);
  const [initAuthInstance, setInitAuthInstance] = useState<Auth | null>(null);
  const [isPageNotFound, setIsPageNotFound] = useState<boolean>(false);
  const location = useLocation();
  const urlPathParamRef = useRef('');
  urlPathParamRef.current = location.pathname.replace('/', '').toLowerCase();
  const { t } = useTranslation();

  // Exclude certain url path parameters from being saved as the tenant name.
  const pathParmsToExculdeForTenantName = [
    t('urlPathParameters.emptyString'),
    t('urlPathParameters.chat'),
    t('urlPathParameters.disclaimer'),
    t('urlPathParameters.welcomepage'),
  ];

  useEffect(() => {
    InitializeGA();
    //this function checks if the user uses platform with multiple tenants.
    checkIfUserLogInWithDifferentTenant();

    const initializeAuthInstance = async () => {
      //fetch tenant details by using url path params reference and then initialize auth Instance.
      const authInstance = await getTenantDetailsAndInitAuthInstance(
        urlPathParamRef.current,
        pathParmsToExculdeForTenantName,
        t
      );
      if (authInstance) {
        setInitAuthInstance(authInstance);
        storeQueryParameter(location.search);
      } else {
        // If an issuer do not found, display page not found page.
        setIsPageNotFound(true);
      }
    };
    initializeAuthInstance();
  }, []);

  //if the user is trying to login with different tenant first
  //logout from the first tenant details
  const checkIfUserLogInWithDifferentTenant = () => {
    const tenantName = getTenantName();
    if (
      tenantName !== urlPathParamRef.current &&
      urlPathParamRef.current !== '' &&
      !pathParmsToExculdeForTenantName.includes(urlPathParamRef.current.toLowerCase())
    ) {
      getAuthInstance().then(async (Auth) => await Auth.logout());
    }
  };

  useEffect(() => {
    if (initAuthInstance) {
      getAuthInstance().then(async (authInstance) => {
        setMsalInstance(authInstance.getMsalInstance());
        const navigateDisclaimer = await authInstance.shouldShowDisclaimer();
        const queryParamExists = (assistantsQueryParams as Array<keyof typeof SubPanelAssistants>).some((key) =>
          JSON.parse(sessionStorage.getItem(SubPanelAssistants[key]) as string)
        );

        const callbackId = authInstance.getMsalInstance().addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_SUCCESS) {
            if (navigateDisclaimer) {
              navigate(disclaimerPageNavigation);
              authInstance.acknowledgeDisclaimer();
            } else if (queryParamExists) {
              navigate(chatPageNavigation);
            } else {
              navigate(welcomePageNavigation);
            }
          } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
            navigate(loginPageNavigation);
          }
        });

        return () => {
          authInstance.getMsalInstance().removeEventCallback(callbackId ?? '');
        };
      });
    }
  }, [initAuthInstance, navigate]);

  return (
    <div className="App">
      {isPageNotFound ? (
        <PageNotFound />
      ) : (
        msalInstance && (
          <MsalProvider instance={msalInstance}>
            <Routes>
              <Route
                path={chatPageNavigation}
                element={
                  <ProtectedRoute>
                    <ActiveNavItemContext.Provider value={{ activeNavItem, setActiveNavItem }}>
                      <Chat />
                    </ActiveNavItemContext.Provider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={welcomePageNavigation}
                element={
                  <ProtectedRoute>
                    <ActiveNavItemContext.Provider value={{ activeNavItem, setActiveNavItem }}>
                      <Chat />
                    </ActiveNavItemContext.Provider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={disclaimerPageNavigation}
                element={
                  <ProtectedRoute>
                    <Disclaimer />
                  </ProtectedRoute>
                }
              />
              <Route path={`/${urlPathParamRef.current}`} element={<Login />} />
            </Routes>
          </MsalProvider>
        )
      )}
    </div>
  );
}

export default App;
