import { Avatar, Typography } from '@mui/material';
import wiproLogo from '../../assets/ai_logo.svg';
import statestreetLogo from '../../assets/statestreet/statestreet.png';
import UsePageNavigation from '../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { TenantNames } from '../../components/staticComponents/StaticHtmlGenerator';

interface LogoProps {
  externalId: string;
  hideExternalIdText: boolean;
}

function Logo({ externalId, hideExternalIdText }: LogoProps) {
  const { isChatPage, isWelcomePage } = UsePageNavigation();
  const logo = externalId === TenantNames.StateStreet ? statestreetLogo : wiproLogo;
  const excludedTenants = [TenantNames.Wipro, TenantNames.StateStreet] as string[];
  return (
    <>
      <Avatar
        src={logo}
        alt="Lab45 AI Platform"
        data-testid="logo"
        sx={{
          width:
            externalId === TenantNames.StateStreet
              ? '170px'
              : isChatPage || isWelcomePage
                ? '170px'
                : { xs: '170px', md: '370px', lg: '370px' },
          height: 'auto',
          borderRadius: '0',
          margin: '0 auto',
        }} // Adjust size as needed
      />
      {hideExternalIdText && externalId === TenantNames.StateStreet && (
        <Typography sx={{ color: '#3c82f6', fontSize: '1.2rem', fontWeight: '700', marginTop: '20px' }}>
          Powered by Wipro Lab45 AI Platform
        </Typography>
      )}
      {!excludedTenants.includes(externalId) && <Typography>{externalId}</Typography>}
    </>
  );
}

export default Logo;
