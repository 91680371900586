import { Autocomplete, Box, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import * as assets from '../../../../../../assets';
import { useAppDispatch, useAppSelector } from '../../../../../../reduxStore/redux-hooks';
import {
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
} from '../../../../../../reduxStore/slices/FileUploadSlice';
import { ListOfExistingDataset } from '../DatasetModal';

interface File {
  name: string;
  ts: number;
}

interface ExistingDatasetProps {
  isExistingDatasetListFetching: boolean;
  setIsExistingDatasetHasData: (hasData: boolean) => void;
}

const ExistingDataset: React.FC<ExistingDatasetProps> = ({
  isExistingDatasetListFetching,
  setIsExistingDatasetHasData,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fileUpload, fileSelection, dataset } = useAppSelector((state) => state.fileUpload);
  const { editDataset } = useAppSelector((state) => state.dataset);
  const listOfUserOwnedDataset = dataset.listOfUserOwnedDataset;
  const [selectedDataset, setSelectedDataset] = useState<ListOfExistingDataset | null>(null);

  const handleSelect = (event: React.SyntheticEvent<Element, Event>, datasetSelected: ListOfExistingDataset | null) => {
    const datasetId = datasetSelected?._id;
    dispatch(
      updateDatasetState({
        selectedDatasetId: datasetId,
      })
    );
    dispatch(
      updateFileSelectionState({
        isDatasetButtonDisabled: false,
      })
    );

    if (datasetSelected) {
      displaySelectedDatasetContent(datasetSelected);
    } else {
      clearDatasetContent();
    }
    setSelectedDataset(datasetSelected);
    setIsExistingDatasetHasData(true);
  };

  const displaySelectedDatasetContent = (selectedDataset: ListOfExistingDataset) => {
    dispatch(
      updateDatasetState({
        datasetName: selectedDataset.name,
      })
    );
    dispatch(
      updateFileUploadState({
        listOfFilesInSelectedDataset: selectedDataset.files,
      })
    );
    const existingFileNames = selectedDataset.files.map((file: File) => file.name);
    const filesExist = fileUpload['uploadedFiles']
      .filter((file) => existingFileNames.includes(file.name))
      .map((file) => file.name);
    dispatch(
      updateFileUploadState({
        listOfFilteredUploadedFilesExistInDataset: filesExist,
      })
    );
  };

  const clearDatasetContent = () => {
    dispatch(
      updateFileUploadState({
        listOfFilesInSelectedDataset: [],
        listOfFilteredUploadedFilesExistInDataset: [],
      })
    );
    setSelectedDataset(null);
  };

  const handleCheckBoxChange = (checked: boolean) => {
    dispatch(
      updateFileSelectionState({
        isReplaceFileCheckboxChecked: checked,
        isDatasetButtonDisabled: fileUpload.listOfFilteredUploadedFilesExistInDataset.length ? !checked : checked,
      })
    );
  };

  const enableVisionSummary = (checked: boolean) => {
    dispatch(
      updateFileSelectionState({
        isVisionSummaryEnabled: checked,
      })
    );
  };
  const loadDatasetList = async () => {
    if (!editDataset.addFiles) return;
    handleCheckBoxChange(false);
    const selectedDataset = listOfUserOwnedDataset.find((dataset) => dataset._id === editDataset.datasetId);
    if (!selectedDataset) {
      // Clear the state if no dataset is selected
      clearDatasetContent();
      return;
    }
    displaySelectedDatasetContent(selectedDataset);
    setSelectedDataset(selectedDataset);
  };

  useEffect(() => {
    loadDatasetList();
  }, [listOfUserOwnedDataset]);

  useEffect(() => {
    return () => {
      // Clear the state when the component is unmounted
      clearDatasetContent();
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: '20px', maxWidth: '615px' }}>
      {isExistingDatasetListFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Typography sx={{ fontWeight: 700 }}>{t('fileUpload.existingDatasetLabel')}</Typography>
          <Autocomplete
            options={dataset['listOfUserOwnedDataset']}
            getOptionLabel={(option) => option.name}
            value={selectedDataset}
            onChange={handleSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={selectedDataset ? selectedDataset.name : t('fileUpload.selectPlaceholder')}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectedDataset && (
                    <img
                      src={assets.datasets_icon}
                      alt="Dataset Icon"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                      }}
                    />
                  ),
                }}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: '10rem',
                overflow: 'auto',
              },
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                key={option._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0.5rem',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <img
                    src={assets.datasets_icon}
                    style={{
                      width: '1.5rem',
                      height: '1.5rem',
                    }}
                    alt="Dataset Icon"
                  />
                  <Typography>{option.name}</Typography>
                </Box>
                <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#256AFF' }}>
                  {`${option.files.length} Files`}
                </Typography>
              </Box>
            )}
          />
          {/* If a dataset is selected and it has files, show the dataset content */}
          {!isExistingDatasetListFetching && fileUpload.listOfFilesInSelectedDataset.length !== 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', marginTop: '1rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>
                <Typography>{selectedDataset && selectedDataset.files.length}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '6.5rem',
                  overflowY: 'scroll',
                  gap: '.25rem',
                }}
              >
                {fileUpload.listOfFilesInSelectedDataset.map((file, key) => (
                  <Typography
                    key={key}
                    sx={{
                      padding: '.25rem .25rem',
                      background: '#F6F6F6B2',
                      borderRadius: '.25rem',
                    }}
                  >
                    {file.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
          {!isExistingDatasetListFetching && fileUpload.listOfFilteredUploadedFilesExistInDataset.length !== 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ userSelect: 'none' }}
                  checked={fileSelection.isReplaceFileCheckboxChecked}
                  onChange={(event) => handleCheckBoxChange(event.target.checked)}
                />
              }
              label={`${fileUpload.listOfFilteredUploadedFilesExistInDataset} ${t('fileUpload.checkBoxQuestion')}`}
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={fileSelection.isVisionSummaryEnabled}
                onChange={(event) => enableVisionSummary(event.target.checked)}
              />
            }
            label={t('fileUpload.visionSummary')}
          />
        </>
      )}
    </Box>
  );
};

export default ExistingDataset;
