import { Box, InputLabel, Tooltip, Icon, Slider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reduxStore/store';
import { setTemperature } from '../../reduxStore/slices/TemperatureSlice';

interface ConversationStyleSliderProps {
  control: Control<any>; // Ensure `control` has a valid type
  isAgentView: boolean;
}

const ConversationStyleSlider: React.FC<ConversationStyleSliderProps> = ({ control, isAgentView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const temperature = useSelector((state: RootState) => state.temperature.value);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <InputLabel className="mui-form-label">{t('rightPanel.agent.createAgent.conversationStyle')}</InputLabel>
        <Tooltip
          title={
            <Box component={'ul'} paddingLeft={'10px'}>
              <Box component="li">{t('rightPanel.agent.createAgent.temperatureTooltipPoints.firstPoint')}</Box>
              <Box component="li">{t('rightPanel.agent.createAgent.temperatureTooltipPoints.secondPoint')}</Box>
            </Box>
          }
          arrow
        >
          <Icon sx={{ display: 'flex', marginLeft: '5px', cursor: 'pointer' }}>
            <InfoIcon sx={{ width: '20px' }} />
          </Icon>
        </Tooltip>
      </Box>

      <Box sx={{ position: 'relative', marginTop: '15px' }}>
        <Box sx={{ position: 'absolute', top: '-10px', width: '100%' }}></Box>
        <Box>
          <Controller
            control={control}
            name="temperature"
            rules={{ required: false }}
            render={({ field }) => (
              <Slider
                {...field}
                value={temperature}
                onChange={(_, value) => {
                  const numericValue = Array.isArray(value) ? value[0] : value; // Ensure it's a number
                  field.onChange(numericValue);
                  dispatch(setTemperature(numericValue)); // Dispatch only a number
                }}
                name={'temperature'}
                step={0.1}
                min={0}
                max={1}
                valueLabelDisplay="on"
                disabled={isAgentView}
              ></Slider>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default ConversationStyleSlider;
