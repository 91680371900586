import { useEffect, useState, useContext, useRef } from 'react';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import Header from './Header';
import ResponseBox from './ResponseBox';
import UserContent from './UserContent';
import ResponseContent from './ResponseContent';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import '../../css/ChatInterface.css';
import QueryBox from './QueryBox';
import User from '../../helpers/User';
import Api, { AgentChatMessages, ApiError, ChatMessages, SkillParameters } from '../../data/api/Api';
import { Skill } from '../../data/models/Skill';
import { LlmModel } from '../../data/models/LlmModel';
import { RightNavPanel } from '../../components/rightPanel/rightNavPanel/RightNavPanel';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { setSuccessResponseModal, setImageUploadData } from '../../reduxStore/slices/FileUploadSlice';
import { setInitialHistoryState } from '../../reduxStore/slices/historySlice';
import CarouselSlider from './quickView/CarasouelSlider';
import {
  PartyType,
  askButtonText,
  customAppsSkillIds,
  customAppsJsonNames,
  AppSkillId,
  maxTitleLength,
} from '../../components/staticComponents/StaticHtmlGenerator';
import customAppsData from '../../components/rightPanel/subpanel/customApps/CustomApps.json';
import { showUserActionContentOnChat } from '../../reduxStore/slices/NotifyUserActionContentSlice';
import FeedbackForm from '../chat/Feedback';
import UsePageNavigation from '../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { getAuthInstance } from '../../helpers/Auth';
import SowCommitAI from '../../components/rightPanel/subpanel/customApps/SowCommitAI';
import SowResponseTable from '../../components/rightPanel/subpanel/customApps/SowResponseTable';
import { saveSowPredictorQueryResponse } from '../../reduxStore/slices/CustomAppsSlice';
import QETPlatform from '../../components/rightPanel/subpanel/customApps/QETPlatfrom';
import { CustomAppName } from '../../components/staticComponents/StaticHtmlGenerator';
import { LogButtonEventToGA } from '../../components/sharedComponents/googleAnalytics/GoogleAnalytics';
import * as assets from '../../assets';
import QuickView from './quickView/QuickView';
import { setClearChat, setIsResponseBeingGenerated, setUserName } from '../../reduxStore/slices/CommonSlice';
import Footer from './Footer';
import UseQueryParamNavigation from '../../components/sharedComponents/customHooks/useQueryParamNavigation/UseQueryParamNavigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxStore/store';
import { showSnackbarErrorMessage } from '../../components/staticComponents/staticUtiles';
import { DocumentSource } from './DocumentSources';
import { getTenantName } from '../../MsalConfig';
import { TenantNames } from '../../components/staticComponents/StaticHtmlGenerator';
import UseBrowserBackNavigation from '../../components/sharedComponents/customHooks/useBrowserBackNavigation/UseBrowserBackNavigation';

export function Chat() {
  const dispatch = useAppDispatch();
  const conversationIdRef = useRef('');
  const conversationMessageCountRef = useRef(0);
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const subPanelOpen =
    activeNavItem !== null &&
    activeNavItem !== t('welcomepage.Home') &&
    activeNavItem !== t('welcomepage.Chat') &&
    activeNavItem !== t('welcomepage.releaseNote');
  const [user, setUser] = useState(User.empty);
  const [chatContents, setChatContents] = useState<JSX.Element[]>([]);
  const [messages, setMessages] = useState<ChatMessages[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appMessage, setAppMessage] = useState('');
  const [isStreamComplete, setisStreamComplete] = useState(false);
  const [documentSources, setDocumentSources] = useState<{ source: string; content: string }[]>([]);
  let updatedMessagesList: ChatMessages[] = [];
  const { enqueueSnackbar } = useSnackbar();
  const { isOpenRightPanel, isClearChat } = useAppSelector((state) => state.commonSlice);
  const { userActionContent } = useAppSelector((state) => state.notifyUserActionContent);
  const { activeAgent } = useAppSelector((state) => state.agents);
  const { activeTeam } = useAppSelector((state) => state.teams);
  const { activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { fileUpload, imageUploadedData } = useAppSelector((state) => state.fileUpload);
  const { historyResponse, conversationID, conversationMessageCounter, selectedModelHistory, agentIdentifier } =
    useAppSelector((state) => state.history.historyState);
  const { customAppDatasetId } = useAppSelector((state) => state.featured);
  const { maxRoundsForTeams } = useAppSelector((state) => state.commonSlice);
  const [selectedGuide, setSelectedGuide] = useState('');
  const { isWelcomePage, isChatPage } = UsePageNavigation();
  const { choosenCustomApp, sowCommitAiQueryResponse, sowPredictorQueryResponse, advanceSummaryResponse } =
    useAppSelector((state) => state.customApps);
  const onGuideClick = (customText: string) => {
    setSelectedGuide(customText);
  };

  const [selectedModel, setSelectedModel] = useState(LlmModel.defaultModal);
  const [llmModels, setLlmModels] = useState<LlmModel[]>([LlmModel.defaultModal]);
  const isQueryResponseStatusPendingForAgentOrTeams = useRef<boolean>(false);
  const nameOfRepliedAgent = useRef<string>('');
  const isAgentOrTeamActive = !!(activeAgent._id || activeTeam._id);
  const { isNavigatedThroughQueryParam, navigateToMainPage } = UseQueryParamNavigation();
  const [isSaveCustomAppHistory, setIsSaveCustomAppHistory] = useState(true);

  const temperature = useSelector((state: RootState) => state.temperature.value);
  const top_k = useSelector((state: RootState) => state.topK.value);
  const tenantName = getTenantName();
  UseBrowserBackNavigation();

  useEffect(() => {
    const modelFound = llmModels.find((model) => model.id === selectedModelHistory);
    setSelectedModel(modelFound ? modelFound : LlmModel.defaultModal);
  }, [selectedModelHistory]);

  const parseHistoryResponseMessageContent = (content: string) => {
    try {
      const parsedMessageContent = JSON.parse(content);
      if (Array.isArray(parsedMessageContent)) {
        const userMessageContent = parsedMessageContent.find((messageContent) => messageContent.type === 'text');

        // Extracting the image urls from the history response and updating the imageUploadedData state.
        const historyImageBase64Urls = parsedMessageContent
          .filter((messageContent) => messageContent.type === 'image_url')
          .map((imageContent) => imageContent.image_url.url);

        const updatedBase64UrlContent = historyImageBase64Urls.map((url) => ({
          type: 'image_url',
          image_url: {
            url: url,
          },
        }));
        return { text: userMessageContent ? userMessageContent.text : '', urlContent: updatedBase64UrlContent };
      }
      return { text: content, urlContent: [] };
    } catch {
      return { text: content, urlContent: [] };
    }
  };

  const loadChatHistory = async () => {
    if (!historyResponse) {
      return;
    }
    if (conversationIdRef.current && conversationIdRef.current === conversationID) {
      return; //Doing a early return if user clicked on same history
    }

    historyResponse.map((message) => {
      if (message.name.includes(`${user.initials()}`)) {
        const { text: parsedMessageContent, urlContent: historyBase64Url } = parseHistoryResponseMessageContent(
          message.content
        );
        const userContent = (
          <UserContent
            initials={user.initials()}
            message={parsedMessageContent}
            imageSelectionChange={() => 'imagePath'}
            toolTipTextChange={(value) => `Tooltip for ${value}`}
          />
        );
        handleAddChatContent(userContent);
        updateMessages([
          {
            content:
              historyBase64Url.length > 0
                ? [{ type: 'text', text: parsedMessageContent }, ...historyBase64Url]
                : parsedMessageContent,
            role: 'user',
            ...((agentIdentifier === PartyType.Agent || agentIdentifier === PartyType.Team) && {
              name: user.initials(),
            }),
          },
        ]);
      } else {
        const initialResponseContent = (
          <ResponseContent
            modelIcon={getModelIcon(selectedModel)}
            modelTooltip={selectedModel.tooltip}
            appMessage={message.content + (message.media && `${' '}` + message.media)}
            isLoading={false}
            isStreamComplete={true}
            nameOfRepliedAgent={isAgentOrTeamActive ? message.name : ''}
            highlightResponse={true}
          />
        );
        handleAddChatContent(initialResponseContent);
        updateMessages([
          {
            content: message.content,
            role: 'assistant',
            ...((agentIdentifier === PartyType.Agent || agentIdentifier === PartyType.Team) && {
              name: user.initials(),
            }),
          },
        ]);
      }
    });
    conversationIdRef.current = conversationID;
    conversationMessageCountRef.current = conversationMessageCounter;
  };

  useEffect(() => {
    loadChatHistory();
  }, [historyResponse]);

  useEffect(() => {
    if (userActionContent.length) {
      const initialResponseContent = (
        <ResponseContent
          appMessage={userActionContent}
          modelIcon={getModelIcon(selectedModel)}
          modelTooltip={selectedModel.tooltip}
          isLoading={false}
          isStreamComplete={false}
        />
      );
      handleAddChatContent(initialResponseContent);
      dispatch(showUserActionContentOnChat(''));
    }
    //clear the previous messages while switching between different custom apps and set system propmt message.
    if (customAppDatasetId) {
      const selectedCustomApp = customAppsData.custom_apps.find(
        (customApp) => customApp.dataset_id === customAppDatasetId
      );
      if (selectedCustomApp) {
        setMessages([{ content: selectedCustomApp.system_prompt, role: 'system' }]);
      }
    }
  }, [userActionContent, customAppDatasetId]);

  //clearing the chat on change of the custom app
  useEffect(() => {
    //Clearing and resetting messages empty on changing to Nav items
    if (isClearChat) {
      clearChatHistory();
    }

    if (isWelcomePage) {
      setSelectedModel(LlmModel.defaultModal);
      dispatch(
        setInitialHistoryState({
          selectedModelHistory: LlmModel.defaultModal.name,
        })
      );
    }
    //Setting Prompt Messages on select of Custom Apps Json
    handleCustomAppSystemPrompt();
    dispatch(setClearChat(false));
  }, [isClearChat, choosenCustomApp.selectedCustomApp]);

  useEffect(() => {
    handleWelcomeMessageCustomApps();
  }, [
    choosenCustomApp,
    saveSowPredictorQueryResponse,
    sowCommitAiQueryResponse,
    sowPredictorQueryResponse,
    advanceSummaryResponse,
  ]);

  const passCustomAppQueryResponse = (selectedCustomAppSkill: string) => {
    return selectedCustomAppSkill === AppSkillId.AdvancedSummary
      ? advanceSummaryResponse
      : customAppsSkillIds.includes(selectedCustomAppSkill)
        ? sowCommitAiQueryResponse
        : sowPredictorQueryResponse;
  };

  const handleWelcomeMessageCustomApps = () => {
    if (
      choosenCustomApp.isCustomAppSelected &&
      !sowCommitAiQueryResponse.length &&
      !Object.values(sowPredictorQueryResponse).length &&
      (advanceSummaryResponse.adv_summary === '' || advanceSummaryResponse.doc_summary === '')
    ) {
      const initialResponseContent = (
        <ResponseContent
          appMessage={choosenCustomApp.welcomeMessage}
          isLoading={false}
          modelIcon={getModelIcon(selectedModel)}
          modelTooltip={selectedModel.tooltip}
          isStreamComplete={false}
          highlightResponse={false}
        />
      );
      handleAddChatContent(
        choosenCustomApp.selectedCustomApp === CustomAppName.QETPlatform ? <QETPlatform /> : initialResponseContent
      );
      //For SowCommit there are Guildlines to show along with welcome message, adding content again
      if (choosenCustomApp.selectedCustomApp === CustomAppName.SOWCommitAI) {
        handleAddChatContent(<SowCommitAI />);
      }
    }
    if (
      choosenCustomApp.isCustomAppSelected &&
      (sowCommitAiQueryResponse.length ||
        (advanceSummaryResponse.adv_summary !== '' && advanceSummaryResponse.doc_summary !== '') ||
        Object.values(sowPredictorQueryResponse).length)
    ) {
      const sowResponseContent = (
        <SowResponseTable
          sowQueryResponse={passCustomAppQueryResponse(choosenCustomApp.selectedCustomAppSkill)}
          selectedCustomAppSkills={choosenCustomApp.selectedCustomAppSkill}
        />
      );
      handleUpdateLastChatContentOfCustomApp(sowResponseContent);
    }
  };

  useEffect(() => {
    fetchRequiredDetailsOnComponentInitialization();
  }, []);

  const fetchRequiredDetailsOnComponentInitialization = async () => {
    try {
      await (await getAuthInstance()).login();

      //get the current user and update the state
      const user = await User.getInstance();
      setUser(user);
      dispatch(setUserName(user.fullName));

      //Fetch and update LLM models
      const llmModels = await Api.listLlmModels();
      setLlmModels(llmModels);

      //handle navigation if needed
      if (!isNavigatedThroughQueryParam()) {
        navigateToMainPage();
      }
    } catch (error) {
      enqueueSnackbar((error as Error).toString());
    }
  };

  const updateMessages = (newMessages: ChatMessages[]) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        content: newMessages[0].content,
        role: newMessages[0].role,
        ...(newMessages[0].name && { name: user.initials() }),
      },
    ]);
    updatedMessagesList = [...messages, ...newMessages]
  };
  const clearChatHistory = () => {
    setMessages([]);
    setChatContents([]);
    setDocumentSources([]);
    conversationIdRef.current = '';
    conversationMessageCountRef.current = 0;
    dispatch(setSuccessResponseModal(false));
    dispatch(
      setInitialHistoryState({
        conversationID: '',
      })
    );
    dispatch(
      setInitialHistoryState({
        clearValue: true,
        activeHistory: '',
      })
    );
    dispatch(
      setImageUploadData({
        imageURLList: [],
        imagesNames: [],
      })
    );
    //Adding Custom App System Prompt to Messages when Custom App is still active
    if (!isClearChat) {
      handleCustomAppSystemPrompt();
    }
  };

  const handleCustomAppSystemPrompt = () => {
    if (choosenCustomApp.selectedCustomApp) {
      const customAppsDetails = customAppsData.apps_custom;
      const customAppJsonName = customAppsDetails.find(
        (customApp) => customApp.sticky_header === choosenCustomApp.selectedCustomApp
      );

      if (customAppJsonName) {
        setMessages([{ content: customAppJsonName.system_prompt, role: 'system' }]);
        setIsSaveCustomAppHistory(customAppJsonName.keep_history);
      }
    }
  };

  const handleAddChatContent = (content: JSX.Element) => {
    setChatContents((prevContents) => [...prevContents, content]);
  };

  const handleUpdateLastChatContent = (content: JSX.Element, lastResponseMessage: string) => {
    setChatContents((prevContents) => {
      const newContents = [...prevContents];

      //while chating with Teams the last round response will always be empty that means
      //all the agents have replied. In such cases just remove the loading indicator showing in the chat.
      if (lastResponseMessage === '') {
        newContents.pop();
      } else {
        newContents[newContents.length - 1] = content;
      }
      return newContents;
    });
  };

  const handleUpdateLastChatContentOfCustomApp = (content: JSX.Element) => {
    setChatContents((prevContents) => {
      const newContents = [...prevContents];
      const lastContent = newContents[newContents.length - 1];
      const lastContentText = lastContent.props.appMessage;
      //When a new response generates then the old response to be replaced with new response in custom apps && // When SOW Commit is selected, validating to show the guidelines with the response
      if (
        (lastContentText !== choosenCustomApp.welcomeMessage &&
          choosenCustomApp.selectedCustomAppSkill !== AppSkillId.SOWCommitAI) ||
        (choosenCustomApp.selectedCustomAppSkill === AppSkillId.SOWCommitAI && newContents.length > 2)
      ) {
        newContents[newContents.length - 1] = content;
      } else {
        newContents[newContents.length] = content;
      }
      return newContents;
    });
  };

  const getModelIcon = (modelSelection: LlmModel) => {
    return isAgentOrTeamActive ? assets.agentModelIcon : modelSelection.image;
  };

  // Function to truncate a string to a specified number of words.
  const truncateString = (inputString: string, characterCount: number): string => {
    // a function to truncate the input string and return only 20 characters out of it in case the title is a lengthy one.
    if (inputString.length >= (characterCount ? characterCount : maxTitleLength)) {
      inputString = inputString.slice(0, characterCount) + '...';
    }
    return inputString;
  };

  //this hanldes whether to use default query api or ai assets api.
  const handlePostQueryApi = async (model: LlmModel) => {
    const skill_parameters: SkillParameters = activeAgent._id
      ? {}
      : {
          temperature: temperature,
          top_k: top_k,
        };

    const partyType = activeAgent._id ? PartyType.Agent : activeTeam._id ? PartyType.Team : null;
    const partyId = activeAgent._id || activeTeam._id;
    let isCustomApp: boolean = false;

    // When the user uses Custom Apps from Json, passing the corresponding dataset model id to the skill parameters
    if (choosenCustomApp.selectedCustomApp && customAppsJsonNames.includes(choosenCustomApp.selectedCustomApp)) {
      const customAppsDetails = customAppsData.apps_custom;
      const customAppJsonName = customAppsDetails.find(
        (customApp) => customApp.sticky_header === choosenCustomApp.selectedCustomApp
      );
      const modelFound = llmModels.find((model) => model.id === customAppJsonName?.model_name) || LlmModel.defaultModal;
      skill_parameters.model_name = modelFound.id as SkillParameters['model_name'];
      if (customAppJsonName?.top_k) {
        skill_parameters.top_k = customAppJsonName?.top_k;
      }

      skill_parameters.return_sources = customAppJsonName?.return_source;
      isCustomApp = choosenCustomApp.selectedCustomApp != '';
    }

    return partyType
      ? Api.postAgentOrTeamQuery(
        updatedMessagesList as AgentChatMessages[],
          model,
          skill_parameters,
          partyId,
          partyType,
          conversationIdRef.current,
          maxRoundsForTeams
        )
      : Api.postQuery(
          activeDataSetId,
          Skill.completion,
          updatedMessagesList,
          model,
          skill_parameters,
          customAppDatasetId,
          isCustomApp
        );
  };

  let newContent = '';
  const checkRegex = new RegExp('<details[^>]*>((.|s)*?)</details>');

  //view source
  const extractSources = (sourceDocuments: DocumentSource[]) => {
    return sourceDocuments.flatMap((doc: DocumentSource) =>
      Object.keys(doc)
        .filter((key: string) => key.startsWith('chunk_'))
        .map((chunkKey: string) => ({ source: doc.source, content: doc[chunkKey] }))
    );
  };

  // function to check if a string is valid JSON
  const isValidJSON = (jsonString: string): boolean => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  };

  const handleImageUrlMessages = (query: string) => {
    const imageurl = imageUploadedData.imageURLList;
    const imageContent = imageurl.map((url) => ({
      type: 'image_url',
      image_url: {
        url: url,
      },
    }));

    return {
      content: [{ type: 'text', text: query }, ...imageContent],
      role: 'user',
      name: user.initials(),
    };
  };

  const handlePostQuery = (newQuery: string, model: LlmModel) => {
    dispatch(setIsResponseBeingGenerated(true));
    LogButtonEventToGA(askButtonText);
    // conversationIDRef will remain empty only for the start of the conversation
    // and hence this API for addTitles gets called only once after the very first user query.
    //do not create new conversation Id for agent chat as Agent will have
    //seperate conversation Id for storing agent session.
    if (conversationIdRef.current === '' && !activeAgent._id && !activeTeam._id && isSaveCustomAppHistory) {
      let message = newQuery;
      // This uses the previously defined truncateString function.
      message = truncateString(message, 20);

      const prefix = choosenCustomApp.isCustomAppSelected
        ? t('history.customApp')
        : activeDataSetId
          ? t('history.docAi')
          : t('history.chatAi');
      message = `${prefix} : ${message}`;

      // }
      Api.addTitles(message)
        .then((response) => {
          conversationIdRef.current = response.data?.conversation_id as string;
          dispatch(
            setInitialHistoryState({
              conversationID: conversationIdRef.current, //syncing local and redux state for conversationID
            })
          );
          dispatch(
            setInitialHistoryState({
              activeHistory: message,
            })
          );
        })
        .catch((error) => {
          showSnackbarErrorMessage(error as ApiError);
        });
    }

    // If the speaker selector continues to send responses for agents/teams,
    // then we should avoid updating or showing the same message to the user again in the chat,
    // as we have already displayed it for the first user query.
    if (!isQueryResponseStatusPendingForAgentOrTeams.current) {
      if (imageUploadedData.imageURLList.length > 0) {
        updateMessages([{ ...handleImageUrlMessages(newQuery), role: 'user' }]);
      } else {
        updateMessages([
          {
            content: newQuery,
            role: 'user',
            ...(isAgentOrTeamActive && { name: user.initials() }),
          },
        ]);
      }
    }
    //When chat conversation happens with image file, the base64 Json data converted to string to update history.
    const updatedUserQuery =
      imageUploadedData.imageURLList.length > 0 ? JSON.stringify(handleImageUrlMessages(newQuery).content) : newQuery;

    setIsLoading(true);
    setAppMessage('');
    setisStreamComplete(false);

    // If the speaker selector continues to send responses for agents/teams,
    // then we should avoid updating or showing the same message to the user again in the chat,
    // as we have already displayed it for the first user query.
    if (!isQueryResponseStatusPendingForAgentOrTeams.current) {
      const userContent = (
        <UserContent
          initials={user.initials()}
          message={newQuery}
          imageSelectionChange={() => 'imagePath'}
          toolTipTextChange={(value) => `Tooltip for ${value}`}
        />
      );

      handleAddChatContent(userContent);
    }

    const initialResponseContent = (
      <ResponseContent
        appMessage=""
        modelIcon={getModelIcon(selectedModel)}
        modelTooltip={selectedModel.tooltip}
        isLoading={true}
        isStreamComplete={isStreamComplete}
        highlightResponse={false}
      />
    );

    handleAddChatContent(initialResponseContent);
    dispatch(
      setImageUploadData({
        imageURLList: [],
        imagesNames: [],
      })
    );

    handlePostQueryApi(model)
      .then(async (response) => {
        // TODO: Add error handling
        const body = response.response.body;
        if (!body) {
          setIsLoading(false);
          return;
        }
        // Check if the current conversation ID is not empty.
        if (conversationIdRef.current !== '' && !activeAgent._id && !activeTeam._id) {
          // Increment the conversation message count by 1.
          // This counter keeps track of the number of messages or queries sent during this conversation.
          conversationMessageCountRef.current = conversationMessageCountRef.current + 1;

          // Determine the skill name based on whether any files have been uploaded.
          // If there are files, use 'docCompletion' skill; otherwise, use 'completion' skill.
          // This likely affects how the conversation or query is processed on the backend.
          const skill_name = fileUpload.uploadedFiles.length > 0 ? Skill.docCompletion : Skill.completion;

          // Construct a dataset identifier using the user's initials and the determined skill name.
          // This identifier might be used for logging, analytics, or to tailor the conversation processing.
          const modelAPPDataset = `${user.initials()}:${skill_name}`;

          // Call the Api's updateConversation method to update the current conversation.
          // This method likely sends the new query along with the updated message count and dataset identifier
          // to the server, where it may be processed or logged accordingly.
          Api.updateConversation(
            conversationIdRef.current,
            updatedUserQuery,
            conversationMessageCountRef.current,
            modelAPPDataset
          );
        }

        try {
          const readableStream = body.pipeThrough(new TextDecoderStream());
          const reader = readableStream.getReader();
          setisStreamComplete(false);

          if (!reader) {
            enqueueSnackbar(t('snackbar.alertMessage.readerNotFound'));
            setIsLoading(false);
            return;
          }

          let bufferedData = '';
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              setisStreamComplete(true);
              break;
            }
            if (value === undefined) {
              continue;
            }
            const strings = value.split('\n');
            strings.forEach((string) => {
              if (string === '') {
                return;
              }
              bufferedData += string;

              // Check if bufferedData forms a complete JSON object
              if (isValidJSON(bufferedData)) {
                const data = JSON.parse(bufferedData);
                bufferedData = ''; // Clear the bufferedData after successful parsing

                if (data.data.source_documents) {
                  const extractedSources = extractSources(data.data.source_documents);
                  setDocumentSources(extractedSources);
                }

                //For Teams the last response will be empty and it contains conversation_id
                //in such cases also dont return.
                if (!data.data.content && !data.data.conversation_id) return;

                newContent += data.data.content;
                //for generating links on chat
                if (data.data.links) {
                  newContent += ' ' + data.data.links;
                }
                //use this agent/team conversationId for storing the agent/team session.
                if (data.data.conversation_id && isAgentOrTeamActive) {
                  conversationIdRef.current = data.data.conversation_id;

                  //store the name of agent replying to concat it before the response.
                  nameOfRepliedAgent.current = data.data.name;

                  // If the speaker selector continues to send responses for agents/teams,
                  // then we should update this variable to true so that the same Team/Agent query Api will be called again.
                  // from below useEffect. This process continues until the response_status is completed and all agents have replied.
                  isQueryResponseStatusPendingForAgentOrTeams.current =
                    data.data.response_status === t('rightPanel.team.responseRoundsPedning');
                }
                setIsLoading(false);
                setAppMessage(newContent);
              }
            });
          }
          dispatch(setIsResponseBeingGenerated(false));
        } catch (error) {
          showSnackbarErrorMessage(error as ApiError);
          console.error(error);
          setIsLoading(false);
          isQueryResponseStatusPendingForAgentOrTeams.current = false;
          dispatch(setIsResponseBeingGenerated(false));
        }

        //if the newContent is empty then dont update the messages to avoid api validation erros.
        if (newContent !== '') {
          updateMessages([
            {
              content: newContent.replace(checkRegex, '').replaceAll('<br>', '\n'),
              role: 'assistant',
              ...(isAgentOrTeamActive && { name: user.initials() }),
            },
          ]);
        }

        // Check if there's an ongoing conversation by checking if a conversation ID exists.
        if (conversationIdRef.current && !activeAgent._id && !activeTeam._id) {
          // Increase the count of messages sent in this conversation by one.
          conversationMessageCountRef.current += 1;

          // Decide which skill to use based on whether any files have been uploaded.
          // If files are present, use the 'docCompletion' skill for document processing.
          // If no files are uploaded, use the 'completion' skill for standard queries.
          const skill_name = fileUpload.uploadedFiles.length > 0 ? Skill.docCompletion : Skill.completion;

          //This identifier ('modelAPPDataset') is crucial for storing
          // responses in a way that allows us to differentiate between various AI models and their
          // versions when reloading history
          //Currently, it's set to 'gpt-4' but this will be dynamic in the future
          const modelAPPDataset = selectedModelHistory + ':' + skill_name + ':' + activeDataSetId;

          // Call the Api's updateConversation method to update the current query response .
          Api.updateConversation(
            conversationIdRef.current,
            newContent,
            conversationMessageCountRef.current,
            modelAPPDataset
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setImageUploadData({
            imageURLList: [],
            imagesNames: [],
          })
        );
        showSnackbarErrorMessage(error as ApiError);
        dispatch(setIsResponseBeingGenerated(false));
        isQueryResponseStatusPendingForAgentOrTeams.current = false;
      })
      .finally(() => {
        dispatch(
          setInitialHistoryState({
            clearValue: false,
          })
        );
      });
  };

  useEffect(() => {
    if (!isLoading) {
      const responseContent = (
        <ResponseContent
          appMessage={appMessage}
          isLoading={false}
          modelIcon={getModelIcon(selectedModel)}
          modelTooltip={selectedModel.tooltip}
          isStreamComplete={isStreamComplete}
          nameOfRepliedAgent={isAgentOrTeamActive ? nameOfRepliedAgent.current : ''}
          documentSources={documentSources}
          highlightResponse={true}
        />
      );
      handleUpdateLastChatContent(responseContent, appMessage);

      //Once the response is rendered on the chat and the response_status is pending for Team/Agents,
      //the agents/Team query API should be called again.
      //This process continues until the response_status is completed and all agents have replied.
      if (isQueryResponseStatusPendingForAgentOrTeams.current) {
        newContent = '';
        handlePostQuery(newContent, LlmModel.defaultModal);
      }
    }
  }, [appMessage, isLoading, isStreamComplete]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  return (
    <>
      {/* load these initial components only after the user is authenticated and loaded fully. */}
      {user.fullName && (
        <div id="boundary-box">
          <Header clearChatHistory={clearChatHistory} />
          {/* {isWelcomePage && (
            <Typography
              variant="h3"
              sx={{ marginBottom: '20px', color: '#3C82F6', textAlign: 'center', fontWeight: 700 }}
            >
              {t('chatInterface.welcomeMessage', { name: user.firstName() })}
            </Typography>
          )} */}

          <Box
            sx={{
              //marginRight: isLargeScreen ? (subPanelOpen ? '26.5rem' : '100px') : '100px',
              transition: 'all 200ms ease-in-out',
              width: isWelcomePage ? (isLargeScreen ? '1170px' : isMediumScreen ? '950px' : 'auto') : 'auto',
              margin: isWelcomePage
                ? isLargeScreen
                  ? subPanelOpen
                    ? '0 26.5rem 0 auto'
                    : '0 auto'
                  : '0 auto'
                : isLargeScreen
                  ? subPanelOpen
                    ? '0 26.5rem 0 0'
                    : '0 50px 0 0'
                  : '0',
              paddingBottom: isWelcomePage ? '80px' : '0',
            }}
          >
            {/* {isWelcomePage && <FeaturedList />} */}

            {isChatPage && (
              <ResponseBox
                userFirstName={user.firstName()}
                dynamicContent={chatContents}
                modelIcon={selectedModel.image}
                modelTooltip={selectedModel.tooltip}
              />
            )}
            <FeedbackForm />
            {isWelcomePage && tenantName !== TenantNames.StateStreet && <CarouselSlider />}
            <QueryBox
              userInitials={user.initials()}
              postQuery={handlePostQuery}
              clearChatHistory={clearChatHistory}
              selectedGuide={selectedGuide}
            />
            {/* {isWelcomePage && <ConversationGuides onGuideClick={onGuideClick} />} */}
            {isWelcomePage && <QuickView />}

            {/* {isWelcomePage && <RecentChatList />} */}
          </Box>
          {isOpenRightPanel && <RightNavPanel userFullName={user.fullName} initials={user.initials()} />}

          {isWelcomePage && <Footer />}
        </div>
      )}
    </>
  );
}
