import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Api, { ApiError } from '../../../../data/api/Api';
import {
  AppSkillId,
  chatPageNavigation,
  loggedInUserId,
} from '../../../../components/staticComponents/StaticHtmlGenerator';
import { Dataset, datasetSharedUser } from '../../../../components/rightPanel/subpanel/dataset/myDatasets/MyDatasets';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Input,
  Skeleton,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppDispatch, useAppSelector } from 'reduxStore/redux-hooks';
import {
  setFiles,
  toggleModal,
  updateDatasetState,
  setIsSharePointSelected,
  setDocumentPanelExpand,
} from '../../../../reduxStore/slices/FileUploadSlice';
import { supportedFileTypes } from '../../../../components/rightPanel/subpanel/dataset/createDataset/DatasetModal';
import { useNavigate } from 'react-router-dom';
import ShareDataset from '../../../../components/rightPanel/subpanel/dataset/shareDataset/ShareDataset';
import ImageButton from '../../../../components/sharedComponents/ImageButton';
import * as assets from '../../../../assets';
import {
  currentDataSet,
  ownerDataSet,
  selectedDataSet,
  setEditDataset,
  sharedDataSet,
  setSelectedDocumentsTabForViewAll,
} from 'reduxStore/slices/DataSetSlice';

import { showUserActionContentOnChat } from 'reduxStore/slices/NotifyUserActionContentSlice';
import { setRightPanelOpen, setOpenCreateAgentOrTeamPage } from '../../../../reduxStore/slices/CommonSlice';
import { ActiveNavItemContext } from '../../../../components/rightPanel/RightPanelContext';
import DeleteModal from '../../../../components/sharedComponents/DeleteModal';
import { findEmptyFileName, showSnackbarErrorMessage } from '../../../../components/staticComponents/staticUtiles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setOpenCloseDatasetModal } from '../../../../reduxStore/slices/DataSetSlice';

import { SharePointDataset } from '../../../../components/rightPanel/subpanel/dataset/createDataset/existingSharePointdataset/ExistingSharePointDatset';
import { LogButtonEventToGA } from '../../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';

const DatasetInfo = () => {
  const { t } = useTranslation();
  const filterDocumentTabs = [
    t('quickView.allDocuments'),
    t('rightPanel.myDocuments'),
    t('rightPanel.dataset.sharedWithMe'),
  ];
  const [documentTabValue, setDocumentTabValue] = useState<string>(filterDocumentTabs[1]);
  const [sharedPointTabValue, setSharedPointTabValue] = useState<string>(filterDocumentTabs[1]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allDatasets, setAllDatasets] = useState<Dataset[]>([]);
  const [datasetOwners, setDatasetOwners] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const navigate = useNavigate();
  const [isManageShareOpen, setIsManageShareOpen] = useState(false);
  const [currentSelectedDataset, setCurrentSelectedDataset] = useState<Dataset | SharePointDataset>();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const [selectedDatasetSharedUsers, setSelectedDatasetSharedUsers] = useState<datasetSharedUser[]>([]);
  const [isDatasetDeleteModalOpen, setIsDatasetDeleteModalOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [refreshAllDatasetList, setRefreshAllDatasetList] = useState<boolean>(false);
  const [myDocuments, setMyDocuments] = useState<Dataset[]>([]);
  const [sharedWithMeDocuments, setSharedWithMeDocuments] = useState<Dataset[]>([]);
  const { responseMessage } = useAppSelector((state) => state.fileUpload.fileSelection);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isFetching, setIsFetching] = React.useState<boolean>(true);
  const [allSharePointDatasets, setAllSharePointDatasets] = useState<SharePointDataset[]>([]);
  const [mySharePointDatasets, setMySharePointDatasets] = useState<SharePointDataset[]>([]);
  const [sharedWithMeSharePointDatasets, setSharedWithMeSharePointDatasets] = useState<SharePointDataset[]>([]);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false); // Ensure only one accordion is expanded at a time
  };

  // UseEffect hook to fetch the list of datasets on component mount
  useEffect(() => {
    fetchDatasetsList();
    fetchlistofSharedPointDatasets();
  }, [refreshAllDatasetList]);

  // function to filter, sort, and slice Documents list
  const filteredAndSortedDatasets = (datasets: Dataset[]) => {
    return datasets
      .filter((dataset) => dataset.name && dataset.name.toLowerCase()) // Ensure dataset.name is not null or undefined
      .sort((a, b) => a.name!.localeCompare(b.name!)) // Use non-null assertion since we've already filtered out null/undefined
      .slice(0, 6); // Only take the first 6 after filtering and sorting
  };

  // Function to fetch the list of datasets from the API
  const fetchDatasetsList = async () => {
    try {
      const [allDocumentsList, myDocumentsList, sharedwithMeDocumentsList] = await Promise.all([
        Api.listDatasets(true, true, AppSkillId.DocCompletion),
        Api.listDatasets(true, false, AppSkillId.DocCompletion),
        Api.listDatasets(false, true, AppSkillId.DocCompletion),
      ]);

      const allDocumentsData = filteredAndSortedDatasets(allDocumentsList.data as Dataset[]);
      const myDocumentsListData = filteredAndSortedDatasets(myDocumentsList.data as Dataset[]);
      const sharedWithMeDocumentsListData = filteredAndSortedDatasets(sharedwithMeDocumentsList.data as Dataset[]);

      setAllDatasets(allDocumentsData);
      setMyDocuments(myDocumentsListData);
      setSharedWithMeDocuments(sharedWithMeDocumentsListData);

      // Concatenate both lists for further processing
      await fetchAndStoreDatasetOwnersData([
        ...allDocumentsData,
        ...myDocumentsListData,
        ...sharedWithMeDocumentsListData,
      ]);
      setLoading(false);
      setRefreshAllDatasetList(false);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  // Function to filter, sort, and slice SharePointDatasets list
  const filteredAndSortedSharePointDatasets = (datasets: SharePointDataset[]) => {
    return datasets
      .filter((dataset) => dataset.name.toLowerCase())
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 6); // Only take the first 6 after filtering and sorting
  };
  // Function to fetch the list of share point datasets from the API
  const fetchlistofSharedPointDatasets = async () => {
    try {
      const sharedDatasetResponse = await Api.listDatasets(true, true, AppSkillId.sharePoint);
      const allSharePointData = filteredAndSortedSharePointDatasets(sharedDatasetResponse.data as SharePointDataset[]);
      const mySharePointData = filteredAndSortedSharePointDatasets(
        (sharedDatasetResponse.data as SharePointDataset[]).filter((dataset) =>
          dataset.owners?.includes(loggedInUserId())
        )
      );
      const sharedWithMeSharePointData = filteredAndSortedSharePointDatasets(
        (sharedDatasetResponse.data as SharePointDataset[]).filter(
          (dataset) => !dataset.owners?.includes(loggedInUserId())
        )
      );

      setAllSharePointDatasets(allSharePointData);
      setMySharePointDatasets(mySharePointData);
      setSharedWithMeSharePointDatasets(sharedWithMeSharePointData);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    } finally {
      setIsFetching(false);
    }
  };

  const closeManageSharePopup = () => {
    setIsManageShareOpen(false);
    setRefreshAllDatasetList(true);
  };

  const openManageSharePopup = (dataset: Dataset | SharePointDataset) => {
    fetchAndStoreDatasetSharedUserDetails(dataset);
    setCurrentSelectedDataset(dataset);
    setIsManageShareOpen(true);
  };

  let dsSharedUser: datasetSharedUser[] = [];

  const fetchAndStoreDatasetSharedUserDetails = async (dataset: Dataset | SharePointDataset) => {
    try {
      // Fetch user details using the acl.users from the original Agent interface
      const aclResponse = await Api.getUserDetailsByIds(dataset.acl?.users || []);
      const datasetSharedUserList = (dataset.acl?.users || []).map((id) => ({
        id,
        name: aclResponse[id] || id,
      }));
      setSelectedDatasetSharedUsers(datasetSharedUserList);
      dsSharedUser = datasetSharedUserList;
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      console.log(error);
    }
  };

  // Function to make dataset active
  const makeDatasetActive = async (dataset: Dataset | SharePointDataset) => {
    try {
      dispatch(
        selectedDataSet({
          activeDataSetName: dataset.name,
          activeDataSetId: dataset._id,
        })
      );
      dispatch(
        currentDataSet({
          currentDataSetName: dataset.name,
          currentDataSetId: dataset._id,
        })
      );
      dispatch(
        sharedDataSet({
          sharedDataList: { [dataset._id]: dsSharedUser },
        })
      );
      const ownersName = await Api.getUserDetailsByIds(dataset.owners);
      const ownerList = dataset.owners.map((id) => ownersName[id] || id);

      dispatch(
        ownerDataSet({
          ownersDataList: { [dataset._id]: ownerList },
        })
      );
      // dispatch(sharedDataSet({dataset._id,selectedDatasetSharedUsers}));
      dispatch(showUserActionContentOnChat(t('fileUpload.exisitingDatasetactiveMessage', { fileName: dataset.name })));
      navigate(chatPageNavigation);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const fetchAndStoreDatasetOwnersData = async (datasets: Dataset[]) => {
    try {
      // Fetch dataset owners for each dataset
      const datasetOwnerPromises = datasets.map(async (dataset) => {
        const datasetOwnerDetails = await Api.getUserDetailsByIds(dataset.owners);
        return datasetOwnerDetails;
      });

      // Wait for all dataset owner data to be fetched.
      const datasetOwnerData = await Promise.all(datasetOwnerPromises);

      // Transform the owner data into a single object where keys are owner IDs and values are owner names.
      const datasetOwnerIdAndNameMapping = datasetOwnerData.reduce((acc, datasetOwnerDetails) => {
        const datasetOwnerId = Object.keys(datasetOwnerDetails)[0];
        acc[datasetOwnerId] = datasetOwnerDetails[datasetOwnerId];
        return acc;
      }, {});

      const datasetOwnerIdAndEmailMapping = datasetOwnerData.reduce((acc, datasetOwnerDetails) => {
        const datasetOwnerId = Object.keys(datasetOwnerDetails)[0];
        acc[datasetOwnerId] = datasetOwnerDetails.email;
        return acc;
      }, {});

      setDatasetOwners({
        names: datasetOwnerIdAndNameMapping,
        emails: datasetOwnerIdAndEmailMapping,
      });
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const documentCardActions = [
    {
      icon: assets.shareIcon,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset | SharePointDataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openManageSharePopup(dataset);
      },
    },
    {
      icon: assets.pencilIcon,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset | SharePointDataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openEditDatasetPage(dataset);
      },
    },
    {
      icon: assets.delete,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset | SharePointDataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openDeleteDatasetModal(dataset);
      },
    },
  ];

  const documentCardActionsWithoutShare = [
    {
      icon: assets.pencilIcon,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset | SharePointDataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openEditDatasetPage(dataset);
      },
    },
    {
      icon: assets.delete,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset | SharePointDataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openDeleteDatasetModal(dataset);
      },
    },
  ];

  const renderDocuments = (datasets: Dataset[] | SharePointDataset[], showShareIcon: boolean = true) => {
    return datasets.map((dataset, index) => {
      return (
        <Card
          key={index}
          sx={{
            border: '1px solid #e5e7eb',
            backgroundColor: '#F9FAFB',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'visible',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => {
            makeDatasetActive(dataset);
          }}
        >
          <CardContent sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 700,
                  maxWidth: '20ch',
                }}
              >
                {dataset.name}
              </Typography>
              <Box>
                {(showShareIcon ? documentCardActions : documentCardActionsWithoutShare).map((action, index) => {
                  return (
                    <ImageButton
                      key={index}
                      srcFile={action.icon}
                      disabled={
                        dataset.owners[0] !== loggedInUserId() || (responseMessage && action.icon === assets.pencilIcon)
                      }
                      style={{
                        ...action.style,
                        opacity: dataset.owners[0] !== loggedInUserId() ? 0.5 : 1, // Set opacity for disabled state
                        pointerEvents: dataset.owners[0] !== loggedInUserId() ? 'none' : 'auto', // Ensure click events are disabled
                      }}
                      handleClick={(event) => {
                        if (action.onClick) {
                          action.onClick(dataset, event);
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            {[t('quickView.ownerLabel')].map((label, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4B5563',
                  lineHeight: '1.5rem',
                }}
              >
                {label}: {datasetOwners.names?.[dataset.owners[0]] || dataset.owners[0]}
              </Typography>
            ))}
          </CardContent>
        </Card>
      );
    });
  };

  const fileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files && files!.length === 0) return;
    dispatch(setFiles(Array.from(files as FileList)));
    e.target.value = '';
    dispatch(toggleModal());
    LogButtonEventToGA(`${t('quickView.document')} ${t('quickView.addDocument')}`);
    if (findEmptyFileName(files!)) {
      enqueueSnackbar(t('fileUpload.fileIsEmpty', { fileName: findEmptyFileName(files!) }), {
        variant: 'error',
      });
      return;
    }
  };

  const viewAllDocuments = () => {
    dispatch(setOpenCreateAgentOrTeamPage(false));
    dispatch(setRightPanelOpen(true));
    setActiveNavItem(t('welcomepage.Documents'));
    LogButtonEventToGA(`${t('quickView.document')} ${t('quickView.viewAll')}`);
    dispatch(setSelectedDocumentsTabForViewAll(documentTabValue));
    dispatch(setDocumentPanelExpand('panel1'));
  };
  const viewAllSharedPointDocuments = () => {
    dispatch(setOpenCreateAgentOrTeamPage(false));
    dispatch(setRightPanelOpen(true));
    setActiveNavItem(t('welcomepage.Documents'));
    LogButtonEventToGA(`${t('quickView.document')} ${t('quickView.viewAll')}`);
    dispatch(setSelectedDocumentsTabForViewAll(sharedPointTabValue));
    dispatch(setDocumentPanelExpand('panel2'));
  };

  const openDeleteDatasetModal = (dataset: Dataset | SharePointDataset) => {
    setCurrentSelectedDataset(dataset);
    setIsDatasetDeleteModalOpen(true);
  };

  // Function to confirm the deletion of the Dataset.
  const confirmDelete = async () => {
    await deleteDataset();
    setIsDatasetDeleteModalOpen(false);
  };

  const deleteDataset = async () => {
    try {
      if (currentSelectedDataset) {
        await Api.deleteDataset(currentSelectedDataset._id);
        enqueueSnackbar(t('quickView.deleteDataset', { datasetName: currentSelectedDataset.name }));
        setLoading(true);
        fetchDatasetsList();
        fetchlistofSharedPointDatasets();
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      console.error('Error deleting Document:', error);
    }
  };

  // Function to close the delete Dataset modal
  const closeDatasetDeleteModal = () => {
    setIsDatasetDeleteModalOpen(false);
  };

  //Function to Edit Dataset
  const openEditDatasetPage = (dataset: Dataset | SharePointDataset) => {
    dispatch(
      setEditDataset({
        addFiles: true,
        datasetId: dataset._id,
      })
    );
    dispatch(
      updateDatasetState({
        selectedDatasetId: dataset._id,
      })
    );
    // Type guard to check if dataset is SharePointDataset
    const isSharePointDataset = (dataset: Dataset | SharePointDataset): dataset is SharePointDataset => {
      return (dataset as SharePointDataset).skill_id !== undefined;
    };
    if (isSharePointDataset(dataset)) {
      dispatch(setIsSharePointSelected(AppSkillId.sharePoint));
    } else {
      dispatch(setIsSharePointSelected(''));
    }
    dispatch(setOpenCloseDatasetModal(true));
    // if (fileInputRef.current) {
    //   fileInputRef.current.click(); // Open the file dialog
    //   if (!addMoreFiles) {
    //     dispatch(setAddMoreFiles());
    //   }
    // }
  };

  //Function to open Dataset Modal where sharedPoint dataset can be added
  const openSharePointDatasetModal = () => {
    dispatch(setIsSharePointSelected(AppSkillId.sharePoint));
    dispatch(setOpenCloseDatasetModal(true));
  };
  //Function to open Dataset Modal where dataset can be added
  const openNewDatasetModal = () => {
    dispatch(setIsSharePointSelected(t('fileUpload.newDataset')));
    dispatch(setOpenCloseDatasetModal(true));
  };
  return (
    <Box>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" fontWeight={'700'}>
            {t('quickView.documents')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: 'end', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <label htmlFor="file-upload" style={{ display: 'none' }}>
                  <Input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    sx={{
                      opacity: '0',
                      zIndex: '99',
                      left: fileUpload.uploadedFiles.length > 0 ? '10px' : '-10px',
                      width: '1.86rem',
                    }}
                    inputProps={{
                      multiple: true,
                      accept: supportedFileTypes,
                    }}
                    onChange={fileInput}
                  />
                </label>
                <Button
                  sx={{
                    textTransform: 'none',
                    fontSize: '14px',
                    boxShadow: 'none',
                    height: '2rem',
                    '&:hover': { boxShadow: 'none' },
                  }}
                  variant="outlined"
                  endIcon={<NavigateNextIcon />}
                  size="small"
                  onClick={viewAllDocuments}
                >
                  {t('quickView.viewAll')}
                </Button>
                <label
                  className="custom-file-upload"
                  style={{
                    position: 'relative',
                    right: '2rem',
                    pointerEvents: responseMessage ? 'none' : 'auto',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    disabled={responseMessage}
                    sx={{
                      width: '10rem',
                      height: '2rem',
                      textTransform: 'none',
                      left: '2rem',
                      background: '#3C82F6',
                    }}
                    component="span"
                    onClick={openNewDatasetModal}
                  >
                    {t('quickView.addDocument')}
                  </Button>
                </label>
              </Box>
            </Box>

            <TabContext value={documentTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={(_, newValue) => setDocumentTabValue(newValue)}
                  aria-label="filter document tabs"
                  sx={{
                    '& .MuiTabs-indicator': {
                      backgroundColor: '#2563EB !important',
                    },
                  }}
                >
                  {filterDocumentTabs.map((tab) => (
                    <Tab
                      key={tab}
                      label={tab}
                      value={tab}
                      sx={{
                        '&.Mui-selected': {
                          color: '#2563EB',
                          fontWeight: '700',
                        },
                        textTransform: 'none',
                      }}
                    />
                  ))}
                </TabList>
              </Box>
              {filterDocumentTabs.map((tab) => (
                <TabPanel key={tab} value={tab} sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' },
                      gap: '30px',
                    }}
                  >
                    {loading
                      ? Array.from({ length: 6 }).map((_, index) => (
                          <Skeleton key={index} variant="rounded" width="100%" height={100} animation="wave" />
                        ))
                      : renderDocuments(
                          tab === t('quickView.allDocuments')
                            ? allDatasets
                            : tab === t('rightPanel.myDocuments')
                              ? myDocuments
                              : sharedWithMeDocuments
                        )}
                  </Box>
                </TabPanel>
              ))}
            </TabContext>
            {currentSelectedDataset && (
              <ShareDataset
                ownerMail={datasetOwners.emails[currentSelectedDataset.owners[0]]}
                openShareDataset={isManageShareOpen}
                closeShareDataset={closeManageSharePopup}
                datasetId={currentSelectedDataset._id}
                sharedListDataset={selectedDatasetSharedUsers}
                isNavigatedThroughQuickView={true}
                datasetName={currentSelectedDataset.name}
              />
            )}

            {isDatasetDeleteModalOpen && (
              <DeleteModal
                openDeleteModal={isDatasetDeleteModalOpen}
                closeDeleteModal={closeDatasetDeleteModal}
                modalAPi={confirmDelete}
                dialogTitle={t('rightPanel.deleteDataset')}
                textContent={`${t('rightPanel.confirmDelete')} ${
                  currentSelectedDataset && currentSelectedDataset.name
                }?`}
                confirmButtonText={t('rightPanel.removeButton')}
              />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="h6" fontWeight={'700'}>
            {t('quickView.sharedPointDocuments')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: 'end', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <label htmlFor="file-upload2" style={{ display: 'none' }}>
                  <Input
                    ref={fileInputRef}
                    id="file-upload2"
                    type="file"
                    sx={{
                      opacity: '0',
                      zIndex: '99',
                      left: fileUpload.uploadedFiles.length > 0 ? '10px' : '-10px',
                      width: '1.86rem',
                    }}
                    inputProps={{
                      multiple: true,
                      accept: supportedFileTypes,
                    }}
                    onChange={fileInput}
                  />
                </label>
                <Button
                  sx={{
                    textTransform: 'none',
                    fontSize: '14px',
                    boxShadow: 'none',
                    height: '2rem',
                    '&:hover': { boxShadow: 'none' },
                  }}
                  variant="outlined"
                  endIcon={<NavigateNextIcon />}
                  size="small"
                  onClick={viewAllSharedPointDocuments}
                >
                  {t('quickView.viewAll')}
                </Button>
                <label
                  className="custom-file-upload"
                  style={{
                    position: 'relative',
                    right: '2rem',
                    pointerEvents: responseMessage ? 'none' : 'auto',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    disabled={responseMessage}
                    sx={{
                      width: '10rem',
                      height: '2rem',
                      textTransform: 'none',
                      left: '2rem',
                      background: '#3C82F6',
                    }}
                    component="span"
                    onClick={openSharePointDatasetModal}
                  >
                    {t('quickView.addDocument')}
                  </Button>
                </label>
              </Box>
            </Box>

            <TabContext value={sharedPointTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={(_, newValue) => setSharedPointTabValue(newValue)}
                  aria-label="filter shared point document tabs"
                  sx={{
                    '& .MuiTabs-indicator': {
                      backgroundColor: '#2563EB !important',
                    },
                  }}
                >
                  {filterDocumentTabs.map((tab) => (
                    <Tab
                      key={tab}
                      label={tab}
                      value={tab}
                      sx={{
                        '&.Mui-selected': {
                          color: '#2563EB',
                          fontWeight: '700',
                        },
                        textTransform: 'none',
                      }}
                    />
                  ))}
                </TabList>
              </Box>
              {filterDocumentTabs.map((tab) => (
                <TabPanel key={tab} value={tab} sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' },
                      gap: '30px',
                    }}
                  >
                    {isFetching
                      ? Array.from({ length: 6 }).map((_, index) => (
                          <Skeleton key={index} variant="rounded" width="100%" height={100} animation="wave" />
                        ))
                      : renderDocuments(
                          tab === t('quickView.allDocuments')
                            ? allSharePointDatasets
                            : tab === t('rightPanel.myDocuments')
                              ? mySharePointDatasets
                              : sharedWithMeSharePointDatasets,
                          false
                        )}
                  </Box>
                </TabPanel>
              ))}
            </TabContext>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DatasetInfo;
