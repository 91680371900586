import { Avatar } from '@mui/material';
import statestreetLogo from '../../assets/statestreet/statestreet.png';
import UsePageNavigation from '../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { TenantNames } from '../../components/staticComponents/StaticHtmlGenerator';

interface CustomizeLogoCenter {
  externalId: string;
}

function CustomizeLogoCenter({ externalId }: CustomizeLogoCenter) {
  const { isChatPage, isWelcomePage } = UsePageNavigation();
  const logo = externalId === TenantNames.StateStreet ? statestreetLogo : '';

  return (
    <>
      <Avatar
        src={logo}
        alt="state street"
        data-testid="logo"
        sx={{
          width: isChatPage || isWelcomePage ? '170px' : { xs: '170px', md: '370px', lg: '370px' },
          position: 'absolute',
          left: 0,
          right: 0,
          height: 'auto',
          borderRadius: '0',
          margin: '0 auto',
        }}
      />
    </>
  );
}

export default CustomizeLogoCenter;
