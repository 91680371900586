import { useEffect, useState } from 'react';
import Api, { ApiError } from '../../../../../data/api/Api';
import { Box, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { ListOfExistingDataset } from '../createDataset/DatasetModal';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';

interface ViewAllModalProps {
  viewAllModalOpen: boolean;
  closeViewAllModal: () => void;
  currentDatasetId: string;
}

const ViewDatasetModal = ({ viewAllModalOpen, closeViewAllModal, currentDatasetId }: ViewAllModalProps) => {
  const { t } = useTranslation();
  const [datasetDetails, setDatasetDetails] = useState<ListOfExistingDataset | null>(null);

  const getDatasetDetails = async () => {
    try {
      const datasetDetail = await Api.getDataset(currentDatasetId);
      setDatasetDetails(datasetDetail.data as ListOfExistingDataset);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  useEffect(() => {
    getDatasetDetails();
  }, [viewAllModalOpen]);

  return (
    <Modal
      open={viewAllModalOpen}
      onClose={closeViewAllModal}
      aria-labelledby="dataset-modal-title"
      aria-describedby="dataset-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: 4,
        }}
      >
        <Box>
          <CloseIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={closeViewAllModal} />
        </Box>
        {!datasetDetails ? (
          <Typography>{t('viewModal.loadingDatasetDetails')}</Typography>
        ) : (
          <>
            <Typography id="dataset-modal-title" variant="h6" component="h2" sx={{ fontWeight: 700 }}>
              {datasetDetails.name}
            </Typography>
            <Typography sx={{ marginTop: '22px', fontWeight: 700 }}>{t('fileUpload.datasetContent')}</Typography>
            <Box
              id="dataset-modal-description"
              mt={2}
              sx={{
                maxHeight: '300px',
                overflowY: 'auto',
                paddingRight: '8px',
              }}
            >
              {datasetDetails.files && datasetDetails.files.length > 0 ? (
                datasetDetails.files.map((file: ListOfExistingDataset, index: number) => (
                  <Typography
                    key={index}
                    padding={'.25rem .25rem'}
                    bgcolor={'#F6F6F6B2'}
                    borderRadius={'.25rem'}
                    mb={1}
                  >
                    {file.name}
                  </Typography>
                ))
              ) : (
                <Typography>{t('viewModal.No_files_available')}</Typography>
              )}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ViewDatasetModal;
