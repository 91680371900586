import UsePageNavigation from '../../../../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { chatPageNavigation } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import BasicPopover from '../../../../../components/sharedComponents/BasicPopover';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ImageButton from '../../../../../components/sharedComponents/ImageButton';
import { buttonStyle } from '../../dataset/Dataset';
import * as assets from '../../../../../assets';
import Api, { ApiError } from '../../../../../data/api/Api';
import EditTeam from '../editTeam/EditTeam';
import { activeTeamMethod, refreshTeamsList } from '../../../../../reduxStore/slices/TeamsSlice';
import { lengthOfVisibleAgentOrTeamName } from '../../agents/myAgents/MyAgents';
import { setMaxRoundsForTeams } from '../../../../../reduxStore/slices/CommonSlice';
import useResetActiveItems from '../../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';

export interface MyTeamsData {
  name: string;
  _id: string;
  description: string;
  assistant_ids: string[];
  owners: string[];
  acl?: {
    users: string[];
  };
  workflow?: {
    transition_graph: {
      [key: string]: string[];
      initial_state: string[];
    };
    transition_type: string;
  };
}

function MyTeams() {
  const [allTeams, setAllTeams] = useState<MyTeamsData[]>([]);
  const [myTeams, setMyTeams] = useState<MyTeamsData[]>([]);
  const [sharedWithMeTeams, setSharedWithMeTeams] = useState<MyTeamsData[]>([]);
  const [ownedByMeChecked, setOwnedByMeChecked] = useState<boolean>(true);
  const [sharedWithMeChecked, setSharedWithMeChecked] = useState<boolean>(false);
  const [filteredTeamsList, setFilteredTeamsList] = useState<MyTeamsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { activeTeam, refetchTeamsList } = useAppSelector((state) => state.teams);
  const { t } = useTranslation();
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const resetActiveItems = useResetActiveItems();

  // UseEffect hook to fetch the list of Teams on component mount
  useEffect(() => {
    fetchMyTeamsList();
    if (refetchTeamsList) {
      dispatch(refreshTeamsList(false));
    }
  }, [refetchTeamsList]);

  useEffect(() => {
    modifyTeamsListFromSelectedCheckBox();
  }, [ownedByMeChecked, sharedWithMeChecked]);

  const modifyTeamsListFromSelectedCheckBox = () => {
    // Update the filtered teams list based on the current state
    if (ownedByMeChecked && sharedWithMeChecked) {
      setFilteredTeamsList(allTeams);
    } else if (ownedByMeChecked) {
      setFilteredTeamsList(myTeams);
    } else if (sharedWithMeChecked) {
      setFilteredTeamsList(sharedWithMeTeams);
    } else {
      setFilteredTeamsList([]);
    }
  };

  // Function to fetch the list of teams from the API
  const fetchMyTeamsList = async () => {
    try {
      const [allTeamsList, myTeamsList, sharedWithMeTeamsList] = await Promise.all([
        Api.getTeamsList(true, true),
        Api.getTeamsList(true, false),
        Api.getTeamsList(false, true),
      ]);

      // Filter, sort, and slice different teams lists
      const allTeamsListData = sortTeams(allTeamsList.data as MyTeamsData[]);
      const myTeamsListData = sortTeams(myTeamsList.data as MyTeamsData[]);
      const sharedWithMeTeamsListData = sortTeams(sharedWithMeTeamsList.data as MyTeamsData[]);

      // Set the filtered teams
      setAllTeams(allTeamsListData);
      setMyTeams(myTeamsListData);
      setSharedWithMeTeams(sharedWithMeTeamsListData);

      //default teams list will be owned by me teams.
      setFilteredTeamsList(myTeamsListData);

      //if the team is active and if its data gets updated then make sure
      //to update the active team data too.
      if (activeTeam._id) {
        dispatch(activeTeamMethod(allTeamsListData.find((team) => team._id === activeTeam._id) as MyTeamsData));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackbarErrorMessage(error as ApiError);
      console.error('Error in fetching my teams list:', error);
    }
  };

  // function to sort teams list
  const sortTeams = (team: MyTeamsData[]) => {
    return team.sort((a, b) => a.name.localeCompare(b.name));
  };

  // Function to set the Teams active
  const makeTeamActive = async (teamsData: MyTeamsData) => {
    await resetActiveItems(activeNavItem);
    dispatch(activeTeamMethod(teamsData));
    dispatch(showUserActionContentOnChat(t('rightPanel.team.activateTeam', { activeTeamName: teamsData.name })));
    dispatch(setMaxRoundsForTeams(teamsData.assistant_ids.length));

    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  // Function to filter the list of teams based on the search query
  const filterMyTeamsList = (teamName: string) => {
    setFilteredTeamsList(filteredTeamsList.filter((team) => team.name.toLowerCase().includes(teamName.toLowerCase())));
    if (teamName === '') {
      modifyTeamsListFromSelectedCheckBox();
    }
  };

  const handleTeamsSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === t('rightPanel.dataset.ownedByMe')) {
      setOwnedByMeChecked(event.target.checked);
    } else if (value === t('rightPanel.dataset.sharedWithMe')) {
      setSharedWithMeChecked(event.target.checked);
    }
  };

  const renderEditTeamPopover = (team: MyTeamsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditTeam team={team} isActiveTeam={false} />}
      />
    );
  };

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.team.myTeams')}
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box>
          {!myTeams.length ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.team.noCustomTeams')}
            </Typography>
          ) : (
            <>
              <Box>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ownedByMeChecked}
                        onChange={(event) => handleTeamsSelectionChange(event, t('rightPanel.dataset.ownedByMe'))}
                      />
                    }
                    label={t('rightPanel.dataset.ownedByMe')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sharedWithMeChecked}
                        onChange={(event) => handleTeamsSelectionChange(event, t('rightPanel.dataset.sharedWithMe'))}
                      />
                    }
                    label={t('rightPanel.dataset.sharedWithMe')}
                  />
                </FormGroup>
              </Box>
              <TextField
                label={t('rightPanel.team.searchTeamName')}
                variant="standard"
                id="searchTeam"
                onChange={(event) => filterMyTeamsList(event.target.value)}
                size="small"
                fullWidth
              />
              <List sx={{ maxHeight: '25rem', overflow: 'auto' }}>
                {filteredTeamsList.map((team) => (
                  <Box key={team._id} className="teams-list">
                    <ListItemButton
                      disabled={activeTeam._id === team._id}
                      sx={{
                        padding: '5px 10px',
                        // paddingLeft: '0px',
                      }}
                    >
                      {/* <ListItemAvatar sx={{ minWidth: '50px' }} onClick={() => makeTeamActive(team)}>
                        <Avatar alt="default team" src={assets.ai360Agent} />
                      </ListItemAvatar> */}
                      <Tooltip title={team.name.length > lengthOfVisibleAgentOrTeamName ? team.name : null} arrow>
                        <ListItemText
                          primary={team.name}
                          sx={{
                            padding: '8px 0px',
                            margin: '0px',
                            '& .MuiListItemText-primary': {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          onClick={() => makeTeamActive(team)}
                        />
                      </Tooltip>
                      {activeTeam._id !== team._id && (
                        <span className="buttonShowOnHover">{renderEditTeamPopover(team)}</span>
                      )}
                    </ListItemButton>
                  </Box>
                ))}
                {filteredTeamsList.length === 0 && <Typography color="red">{t('rightPanel.noResult')}</Typography>}
              </List>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MyTeams;
