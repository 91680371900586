import {
  chatPageNavigation,
  hiddenMyAgentsList,
  loggedInUserId,
} from '../../../../components/staticComponents/StaticHtmlGenerator';
import { MyAgentsData } from '../../../../components/rightPanel/subpanel/agents/myAgents/MyAgents';
import Api, { ApiError } from '../../../../data/api/Api';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Card, CardContent, Skeleton, Tab, Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ImageButton from '../../../../components/sharedComponents/ImageButton';
import * as assets from '../../../../assets';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ActiveNavItemContext } from '../../../../components/rightPanel/RightPanelContext';
import { setOpenCreateAgentOrTeamPage, setRightPanelOpen } from '../../../../reduxStore/slices/CommonSlice';
import DeleteModal from '../../../../components/sharedComponents/DeleteModal';
import ShareAgent from '../../../../components/rightPanel/subpanel/agents/shareAgent/ShareAgent';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import {
  activeAgentMethod,
  confirmAgentUpdate,
  setSelectedAgentsTabForViewAll,
  updateAgent,
} from '../../../../reduxStore/slices/AgentsSlice';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { UserDetails } from '../../../../components/rightPanel/subpanel/developerKey/DeveloperKey';
import { showSnackbarErrorMessage } from '../../../../components/staticComponents/staticUtiles';
import { LogButtonEventToGA } from '../../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';

const AgentsInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filterAgentTabs = [
    t('quickView.allAgents'),
    t('rightPanel.agent.myAgents'),
    t('rightPanel.dataset.sharedWithMe'),
  ];
  const [initialFilterAgentTab, setInitialFilterAgentTab] = useState<string>(filterAgentTabs[0]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allAgents, setAllAgents] = useState<MyAgentsData[]>([]);
  const [myAgents, setMyAgents] = useState<MyAgentsData[]>([]);
  const [sharedWithMeAgents, setSharedWithMeAgents] = useState<MyAgentsData[]>([]);
  const [agentOwners, setAgentOwners] = useState<UserDetails[]>([]);
  const [isAgentDeleteModalOpen, setIsAgentDeleteModalOpen] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<MyAgentsData>();
  const [refreshAllAgentsList, setRefreshAllAgentsList] = useState<boolean>(false);
  const [showShareAgentDialog, setShowShareAgentDialog] = useState<boolean>(false);
  const [selectedAgentSharedUsers, setSelectedAgentSharedUsers] = useState<UserDetails[]>([]);
  const [selectedAgentOwners, setSelectedAgentOwners] = useState<UserDetails[]>([]);
  const { isCreateAgentOrTeamPageOpen } = useAppSelector((state) => state.commonSlice);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    if (loading || !isCreateAgentOrTeamPageOpen) {
      fetchAgentsList();
    }
  }, [refreshAllAgentsList, isCreateAgentOrTeamPageOpen]);

  // Function to fetch the list of agents from the API's
  const fetchAgentsList = async () => {
    try {
      const [allAgentsList, myAgentsList, sharedWithMeAgentsList] = await Promise.all([
        Api.getAgentsList(true, true),
        Api.getAgentsList(true, false),
        Api.getAgentsList(false, true),
      ]);

      // Filter, sort, and slice different agents lists
      const allAgentsListData = filterSortAndSliceAgents(allAgentsList.data as MyAgentsData[]);
      const myAgentsListData = filterSortAndSliceAgents(myAgentsList.data as MyAgentsData[]);
      const sharedWithMeAgentsListData = filterSortAndSliceAgents(sharedWithMeAgentsList.data as MyAgentsData[]);

      // Set the filtered agents
      setAllAgents(allAgentsListData);
      setMyAgents(myAgentsListData);
      setSharedWithMeAgents(sharedWithMeAgentsListData);

      // Concatenate both lists for further processing
      await fetchAndStoreAgentOwnersData([...allAgentsListData, ...myAgentsListData, ...sharedWithMeAgentsListData]);

      setLoading(false);
      setRefreshAllAgentsList(false);
    } catch (error) {
      setLoading(false);
      console.error('Error in fetching all agents list:', error);
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  // function to filter, sort, and slice agents list
  const filterSortAndSliceAgents = (agents: MyAgentsData[]) => {
    return agents
      .filter((agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))
      .slice(0, 6); // Only take the first 6 after filtering and sorting
  };

  const fetchAndStoreAgentOwnersData = async (agents: MyAgentsData[]) => {
    try {
      // Fetch agent owners for each agent
      const agentOwnerPromises = agents.map(async (agent) => {
        const agentOwnerDetails = await Api.getCompleteUserDetailsByID(agent.owners);
        return agentOwnerDetails as UserDetails[];
      });

      // Wait for all agents owner data to be fetched.
      const agentOwnerData = await Promise.all(agentOwnerPromises);
      setAgentOwners(agentOwnerData.flat());
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const openDeleteAgentModal = (agent: MyAgentsData) => {
    setSelectedAgent(agent);
    setIsAgentDeleteModalOpen(true);
  };

  // Function to close the delete agent modal
  const closeAgentDeleteModal = () => {
    setIsAgentDeleteModalOpen(false);
  };

  const openShareAgentModal = (agent: MyAgentsData) => {
    fetchAndStoreAgentSharedUserDetails(agent);
    setSelectedAgent(agent);
    setShowShareAgentDialog(true);
    // Filter to get unique owners from agentOwners and ensure the owner is in the agent.owners list
    setSelectedAgentOwners(
      agentOwners.filter(
        (owner, ownerIndex, agentOwners) =>
          ownerIndex === agentOwners.findIndex((existingOwner) => existingOwner.id === owner.id) &&
          agent.owners.includes(owner.id)
      )
    );
  };

  const closeShareAgentDialog = () => {
    setShowShareAgentDialog(false);
    setLoading(true);
    setRefreshAllAgentsList(true);
  };

  const openCreateAgentPage = () => {
    dispatch(setRightPanelOpen(true));
    dispatch(setOpenCreateAgentOrTeamPage(true));
    setActiveNavItem(t('welcomepage.Agent'));
    LogButtonEventToGA(`${t('quickView.agent')} ${t('quickView.createAgent')}`);
  };

  const openEditAgentPage = (agent: MyAgentsData) => {
    dispatch(confirmAgentUpdate(true));
    dispatch(updateAgent(agent));
    openCreateAgentPage();
  };

  const fetchAndStoreAgentSharedUserDetails = async (agent: MyAgentsData) => {
    try {
      // Fetch user details using the acl.users from the original Agent interface
      const agentSharedUsersDetails = (await Api.getCompleteUserDetailsByID(agent.acl?.users || [])) as UserDetails[];
      setSelectedAgentSharedUsers(agentSharedUsersDetails);
    } catch (error) {
      enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
      console.log(error);
    }
  };

  // Function to make agent active
  const makeAgentActive = (agentData: MyAgentsData) => {
    dispatch(activeAgentMethod(agentData));
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.activateAgent', { activeAgentName: agentData.name })));
    navigate(chatPageNavigation);
  };

  const viewAllAgents = () => {
    dispatch(setOpenCreateAgentOrTeamPage(false));
    dispatch(setRightPanelOpen(true));
    setActiveNavItem(t('welcomepage.Agent'));
    dispatch(setSelectedAgentsTabForViewAll(initialFilterAgentTab));
    LogButtonEventToGA(`${t('quickView.agent')} ${t('quickView.viewAll')}`);
  };

  // Function to confirm the deletion of the agent.
  const confirmDelete = async () => {
    await deleteAgent();
    setIsAgentDeleteModalOpen(false);
  };

  const deleteAgent = async () => {
    try {
      if (selectedAgent) {
        const deleteAgentResponse = await Api.deleteAgent(selectedAgent._id);
        if (deleteAgentResponse) {
          enqueueSnackbar(t('quickView.deleteAgent', { agentName: selectedAgent.name }));
          setLoading(true);
          setRefreshAllAgentsList(true);
        }
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      console.error('Error deleting agent:', error);
    }
  };

  const agentCardActions = [
    {
      icon: assets.shareIcon,
      style: { padding: '0px 5px' },
      onClick: (agent: MyAgentsData, event: React.MouseEvent) => {
        event.stopPropagation();
        openShareAgentModal(agent);
      },
    },
    {
      icon: assets.pencilIcon,
      style: { padding: '0px 5px' },
      onClick: (agent: MyAgentsData, event: React.MouseEvent) => {
        event.stopPropagation();
        openEditAgentPage(agent);
      },
    },
    {
      icon: assets.delete,
      style: { padding: '0px 5px' },
      onClick: (agent: MyAgentsData, event: React.MouseEvent) => {
        event.stopPropagation();
        openDeleteAgentModal(agent);
      },
    },
  ];

  const renderAgents = (agents: MyAgentsData[]) => {
    return agents.map((agent, index) => {
      return (
        <Card
          key={index}
          sx={{
            border: '1px solid #e5e7eb',
            backgroundColor: '#F9FAFB',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'visible',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => makeAgentActive(agent)}
        >
          <CardContent sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 700,
                  maxWidth: '12ch',
                }}
              >
                {agent.name}
              </Typography>
              <Box>
                {agentCardActions.map((action, index) => {
                  return (
                    <ImageButton
                      key={index}
                      disabled={agent.owners[0] !== loggedInUserId()}
                      srcFile={action.icon}
                      style={{
                        ...action.style,
                        opacity: agent.owners[0] !== loggedInUserId() ? 0.5 : 1, // Set opacity for disabled state
                        pointerEvents: agent.owners[0] !== loggedInUserId() ? 'none' : 'auto', // Ensure click events are disabled
                      }}
                      handleClick={(event) => {
                        if (action.onClick) {
                          action.onClick(agent, event);
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            {[t('quickView.agentTypeLabel'), t('quickView.agentLLMLabel'), t('quickView.ownerLabel')].map(
              (label, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4B5563',
                    lineHeight: '1.5rem',
                  }}
                >
                  {label}:{' '}
                  {label === t('quickView.agentTypeLabel')
                    ? agent.type
                    : label === t('quickView.agentLLMLabel')
                      ? agent.model_name
                      : agentOwners.find((owner) => owner.id === agent.owners[0])?.displayName || agent.owners[0]}
                </Typography>
              )
            )}
          </CardContent>
        </Card>
      );
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight={'700'}>
          {t('quickView.agents')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              '&:hover': { boxShadow: 'none' },
            }}
            variant="outlined"
            endIcon={<NavigateNextIcon />}
            size="small"
            onClick={viewAllAgents}
          >
            {t('quickView.viewAll')}
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              '&:hover': { boxShadow: 'none' },
              background: '#3C82F6',
            }}
            variant="contained"
            startIcon={<AddIcon />}
            size="small"
            onClick={openCreateAgentPage}
          >
            {t('quickView.createAgent')}
          </Button>
        </Box>
      </Box>

      <TabContext value={initialFilterAgentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_, newValue) => setInitialFilterAgentTab(newValue)}
            aria-label="filter agents tabs"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#2563EB !important',
              },
            }}
          >
            {filterAgentTabs.map((tab) => (
              <Tab
                key={tab}
                label={tab}
                value={tab}
                sx={{
                  textTransform: 'none',
                  '&.Mui-selected': {
                    color: '#2563EB',
                    fontWeight: '700',
                    textTransform: 'none',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {filterAgentTabs.map((tab) => (
          <TabPanel key={tab} value={tab} sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' },
                gap: '30px',
              }}
            >
              {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton key={index} width="100%" variant="rounded" height={150} animation="wave" />
                  ))
                : renderAgents(
                    tab === t('quickView.allAgents')
                      ? allAgents
                      : tab === t('rightPanel.agent.myAgents')
                        ? myAgents
                        : sharedWithMeAgents
                  )}
            </Box>
          </TabPanel>
        ))}
      </TabContext>

      {isAgentDeleteModalOpen && (
        <DeleteModal
          openDeleteModal={isAgentDeleteModalOpen}
          closeDeleteModal={closeAgentDeleteModal}
          modalAPi={confirmDelete}
          dialogTitle={t('rightPanel.agent.editAgent.deleteAgent')}
          textContent={`${t('rightPanel.confirmDelete')} ${selectedAgent && selectedAgent.name}?`}
          confirmButtonText={t('rightPanel.removeButton')}
        />
      )}

      {showShareAgentDialog && selectedAgent && (
        <ShareAgent
          setShowShareAgentDialog={closeShareAgentDialog}
          agent={selectedAgent}
          selectedAgentSharedUsers={selectedAgentSharedUsers}
          selectedAgentOwners={selectedAgentOwners}
          isNavigatedThroughQuickView={true}
        />
      )}
    </Box>
  );
};

export default AgentsInfo;
