import React, { useState } from 'react';
import ConversationStyleSlider from '../../components/sharedComponents/ConversationStyleSlider';
import { Box, Modal, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DEFAULT_TOP_K } from '../../components/staticComponents/StaticHtmlGenerator';
import TopKConfiguration from '../../components/sharedComponents/TopKConfiguration';
import { useAppSelector } from '../../reduxStore/redux-hooks';

interface SettingsModalProps {
  control: any;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ control }) => {
  const [settingConfigurationModalOpen, setSettingConfigurationModalOpen] = useState<boolean>(false);
  const { activeDataSetName } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { updateAgentDetails, isAgentToBeUpdated } = useAppSelector((state) => state.agents);

  return (
    <Tooltip title="Setting" enterDelay={1000} arrow placement="top">
      <Box>
        <Box className="query-input-action-item">
          <SettingsIcon sx={{ cursor: 'pointer' }} onClick={() => setSettingConfigurationModalOpen(true)} />

          <Modal
            open={settingConfigurationModalOpen}
            onClose={() => setSettingConfigurationModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
            >
              <ConversationStyleSlider control={control} isAgentView={false} />
              {activeDataSetName && (
                <Box sx={{ marginTop: '30px' }}>
                  <TopKConfiguration
                    topKValue={isAgentToBeUpdated ? (updateAgentDetails.top_k ?? DEFAULT_TOP_K) : DEFAULT_TOP_K}
                  />
                </Box>
              )}
            </Box>
          </Modal>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default SettingsModal;
