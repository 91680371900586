import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TemperatureState {
  value: number;
}
const initialState: TemperatureState = {
  value: 0.3, // Default temperature
};

const temperatureSlice = createSlice({
  name: 'temperature',
  initialState,
  reducers: {
    setTemperature: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export const { setTemperature } = temperatureSlice.actions;
export default temperatureSlice.reducer;
