import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Api, { ApiError } from '../../../../../data/api/Api';
import { setIsTeamView, teamActivationMethod } from '../../../../../reduxStore/slices/TeamsSlice';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  InputLabel,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import * as assets from '../../../../../assets';
import { LoadingButton } from '@mui/lab';
import { MyAgentsData, MyAgentsDataResponse } from '../../agents/myAgents/MyAgents';
import { hiddenMyAgentsList } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import ShareTeam from '../shareTeam/ShareTeam';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';
import { resetAgentActivationProcess } from '../../../../../reduxStore/slices/AgentsSlice';
import { setOpenCreateAgentOrTeamPage } from '../../../../../reduxStore/slices/CommonSlice';
import useResetActiveItems from '../../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import AgentTransitions from './AgentTransitions';

interface CreateTeamData {
  name: string;
  _id: string;
  description: string;
  assistant_ids: string[];
  owners: string[];
  acl?: {
    users: string[];
  };
}

enum AgentTransitionType {
  Allowed = 'allowed',
  Disallowed = 'disallowed',
}

function CreateTeam() {
  const { t } = useTranslation();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const dispatch = useAppDispatch();
  const [isTeamCreateInProcess, setIsTeamCreateInProcess] = useState<boolean>(false);
  const [agents, setAgents] = useState<MyAgentsData[]>([]);
  const [ownedByMeAgents, setOwnedByMeAgents] = useState<MyAgentsData[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<MyAgentsData[]>([]);
  const [agentsTransitionGraph, setAgentsTransitionGraph] = useState<{ [key: string]: string[] }>({});
  const [agentsTransitionTypeAllowed, setAgentsTransitionTypeAllowed] = useState<boolean>(true);
  const [showShareTeamDialog, setShowShareTeamDialog] = useState<boolean>(false);
  const { activationTeam, ShowTeamActivationDialogOnChat } = useAppSelector(
    (state) => state.teams.teamActivationProcess
  );
  const { updateTeamDetails, isTeamToBeUpdated, isTeamOwner, isTeamView } = useAppSelector((state) => state.teams);
  const resetActiveItems = useResetActiveItems();

  //register create team form fields with default values to useForm.
  //useForm is a custom hook for managing forms with ease.
  const { watch, control, getValues, setValue } = useForm({
    defaultValues: {
      name: '',
      description: '',
      assistant_ids: [''],
      //this instructions field is not mandatory and can be removed completely once
      //this gets removed from API in the BE.
      instructions: 'Not Applicable',
      workflow: null as any, //type defined as any since workflow is not mandatory.
    },
  });

  useEffect(() => {
    updateAgentsTransitionWorkflow();
  }, [agentsTransitionGraph, agentsTransitionTypeAllowed]);

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    fetchMyAgentsList();
    //this instructions field is not mandatory and can be removed completely once
    //this gets removed from API in the BE.
    setValue('instructions', 'Not Applicable');
  }, []);

  const updateTeamFields = (sortedAgents: MyAgentsData[]) => {
    setValue('name', updateTeamDetails.name);
    setValue('description', updateTeamDetails.description);
    setValue('assistant_ids', updateTeamDetails.assistant_ids);
    setSelectedAgents(sortedAgents.filter((agent) => updateTeamDetails.assistant_ids.includes(agent._id)));
    if (updateTeamDetails.workflow && updateTeamDetails.workflow.transition_graph) {
      setAgentsTransitionGraph(updateTeamDetails.workflow.transition_graph);
      setAgentsTransitionTypeAllowed(updateTeamDetails.workflow.transition_type === AgentTransitionType.Allowed);
    }
  };

  // Function to fetch the list of agents from the API
  const fetchMyAgentsList = async () => {
    try {
      const fetchAndProcessAgents = async (isOwnedByMe: boolean, isSharedWithMe: boolean) => {
        const agentsListResponse = await Api.getAgentsList(isOwnedByMe, isSharedWithMe);
        const myAgentsListResp = agentsListResponse as MyAgentsDataResponse;
        const filteredAndSortedAgentsData = myAgentsListResp.data.filter(
          (agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase())
        );
        return filteredAndSortedAgentsData;
      };

      // Fetch and update owned by me Agents list
      const ownedByMeAgentsData = await fetchAndProcessAgents(true, false);
      setOwnedByMeAgents(ownedByMeAgentsData);

      // Fetch shared with me Agents list
      const sharedWithMeAgentsData = await fetchAndProcessAgents(false, true);

      //combine all the agents.
      setAgents(ownedByMeAgentsData.concat(sharedWithMeAgentsData).sort((a, b) => a.name.localeCompare(b.name)));

      //prefill data for edit team.
      if (isTeamToBeUpdated) {
        updateTeamFields(ownedByMeAgentsData.concat(sharedWithMeAgentsData));
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      console.error('Error in fetching my agents list:', error);
    }
  };

  const updateAgentsTransitionWorkflow = () => {
    if (!agentsTransitionGraph.initial_state) {
      setValue('workflow', null);
      return;
    }

    const workflow = {
      transition_graph: {
        initial_state: agentsTransitionGraph.initial_state || [],
        ...agentsTransitionGraph,
      },
      transition_type: agentsTransitionTypeAllowed ? AgentTransitionType.Allowed : AgentTransitionType.Disallowed,
    };
    setValue('workflow', workflow);
  };

  const handleAgentSelection = (event: React.SyntheticEvent, selectedAgents: MyAgentsData[]) => {
    setSelectedAgents(selectedAgents);
    setValue(
      'assistant_ids',
      selectedAgents.map((agent) => agent._id)
    );
  };

  const deleteSelectedAgent = (removeSelectedAgent: MyAgentsData) => {
    const updatedSelectedAgents = selectedAgents.filter((agent) => agent._id !== removeSelectedAgent._id);
    setSelectedAgents(updatedSelectedAgents);
    setValue(
      'assistant_ids',
      updatedSelectedAgents.map((agent) => agent._id)
    );
    updateAgentsTransitionGraph(removeSelectedAgent);
  };

  const updateAgentsTransitionGraph = (removeSelectedAgent: MyAgentsData) => {
    // Update the agentsTransitionGraph to remove the deleted agent and its connections
    setAgentsTransitionGraph((prevGraph) => {
      const newAgentsTransitionGraph = { ...prevGraph };

      // Remove the agent's ID from the transition graph
      delete newAgentsTransitionGraph[removeSelectedAgent._id];

      // Remove any connections to the deleted agent
      Object.keys(newAgentsTransitionGraph).forEach((source) => {
        if (newAgentsTransitionGraph[source]) {
          newAgentsTransitionGraph[source] = newAgentsTransitionGraph[source].filter(
            (id) => id !== removeSelectedAgent._id
          );
        }
      });

      // If the removed agent was in the initial state, remove it
      if (newAgentsTransitionGraph.initial_state) {
        newAgentsTransitionGraph.initial_state = newAgentsTransitionGraph.initial_state.filter(
          (id) => id !== removeSelectedAgent._id
        );

        // If initial_state is now empty, remove all connections and delete initial_state
        if (newAgentsTransitionGraph.initial_state.length === 0) {
          Object.keys(newAgentsTransitionGraph).forEach((source) => {
            if (source !== 'initial_state') {
              delete newAgentsTransitionGraph[source];
            }
          });
          delete newAgentsTransitionGraph.initial_state;
        }
      }

      return newAgentsTransitionGraph;
    });
  };

  const createTeamFormSubmit = async () => {
    resetActiveItems(t('welcomepage.Teams'));
    const createTeamFormData = getValues();
    setIsTeamCreateInProcess(true);
    try {
      if (ShowTeamActivationDialogOnChat) {
        dispatch(
          teamActivationMethod({
            ShowTeamActivationDialogOnChat: false,
            activationTeam: activationTeam,
          })
        );
      }
      const createOrUpdateTeam = isTeamToBeUpdated
        ? Api.updateTeam(createTeamFormData, updateTeamDetails)
        : Api.createTeam(createTeamFormData);
      await createOrUpdateTeam.then((createTeamResponse) => {
        if (createTeamResponse.data) {
          dispatch(resetAgentActivationProcess());
          const createTeamData = createTeamResponse.data as CreateTeamData;
          //once team is successfully created hold these team details to show information on the chat for the user to perform some action.
          if (!isTeamToBeUpdated) {
            dispatch(
              teamActivationMethod({
                ShowTeamActivationDialogOnChat: true,
                activationTeam: createTeamData,
              })
            );
            setShowShareTeamDialog(true);
          } else {
            setActiveNavItem(null);
            dispatch(
              showUserActionContentOnChat(
                t('rightPanel.team.createTeam.updateTeamSuccess', {
                  updatingTeamName: createTeamData.name ? createTeamData.name : updateTeamDetails.name,
                })
              )
            );
          }
        }
      });
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
    setIsTeamCreateInProcess(false);
    dispatch(setOpenCreateAgentOrTeamPage(false));
  };

  const closeSubPanel = () => {
    setActiveNavItem(null);
    setShowShareTeamDialog(false);
    dispatch(setOpenCreateAgentOrTeamPage(false));
  };

  return (
    <Box padding={'0% 5% 0% 20%'}>
      <Box padding={'20px 0px'}>
        <Typography sx={{ color: '#000', fontWeight: '700', fontSize: '22px', textAlign: 'center' }}>
          {isTeamToBeUpdated
            ? t('rightPanel.team.createTeam.updateTeam')
            : t('rightPanel.team.createTeam.createNewTeam')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '40px', marginBottom: '40px' }}>
        <Box sx={{ marginLeft: { xs: '0', sm: '0', lg: '-106px' }, display: { xs: 'none', sm: 'none', lg: 'block' } }}>
          <img width={66} height={66} src={assets.teamsIconEdit} alt="team edit icon" />
        </Box>
        <Box flexGrow={1}>
          <InputLabel sx={{ color: '#0B0C0C', fontWeight: '700', fontSize: '16px' }}>
            {t('rightPanel.team.createTeam.teamName') + '*'}
          </InputLabel>
          {/* The Controller from react-hook-form is used into our form,
          providing it with the necessary props and state to manage its 
          value, validation, and error handling, */}
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={isTeamView}
                variant="standard"
                fullWidth
                placeholder={t('rightPanel.agent.createAgent.placeHolderForAgentName')}
              />
            )}
          />
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel sx={{ color: '#0B0C0C', fontWeight: '700', fontSize: '16px' }}>
          {t('rightPanel.agent.createAgent.description')}
        </InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={isTeamView}
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.team.createTeam.placeHolderForDescription')}
            />
          )}
        />
      </Box>
      <Box marginBottom={'40px'} display={'flex'} gap={'50px'}>
        <Box width={'100%'}>
          <InputLabel className="mui-form-label">{t('rightPanel.team.createTeam.selectAgents') + '*'}</InputLabel>
          <Autocomplete
            multiple
            id="agents"
            options={agents}
            value={selectedAgents}
            disabled={isTeamView}
            onChange={handleAgentSelection}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <ListItem
                  key={key}
                  {...optionProps}
                  sx={{
                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: '#FFFFFF !important',
                    },
                  }}
                >
                  <Avatar
                    className="avatar"
                    alt="agent icon"
                    key={key}
                    src={
                      ownedByMeAgents.find((agent) => agent._id === option._id)
                        ? assets.agentModelIcon
                        : assets.shareAgentIcon
                    }
                    sx={{ borderRadius: '0px' }}
                  />
                  <ListItemText
                    sx={{
                      marginLeft: '10px',
                      padding: '5px',
                      backgroundColor: selected ? '#F7FFF3' : 'transparent',
                    }}
                    primary={option.name}
                  />
                  <Box
                    sx={{
                      marginRight: '30px',
                      paddingTop: '5px',
                      paddingRight: '5px',
                      backgroundColor: selected ? '#F7FFF3' : 'transparent',
                    }}
                  >
                    {selected ? (
                      <img src={assets.selectCircleIcon} alt="selected icon" />
                    ) : (
                      <img src={assets.plusCircleIcon} alt="select icon" />
                    )}
                  </Box>
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ padding: '8px 0px' }}
                variant="standard"
                placeholder={t('rightPanel.team.createTeam.selectAgentsPlaceHolder')}
              />
            )}
            renderTags={() => null}
          />
          <Box fontSize={'14px'} color={'#3C82F6'} paddingBottom={'10px'}>
            {t('rightPanel.team.createTeam.createTeamHelperText')}
          </Box>
          {selectedAgents.length > 0 && (
            <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500' }}>
              {t('rightPanel.team.createTeam.addedAgents')}
            </Typography>
          )}
          {selectedAgents.map((agent: MyAgentsData) => (
            <Chip
              sx={{
                margin: '5px 0px',
                marginRight: '10px',
                padding: '4px 10px',
                borderRadius: '4px',
                backgroundColor: '#3F3F3F',
                color: '#F2F2F2',
                position: 'relative',
              }}
              disabled={isTeamView}
              key={agent._id}
              label={agent.name}
              onDelete={() => deleteSelectedAgent(agent)}
              deleteIcon={
                <Box sx={{ position: 'absolute', right: '-10px', top: '-10px' }}>
                  <img src={assets.cancelIcon} alt="cancel icon" />
                </Box>
              }
            />
          ))}
        </Box>
      </Box>

      <AgentTransitions
        selectedAgents={selectedAgents}
        agentsTransitionGraph={agentsTransitionGraph}
        setAgentsTransitionGraph={setAgentsTransitionGraph}
        agentsTransitionTypeAllowed={agentsTransitionTypeAllowed}
        setAgentsTransitionTypeAllowed={setAgentsTransitionTypeAllowed}
      />

      <Box sx={{ float: 'right', gap: '34px', display: 'flex', paddingBottom: '50px' }}>
        <Button variant="text" sx={{ textTransform: 'none', textDecoration: 'underline' }} onClick={closeSubPanel}>
          {t('rightPanel.agent.createAgent.cancel')}
        </Button>
        {(!isTeamView || isTeamOwner) && (
          <LoadingButton
            type="button"
            variant="contained"
            sx={{
              textTransform: 'none',
              backgroundColor: '#3C82F6',
            }}
            disabled={!watch('name') || selectedAgents.length < 2}
            loading={isTeamCreateInProcess}
            onClick={
              isTeamView && isTeamOwner
                ? () => dispatch(setIsTeamView(false)) // Edit action
                : createTeamFormSubmit // Create/Update action
            }
          >
            {isTeamView && isTeamOwner
              ? t('rightPanel.team.editTeam.edit')
              : isTeamToBeUpdated
                ? t('rightPanel.team.createTeam.updateTeam')
                : t('rightPanel.team.createTeam.teamCreate')}
          </LoadingButton>
        )}
      </Box>
      {showShareTeamDialog && (
        <Box>
          <ShareTeam setShowShareTeamDialog={setShowShareTeamDialog} team={activationTeam} />
        </Box>
      )}
    </Box>
  );
}

export default CreateTeam;
