import { Typography, Autocomplete, TextField, Box, CircularProgress, Input } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnackbarErrorMessage } from '../../../../../staticComponents/staticUtiles';
import Api, { ApiError } from '../../../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../../../reduxStore/redux-hooks';
import { updateDatasetState } from '../../../../../../reduxStore/slices/FileUploadSlice';
import { Acl } from '../../myDatasets/MyDatasets';
import { AppSkillId } from '../../../../../staticComponents/StaticHtmlGenerator';

export interface File {
  name: string;
  ts: number;
}

export interface SharePointAttributes {
  power_automate_workflow_url: string;
  site_url: string;
}

export interface SharePointDataset {
  _id: string;
  ad_tenant_id?: string;
  desc: string | null;
  files: File[];
  name: string;
  owners: string[];
  sharepoint_attributes: SharePointAttributes;
  skill_id: string;
  tenant_id: string;
  acl?: Acl;
}

export interface ExistingDatasetProps {
  setSiteUrl: (url: string) => void;
  setWorkflowUrl: (url: string) => void;
  setSharePointDatasetName: (name: string) => void;
}

const ExistingSharePointDatset = ({ setSiteUrl, setWorkflowUrl, setSharePointDatasetName }: ExistingDatasetProps) => {
  const dispatch = useAppDispatch();
  const [sharedPointDataset, setSharedPointDataset] = React.useState<SharePointDataset[]>([]);
  const [selectedDataset, setSelectedDataset] = React.useState<SharePointDataset | null>(null);
  const [isFetching, setIsFetching] = React.useState<boolean>(true);
  const { dataset } = useAppSelector((state) => state.fileUpload);
  const { editDataset } = useAppSelector((state) => state.dataset);

  const listOfUserOwnedDataset = dataset.listOfUserOwnedDataset;

  const { t } = useTranslation();

  const fetchlistofSharedPointDatasets = async () => {
    try {
      const sharedDatasetResponse = await Api.listDatasets(true, false, AppSkillId.sharePoint);
      setSharedPointDataset(sharedDatasetResponse.data as SharePointDataset[]);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchlistofSharedPointDatasets();
  }, []);

  const handleSelect = (event: React.SyntheticEvent<Element, Event>, datasetSelected: SharePointDataset | null) => {
    setSelectedDataset(datasetSelected);
    setSiteUrl(datasetSelected?.sharepoint_attributes.site_url || '');
    setWorkflowUrl(datasetSelected?.sharepoint_attributes.power_automate_workflow_url || '');
    setSharePointDatasetName(datasetSelected?.name || '');
  };

  //function be checked

  const displaySelectedDatasetContent = (selectedDataset: SharePointDataset) => {
    dispatch(
      updateDatasetState({
        datasetName: selectedDataset.name,
      })
    );
  };
  const loadDatasetList = async () => {
    if (!editDataset.addFiles) return;
    const selectedDataset = sharedPointDataset.find((dataset) => dataset._id === editDataset.datasetId);

    if (selectedDataset) {
      displaySelectedDatasetContent(selectedDataset as SharePointDataset);
      setSelectedDataset(selectedDataset as SharePointDataset);
    }
    setSiteUrl(selectedDataset?.sharepoint_attributes.site_url || '');
    setWorkflowUrl(selectedDataset?.sharepoint_attributes.power_automate_workflow_url || '');
    setSharePointDatasetName(selectedDataset?.name || '');
  };

  useEffect(() => {
    loadDatasetList();
  }, [listOfUserOwnedDataset]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: '20px', maxWidth: '615px' }}>
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <Typography sx={{ fontWeight: 700 }}>{t('fileUpload.useSharePointExistingDataset')}</Typography>
          <Autocomplete
            options={sharedPointDataset}
            getOptionLabel={(option) => option.name}
            value={selectedDataset}
            onChange={handleSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={selectedDataset ? selectedDataset.name : t('fileUpload.selectPlaceholder')}
                variant="standard"
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: '10rem',
                overflow: 'auto',
              },
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                key={option._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0.5rem',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <Typography>{option.name}</Typography>
                </Box>
                {/* <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#256AFF' }}>
                  {`${option.files.length} Files`}
                </Typography> */}
              </Box>
            )}
          />
          <Typography fontSize={16} fontWeight={700} sx={{ marginTop: '1rem' }}>
            Site Url
          </Typography>
          <Input
            disabled
            fullWidth
            id="site-url"
            sx={{ marginBottom: '1rem' }}
            value={selectedDataset ? selectedDataset.sharepoint_attributes.site_url : ''}
          />
          <Typography fontSize={16} fontWeight={700}>
            Workflow Url
          </Typography>
          <Input
            disabled
            fullWidth
            id="site-url"
            value={selectedDataset ? selectedDataset.sharepoint_attributes.power_automate_workflow_url : ''}
          />
          {selectedDataset && selectedDataset.files.length !== 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', margin: '1rem 0' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>

                <Typography>{selectedDataset.files.length}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '6.5rem',
                  overflowY: 'scroll',
                  gap: '.25rem',
                }}
              >
                {selectedDataset.files.map((file, key) => (
                  <Typography
                    key={key}
                    sx={{
                      padding: '.25rem .25rem',
                      background: '#F6F6F6B2',
                      borderRadius: '.25rem',
                    }}
                  >
                    {file.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ExistingSharePointDatset;
