import { Box, Typography, Paper, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LogButtonEventToGA } from '../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import * as assets from '../../../assets';

// Define the CarouselItem interface before CarouselItemProps
interface CarouselItem {
  name: string;
  description: string;
  backGround: string;
}

function CarouselSlider() {
  const { t } = useTranslation();

  const items: CarouselItem[] = [
    {
      name: t('carouselCont.WhatsNew'),
      description: t('carouselCont.agentComplex'),
      backGround: assets.carousel_bg_comingSoon,
    },
    {
      name: 'Coming Soon',
      description: t('carouselCont.comingSoon'),
      backGround: assets.carousel_bg,
    },
  ];
  const handleNextClick = () => {
    LogButtonEventToGA(t('carouselCont.nextButton'));
  };
  const handlePrevClick = () => {
    LogButtonEventToGA(t('carouselCont.prevButton'));
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  //This is written for slider as part of accessibility check
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselIndicatorRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ marginBottom: '30px', height: isLargeScreen ? '410px' : 'auto' }}>
      <Carousel
        autoPlay={false}
        navButtonsAlwaysVisible={false} // Make the nav buttons always visible
        indicators={false}
        index={activeIndex}
        NextIcon={
          <IconButton onClick={handleNextClick} style={{ color: '#fff' }}>
            <ArrowForward />
          </IconButton>
        } // Custom right arrow icon
        PrevIcon={
          <IconButton onClick={handlePrevClick} style={{ color: '#fff' }}>
            <ArrowBack />
          </IconButton>
        } // Custom left arrow icon
      >
        {items.map((item, index) => (
          <CarouselItemComponent key={index} item={item} />
        ))}
      </Carousel>

      {/* jSX written for accessibility check for slider*/}
      <Box ref={carouselIndicatorRef} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {items.map((item, index) => (
          <button
            key={index}
            role="tab"
            aria-label={`${t('carouselCont.accessibilityCarouselAriaLabel')} ${index + 1} of ${item.name}`}
            aria-selected={index === activeIndex ? 'true' : 'false'}
            tabIndex={0}
            onClick={() => setActiveIndex(index)}
            style={{
              width: 5,
              height: 5,
              margin: '0 3px',
              borderRadius: '50%',
              padding: '5px',
              backgroundColor: index === activeIndex ? '#000' : 'grey',
              cursor: 'pointer',
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

// Define CarouselItemComponent to render each carousel slide
interface CarouselItemProps {
  item: CarouselItem;
  tabIndex?: number;
}

const CarouselItemComponent: React.FC<CarouselItemProps> = ({ item }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Paper
      sx={{
        height: isLargeScreen ? '360px' : 'auto',
        backgroundImage: `url(${item.backGround})`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        color: '#fff',
        padding: isLargeScreen ? '20px 20px' : '30px 20px',
        boxShadow: 'none',
      }}
    >
      <Typography
        component="div"
        className="textStyle"
        sx={{
          borderBottom: '1px solid #fff',
          marginBottom: '15px',
          width: '100%',
          textAlign: 'center',
          paddingBottom: '0',
        }}
        variant="h5"
        gutterBottom
        fontWeight="700"
      >
        <ReactMarkdown>{item.name}</ReactMarkdown>
      </Typography>
      <Typography component="div" className="textStyle">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.description}</ReactMarkdown>
      </Typography>
    </Paper>
  );
};

export default CarouselSlider;
