import { useState } from 'react';
import { Tooltip, Box, Typography, Icon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import HistoryInfoText from './HistoryInfoText';

const HistoryInfoIcon = ({}) => {
  const { t } = useTranslation();
  const [openHistoryInfoPopup, setOpenHistoryInfoPopup] = useState(false);

  return (
    <>
      <Box>
        <Tooltip
          title={
            <Box paddingLeft={'10px'}>
              <Typography sx={{ fontSize: '11px' }}>{t('history.infoIcon.tooltipText')}</Typography>
              <Typography
                sx={{ fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setOpenHistoryInfoPopup(true)}
              >
                {t('history.infoIcon.readMore')}
              </Typography>
            </Box>
          }
          arrow
        >
          <Icon sx={{ color: '#4282FE', verticalAlign: 'middle', marginLeft: '-7.2rem', cursor: 'pointer' }}>
            <InfoIcon sx={{ width: '20px' }} />
          </Icon>
        </Tooltip>
      </Box>
      {openHistoryInfoPopup && <HistoryInfoText></HistoryInfoText>}
    </>
  );
};

export default HistoryInfoIcon;
