import Api from './data/api/Api';
import { getAuthInstance } from './helpers/Auth';
import { BrowserCacheLocation } from '@azure/msal-browser';
import { TFunction } from 'i18next';
import { ExternalId } from './pages/authConfig';
import { SubPanelAssistants } from './components/rightPanel/subpanel/SubPanelEnums';

interface TenantDetails {
  audience: string;
  issuer: string;
  redirect_uri: string;
}

export const getTenantDetailsAndInitAuthInstance = async (
  urlPathParam: string,
  pathParmsToExculdeForTenantName: string[],
  t: TFunction
) => {
  const tenantName = await fetchTenantName(urlPathParam, pathParmsToExculdeForTenantName, t);
  const tenantDetails = await fetchTenantDetails(tenantName);

  // If tenant details are found, initialize the Auth instance.
  if (tenantDetails) {
    const authInstance = await fetchAuthInstanceDetails(tenantDetails);
    return authInstance;
  } else {
    return null;
  }
};

// This function saves the tenant name to local storage.
const fetchTenantName = async (urlPathParam: string, pathParmsToExculdeForTenantName: string[], t: TFunction) => {
  // Get the tenant name from the url path parameter, if it is empty assign 'wipro' as default tenant.
  let tenantName = urlPathParam ? urlPathParam : t('urlPathParameters.wipro');

  if (pathParmsToExculdeForTenantName.includes(tenantName.toLowerCase())) {
    // If the path parameter is excluded, get the tenant name from local storage.
    tenantName = getTenantName();
  }
  urlPathParam = tenantName;
  localStorage.setItem('tenantName', JSON.stringify(tenantName));
  ExternalId.tenantName = tenantName;
  return tenantName;
};

// This function fetches tenant details from the API.
const fetchTenantDetails = async (tenantName: string) => {
  try {
    const tenantDetails = await Api.fetchTenantDetails(tenantName);
    if (tenantDetails.error) {
      return;
    }
    return tenantDetails.data;
  } catch (error) {
    console.error(error);
  }
};

// This function fetches the Auth instance details.
const fetchAuthInstanceDetails = async (tenantDetails: TenantDetails) => {
  // Create the MSAL configuration using the provided tenant details.
  const msalConfigData = createMsalConfig(tenantDetails);

  localStorage.setItem('msalConfig', JSON.stringify(msalConfigData));

  // Get the Auth instance using the MSAL configuration.
  return await getAuthInstance();
};

// This function creates the MSAL configuration object.
const createMsalConfig = (tenantDetails: TenantDetails) => {
  return {
    auth: {
      clientId: tenantDetails.audience,
      redirectUri: tenantDetails.redirect_uri,
      authority: tenantDetails.issuer,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };
};

export const getTenantName = () => {
  return JSON.parse(localStorage.getItem('tenantName') as string);
};

export const storeQueryParameter = (queryParams: string) => {
  if (!queryParams) {
    clearQueryParameters();
    return;
  }

  const searchParams = new URLSearchParams(queryParams);
  const assistantEnumValues = Object.values(SubPanelAssistants);
  let validQueryParams = false;

  // Iterate over the query parameters and store them in session storage if they are valid.
  searchParams.forEach((value, key) => {
    if (assistantEnumValues.includes(key as SubPanelAssistants)) {
      //first clear all the existing query parameters before a new one is set.
      clearQueryParameters();
      sessionStorage.setItem(key, JSON.stringify(value));
      validQueryParams = true;
    }
  });

  // If no valid query parameters were found, clear any existing query parameters.
  if (!validQueryParams) {
    clearQueryParameters();
  }
};

export const clearQueryParameters = () => {
  Object.values(SubPanelAssistants).forEach((assistant) => {
    sessionStorage.removeItem(assistant);
  });
};
