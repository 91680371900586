import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TableCell, TDocumentDefinitions, CustomTableLayout, ContentTable, Size } from 'pdfmake/interfaces';
import Api from '../../../../data/api/Api';
import { HistoryConversationData } from './History';

interface TableLayout extends CustomTableLayout {
  fillColor: (rowIndex: number) => string | null;
  hLineWidth: (i: number, node: ContentTable) => number;
  vLineWidth: (i: number, node: ContentTable) => number;
  hLineColor: (i: number, node: ContentTable) => string;
  vLineColor: (i: number, node: ContentTable) => string;
  paddingLeft: (i: number, node: ContentTable) => number;
  paddingRight: (i: number, node: ContentTable) => number;
  paddingTop: (i: number, node: ContentTable) => number;
  paddingBottom: (i: number, node: ContentTable) => number;
}

const Roboto = {
  normal: `${window.location.origin}/fonts/Roboto-Regular.ttf`,
  bold: `${window.location.origin}/fonts/Roboto-Medium.ttf`,
  italics: `${window.location.origin}/fonts/Roboto-Italic.ttf`,
  bolditalics: `${window.location.origin}/fonts/Roboto-MediumItalic.ttf`,
};
const Emoji = {
  normal: `${window.location.origin}/fonts/NotoEmoji-Regular.ttf`,
};

pdfMake.fonts = {
  ...pdfMake.fonts,
  Roboto,
  Emoji,
};

export const openFilePickerAndSaveFile = async (file: Blob) => {
  try {
    const fileHandle = await window.showSaveFilePicker({
      suggestedName: 'history.pdf',
      types: [
        {
          description: 'PDF Format',
          accept: { 'application/pdf': ['.pdf'] },
        },
      ],
    });
    const writableStream = await fileHandle.createWritable();
    await writableStream.write(file);
    await writableStream.close();
  } catch (err) {
    console.error('Error saving PDF file:', err);
  }
};

const formatTableData = (
  content: string
): { table: { headerRows: number; widths: Size[]; body: TableCell[][] }; layout: TableLayout } | null => {
  if (content.includes('|') && content.includes('\n')) {
    const tableRows = content
      .split('\n')
      .map((row) =>
        row
          .split('|')
          .map((cell) => cell.trim())
          .filter((cell, index, array) => !(index === 0 || index === array.length - 1))
      )
      .filter((row) => row.length > 0 && row.some((cell) => cell !== ''));

    if (!tableRows.length || !tableRows[0] || !Array.isArray(tableRows[0])) {
      return null;
    }

    // Determine the maximum number of columns in any row
    const columnCount = Math.max(...tableRows.map((row) => row.length));
    if (isNaN(columnCount) || columnCount === 0) {
      return null;
    }

    // Set column widths as a percentage of the page width
    const columnWidths: Size[] = new Array(columnCount).fill('*');

    // Ensure all rows have the same number of columns by adding empty cells if necessary
    const normalizedTableRows = tableRows.map((row) => {
      while (row.length < columnCount) {
        row.push('');
      }
      return row;
    });

    return {
      table: {
        headerRows: 1,
        widths: columnWidths,
        body: normalizedTableRows as TableCell[][],
      },
      layout: {
        fillColor: (rowIndex: number): string | null => {
          return rowIndex === 0 ? '#CCCCCC' : null;
        },
        hLineWidth: (i: number, node: ContentTable): number => {
          return i === 0 || i === node.table.body.length ? 1 : 0.5;
        },
        vLineWidth: (i: number, node: ContentTable): number => {
          return i === 0 || (node.table.widths && i === node.table.widths.length) ? 1 : 0.5;
        },
        hLineColor: (i: number, node: ContentTable): string => {
          return i === 0 || i === node.table.body.length ? 'black' : 'gray';
        },
        vLineColor: (i: number, node: ContentTable): string => {
          return i === 0 || (node.table.widths && i === node.table.widths.length) ? 'black' : 'gray';
        },
        paddingLeft: (i: number): number => {
          return i === 0 ? 5 : 4;
        },
        paddingRight: (i: number, node: ContentTable): number => {
          return node.table.widths && i === node.table.widths.length - 1 ? 5 : 4;
        },
        paddingTop: (i: number): number => {
          return 2;
        },
        paddingBottom: (i: number): number => {
          return 2;
        },
      },
    };
  }
  return null;
};

const parseTextAndEmoji = (input: string): Array<{ text: string; font?: string }> => {
  const emojiRegex = /[\uD38C-\uDBFF][\uDC00-\uDFFF]|\u200D|\ufe0f/g;
  const segments: { text: string; font?: string }[] = [];

  let lastIndex = 0;
  let match;

  while ((match = emojiRegex.exec(input)) !== null) {
    if (match.index > lastIndex) {
      segments.push({
        text: input.slice(lastIndex, match.index),
        font: 'Roboto',
      });
    }
    segments.push({
      text: match[0],
      font: 'Emoji',
    });

    lastIndex = match.index + match[0].length;

    if (emojiRegex.lastIndex <= lastIndex) {
      emojiRegex.lastIndex = lastIndex + 1;
    }
  }
  if (lastIndex < input.length) {
    segments.push({
      text: input.slice(lastIndex),
      font: 'Roboto',
    });
  }
  return segments;
};

const createHistoryPdf = async (conversationMessages: HistoryConversationData[]) => {
  const llmModelsList = await Api.listLlmModels();

  const documentTemplate: TDocumentDefinitions = {
    // It sets the document metadata
    info: {
      title: 'Lab45 AI Plaform',
      author: 'Wipro',
      subject: 'LLM Generated Response',
      creator: 'Wipro',
      producer: 'Wipro',
    },

    // It sets the document size
    pageSize: 'A4',

    // It gives default Orientation to page
    pageOrientation: 'portrait',

    // It gives the default page margin to all the pages
    pageMargins: [40, 50],

    // It adds the watermark
    watermark: {
      text: 'Lab45 AI Plaform',
      fontSize: 45,
      color: 'purple',
      opacity: 0.18,
      italics: false,
    },

    // Actual file content
    content: conversationMessages.map((entry) => {
      const codeRegex = new RegExp('```\\S*\\n([\\s\\S]*?)\\n```', 'g');

      // trims extra spaces which is at start and end of text
      entry.content = entry.content.trim();

      // removes codeRegex match and add type of code at the top
      entry.content = entry.content.replace(codeRegex, (match: string, code: string) => {
        return `[ ${match.split('\n')[0].replace('```', '')} ] \n\n${code}`;
      });

      const formattedText = [];
      const title = entry.name.split(':')[0];
      const modalName = llmModelsList.find((modal) => modal.id === title);

      // gives the title to the text i.e either user intials or model name
      formattedText.push({
        text: `${modalName ? modalName.name.toUpperCase() : title.toUpperCase()} : `,
        style: 'type',
      });

      // splits the text based on new line
      const textParts: string[] = entry.content.split('\n');
      const mediaLink = entry.media.trim().replace(/\s+/g, '');

      // futher processes the text
      const processedText = textParts.map((line) => {
        const asteriskPatternRegex = new RegExp('\\*\\*(.*?)\\*\\*', 'g');

        // removes the asteriskRegex match and make the text line bold
        if (line.match(asteriskPatternRegex)) {
          const textParts: string[] = line.split('**');
          const formattedText = textParts.map((textPart, index) =>
            index % 2 === 1
              ? { text: parseTextAndEmoji(textPart).flat(), style: 'type' }
              : parseTextAndEmoji(textPart).flat()
          );

          return {
            text: [...formattedText].concat([{ text: '\n' }]),
          };
        }

        // removes the ### match and make the text line bold
        else if (line.startsWith('###')) {
          return {
            text: parseTextAndEmoji(line.replace('###', '').trim().concat('\n')).flat(),
            style: 'type',
          };
        }

        // if nothing matches it keeps the line as it is
        else {
          return {
            text: parseTextAndEmoji(line.concat('\n')).flat(),
          };
        }
      });

      //table
      const tableContent = formatTableData(entry.content);
      if (tableContent) {
        // Extract the introductory text before the table
        const introText = textParts
          .slice(
            0,
            textParts.findIndex((line) => line.startsWith('|'))
          )
          .join(' ');
        return [
          {
            text: [
              { text: `${modalName ? modalName.name.toUpperCase() : title.toUpperCase()} : `, style: 'type' },
              { text: introText, style: 'normal' },
            ],
            margin: [0, 0, 0, 10], // Add some space after the text
          },
          tableContent,
          {
            text: textParts
              .slice(textParts.findIndex((line) => line.startsWith('|')) + tableContent.table.body.length)
              .join('\n'),
          },
        ];
      }

      formattedText.push(
        mediaLink
          ? { text: [...processedText, { text: mediaLink, link: mediaLink, style: 'linkStyle' }] }
          : { text: processedText }
      );

      return {
        text: formattedText,
        style: 'conversation',
        preserveLeadingSpaces: true,
      };
    }),

    // gives default style to the pages
    defaultStyle: {
      fontSize: 12,
      alignment: 'justify',
      margin: [0, 5],
    },

    // defines style which is reusable
    styles: {
      type: {
        bold: true,
      },
      conversation: {
        margin: [0, 12],
      },
      linkStyle: { alignment: 'left' },
    },

    // gives page number to every page as a footer
    footer: (currentPage: number, pageCount: number): Content => {
      return [
        {
          text: `${currentPage}/${pageCount}`,
          fontSize: 10,
          alignment: 'center',
          margin: [0, 8],
        },
      ];
    },
  };

  return new Promise<Blob>((resolve) => {
    const pdfDocGenerator = pdfMake.createPdf(documentTemplate);
    pdfDocGenerator.getBlob((blob) => {
      resolve(blob);
    });
  });
};

export default createHistoryPdf;