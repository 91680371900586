import { ListOfExistingDataset } from '../../components/rightPanel/subpanel/dataset/createDataset/DatasetModal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DatasetModals {
  activeModalDatasets: boolean;
  successResponseModal: boolean;
}

interface FileUpload {
  uploadFile: boolean;
  uploadFileMessage: string;
  uploadedFiles: File[];
  listOfFilesInSelectedDataset: File[];
  listOfFilteredUploadedFilesExistInDataset: string[];
}

interface FileSelection {
  isFileDragging: boolean;
  responseMessage: boolean;
  isDatasetActive: boolean;
  isDatasetButtonDisabled: boolean;
  isReplaceFileCheckboxChecked: boolean;
  isVisionSummaryEnabled: boolean;
}
interface Dataset {
  makeActiveDataset: boolean;
  datasetName: string;
  listOfUserOwnedDataset: ListOfExistingDataset[];
  selectedDatasetId: string;
}
interface FileUploadState {
  imageUploadedData: ImageUploadedData;
  datasetModals: DatasetModals;
  fileUpload: FileUpload;
  fileSelection: FileSelection;
  dataset: Dataset;
  isSharepointSelected: string;
  documentPanelExpand: string | false;
}
interface ImageUploadedData {
  imageURLList: string[];
  imagesNames: string[];
}

const initialState: FileUploadState = {
  datasetModals: {
    activeModalDatasets: false,
    successResponseModal: false,
  },
  fileUpload: {
    uploadFile: false,
    uploadFileMessage: '',
    uploadedFiles: [],
    listOfFilesInSelectedDataset: [],
    listOfFilteredUploadedFilesExistInDataset: [],
  },
  fileSelection: {
    isFileDragging: false,
    responseMessage: false,
    isDatasetActive: false,
    isDatasetButtonDisabled: false,
    isReplaceFileCheckboxChecked: false,
    isVisionSummaryEnabled: false,
  },
  dataset: {
    makeActiveDataset: false,
    datasetName: '',
    listOfUserOwnedDataset: [],
    selectedDatasetId: '',
  },
  isSharepointSelected: '',
  documentPanelExpand: '' as string | false,
  imageUploadedData: {
    imageURLList: [],
    imagesNames: [],
  },
};

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.datasetModals['activeModalDatasets'] = !state.datasetModals['activeModalDatasets'];
    },
    setSuccessResponseModal: (state, action: PayloadAction<boolean>) => {
      state.datasetModals.successResponseModal = action.payload;
    },
    setFiles: (state, action: PayloadAction<File[]>) => {
      const files = action.payload;
      state.fileUpload['uploadedFiles'] = [];

      if (files.length === 1) state.fileUpload['uploadedFiles'] = files;
      else files.forEach((file) => state.fileUpload['uploadedFiles'].push(file));
    },
    updateFileUploadState: (state, action: PayloadAction<Partial<FileUpload>>) => {
      state.fileUpload = { ...state.fileUpload, ...action.payload };
    },
    updateFileSelectionState: (state, action: PayloadAction<Partial<FileSelection>>) => {
      state.fileSelection = { ...state.fileSelection, ...action.payload };
    },
    updateDatasetState: (state, action: PayloadAction<Partial<Dataset>>) => {
      state.dataset = { ...state.dataset, ...action.payload };
    },
    setIsSharePointSelected: (state, action: PayloadAction<string>) => {
      state.isSharepointSelected = action.payload;
    },
    setDocumentPanelExpand: (state, action: PayloadAction<string | false>) => {
      state.documentPanelExpand = action.payload;
    },
    setImageUploadData: (state, action: PayloadAction<ImageUploadedData>) => {
      state.imageUploadedData = action.payload;
    },
  },
});

export const {
  toggleModal,
  setSuccessResponseModal,
  setFiles,
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
  setIsSharePointSelected,
  setDocumentPanelExpand,
  setImageUploadData,
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
