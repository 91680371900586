import { useAppDispatch } from '../../../../reduxStore/redux-hooks';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UsePageNavigation from '../usePageNavigation/UsePageNavigation';
import { useNavigate } from 'react-router-dom';
import useResetActiveItems from '../useResetActiveItems/useResetActiveItem';
import { ActiveNavItemContext } from '../../../../components/rightPanel/RightPanelContext';
import { SubPanelAssistants } from '../../../../components/rightPanel/subpanel/SubPanelEnums';
import {
  AppSkillId,
  chatPageNavigation,
  CustomAppJsonName,
  CustomAppName,
  customAppsJsonNames,
  customAppsWithSpecificSkills,
} from '../../../../components/staticComponents/StaticHtmlGenerator';
import {
  CustomAppSkill,
  ListOfExistingDataset,
} from '../../../../components/rightPanel/subpanel/customApps/CustomApps';
import Api, { ApiError } from '../../../../data/api/Api';
import customAppsData from '../../../rightPanel/subpanel/customApps/CustomApps.json';
import { clearQueryParameters } from '../../../../MsalConfig';
import { refreshDatasetList, selectedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import {
  clearChat,
  customAppHistorySelected,
  saveAdvanceSummaryResponse,
  saveSelectedCustomApp,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
} from '../../../../reduxStore/slices/CustomAppsSlice';
import { setMaxRoundsForTeams, setRightPanelOpen } from '../../../../reduxStore/slices/CommonSlice';
import { showSnackbarErrorMessage } from '../../../../components/staticComponents/staticUtiles';
import { MyAgentsDataResponse } from '../../../../components/rightPanel/subpanel/agents/myAgents/MyAgents';
import { activeAgentMethod } from '../../../../reduxStore/slices/AgentsSlice';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { MyTeamsData } from '../../../../components/rightPanel/subpanel/teams/myTeams/MyTeams';
import { activeTeamMethod } from '../../../../reduxStore/slices/TeamsSlice';

const UseQueryParamNavigation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isWelcomePage, isChatPage } = UsePageNavigation();
  const navigate = useNavigate();
  const resetActiveItems = useResetActiveItems();
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const hasNavigatedThroughQueryParamRef = useRef(false);

  const isNavigatedThroughQueryParam = async () => {
    if (hasNavigatedThroughQueryParamRef.current) {
      return; // Skip if already navigated
    }
    hasNavigatedThroughQueryParamRef.current = true;

    const queryParams = {
      agent: sessionStorage.getItem(SubPanelAssistants.agent),
      team: sessionStorage.getItem(SubPanelAssistants.team),
      customApp: sessionStorage.getItem(SubPanelAssistants.app),
    };

    if (queryParams.agent) {
      await navigateToAgentUsingQueryParam(JSON.parse(queryParams.agent));
      return true;
    }
    if (queryParams.team) {
      await navigateToTeamUsingQueryParam(JSON.parse(queryParams.team));
      return true;
    }
    if (queryParams.customApp) {
      await navigateToCustomAppUsingQueryParam(JSON.parse(queryParams.customApp));
      return true;
    }
    return false;
  };

  const navigateToAgentUsingQueryParam = async (agent: string) => {
    try {
      const agentsList = (await Api.getAgentsList(true, true)) as MyAgentsDataResponse;
      const selectedAgent = agentsList.data.find((agentData) => agentData.name.toLowerCase() === agent.toLowerCase());

      if (selectedAgent) {
        dispatch(activeAgentMethod(selectedAgent));
        dispatch(
          showUserActionContentOnChat(t('rightPanel.agent.activateAgent', { activeAgentName: selectedAgent.name }))
        );
        dispatch(setRightPanelOpen(true));
        setActiveNavItem(t('welcomepage.Agent'));
        navigate(chatPageNavigation);
      } else {
        navigateToMainPage();
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      navigateToMainPage();
    }
  };

  const navigateToTeamUsingQueryParam = async (team: string) => {
    try {
      const teamsList = await Api.getTeamsList(true, true);
      const selectedTeam = (teamsList.data as MyTeamsData[]).find(
        (teamData) => teamData.name.toLowerCase() === team.toLowerCase()
      );

      if (selectedTeam) {
        dispatch(activeTeamMethod(selectedTeam));
        dispatch(showUserActionContentOnChat(t('rightPanel.team.activateTeam', { activeTeamName: selectedTeam.name })));
        dispatch(setMaxRoundsForTeams(selectedTeam.assistant_ids.length));
        dispatch(setRightPanelOpen(true));
        setActiveNavItem(t('welcomepage.Teams'));
        navigate(chatPageNavigation);
      } else {
        navigateToMainPage();
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      navigateToMainPage();
    }
  };

  const navigateToCustomAppUsingQueryParam = async (customApp: string) => {
    const customAppNameKey = Object.keys(CustomAppName).find((key) => key.toLowerCase() === customApp.toLowerCase());
    const customAppJsonNameKey = Object.keys(CustomAppJsonName).find(
      (key) => key.toLowerCase() === customApp.toLowerCase()
    );
    const selectedCustomApp = customAppNameKey
      ? CustomAppName[customAppNameKey as keyof typeof CustomAppName]
      : customAppJsonNameKey
        ? CustomAppJsonName[customAppJsonNameKey as keyof typeof CustomAppJsonName]
        : null;

    if (selectedCustomApp) {
      if (await checkIfUserHasAccessToCustomApp(customAppNameKey as string, customAppJsonNameKey as string)) {
        makeCustomAppActive(selectedCustomApp);
        dispatch(setRightPanelOpen(true));
        setActiveNavItem(t('welcomepage.Apps'));
      } else {
        navigateToMainPage();
      }
    } else {
      clearQueryParameters();
    }
  };

  const checkIfUserHasAccessToCustomApp = async (customAppNameKey: string, customAppJsonNameKey: string) => {
    try {
      if (!customAppNameKey && !customAppJsonNameKey) {
        return false;
      }

      if (customAppNameKey) {
        const customAppSkillsList = await Api.fetchSkillsData();
        const appSkills = customAppSkillsList.data as CustomAppSkill[];
        return appSkills.some(
          (customKey) => customKey.skill_id === AppSkillId[customAppNameKey as keyof typeof AppSkillId]
        );
      }

      if (customAppJsonNameKey) {
        const customAppSelected = customAppsData.apps_custom.find(
          (customApp) => customApp.name === customAppJsonNameKey
        );
        const listOfDatasets = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
          .data as ListOfExistingDataset[];
        return listOfDatasets.some((dataset) => dataset._id === customAppSelected?.dataset_id);
      }
      return false;
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
      return false;
    }
  };

  // Function to set the active customapp
  const makeCustomAppActive = async (customApp: string) => {
    await resetActiveItems(activeNavItem);
    dispatch(saveSowCommitAiQueryResponse([]));
    dispatch(saveSowPredictorQueryResponse({}));
    dispatch(saveAdvanceSummaryResponse({ adv_summary: '', doc_summary: '' }));
    dispatch(clearChat(true));
    dispatch(customAppHistorySelected(false));

    dispatch(
      saveSelectedCustomApp({
        isCustomAppSelected: true,
        selectedCustomApp: customApp,
        selectedCustomAppSkill: getCustomAppSkill(customApp),
        welcomeMessage: customAppsWithSpecificSkills.includes(customApp)
          ? t('rightPanel.customapps.activateCustomApp', { selectedCustomApp: customApp })
          : CustomAppJsonName.WiproConsulting === customApp
            ? t('rightPanel.customapps.WiproConsulting', { selectedCustomApp: customApp })
            : CustomAppJsonName.GSG === customApp
              ? t('rightPanel.customapps.GSG', { selectedCustomApp: customApp })
              : t('rightPanel.customapps.appsWelcomeMessage', { selectedCustomApp: customApp }),
      })
    );

    dispatch(refreshDatasetList());

    if (customApp === CustomAppName.QETPlatform) {
      window.open(t('rightPanel.customapps.qetLink'), '_blank');
    }
    // on select of Custom Apps Json Names handling the dataset
    if (customAppsJsonNames.includes(customApp)) {
      handleCustomAppOptionSelection(customApp);
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  const getCustomAppSkill = (customApp: string) => {
    switch (customApp) {
      case CustomAppName.SOWCommitAI:
        return AppSkillId.SOWCommitAI;
      case CustomAppName.SOWPredictor:
        return AppSkillId.SOWPredictor;
      case CustomAppName.SOWFinAI:
        return AppSkillId.SOWFinAI;
      case CustomAppName.AdvancedSummary:
        return AppSkillId.AdvancedSummary;
      default:
        return AppSkillId.SOWPredictor;
    }
  };

  const handleCustomAppOptionSelection = (customAppJsonName: string) => {
    const customAppsDetails = customAppsData.apps_custom;
    const datasetSelected = customAppsDetails.find((customApp) => customApp.sticky_header === customAppJsonName);
    if (datasetSelected) {
      dispatch(
        selectedDataSet({
          activeDataSetName: datasetSelected.name,
          activeDataSetId: datasetSelected.dataset_id,
        })
      );
    }
  };

  const navigateToMainPage = () => {
    if (isWelcomePage) {
      setActiveNavItem(t('welcomepage.Home'));
    } else if (isChatPage) {
      setActiveNavItem(t('welcomepage.Chat'));
    }
  };

  return { isNavigatedThroughQueryParam, navigateToMainPage, makeCustomAppActive };
};

export default UseQueryParamNavigation;
