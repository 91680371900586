import { enqueueSnackbar } from 'notistack';
import { ApiError } from '../../data/api/Api';

//Removed useCallback because getFormattedDateAndTime doesn’t need to be a hook, it doesn’t rely on any component state or lifecycle.
//We can simply define it as a regular function instead of using useCallback.
export const getFormattedDateAndTime = (last_updated: number) => {
  const date = new Date(last_updated * 1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return {
    dateString: `${day} ${month} ${year}`,
    timeString: ` ${hours}:${minutes}`,
  };
};

const validationErrorMessage = (error: ApiError) => {
  if (error.body?.detail.json) {
    // Access the json object where all the dynamic keys are stored
    const fields = error.body?.detail.json;

    // Iterate through the field names in the json object
    for (const fieldName in fields) {
      const errorMessages = fields[fieldName];

      // Check if there are any error messages and return message
      if (errorMessages && errorMessages.length) {
        const [errorMessage] = errorMessages;
        return errorMessage || error.message;
      }
    }
  }
  // If no specific error messages, return the general message if available
  return error.message;
};

export const showSnackbarErrorMessage = (error: ApiError) => {
  enqueueSnackbar(validationErrorMessage(error).toString(), {
    variant: 'error',
  });
};

export const findEmptyFileName = (files: File[] | FileList): string => {
  const emptyFile = Array.from(files).find((file) => file.size === 0);
  if (emptyFile) return emptyFile.name;
  else return '';
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url); // This will throw an error if the URL is invalid
    return true;
  } catch {
    return false;
  }
};
