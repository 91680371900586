import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttachExistingDataset from './attach-existing-dataset/AttachExistingDataset';
import AttachNewDataset from './attach-new-dataset/AttachNewDataset';
import { ListOfExistingDataset } from '../../../dataset/createDataset/DatasetModal';
import Api, { ApiError } from '../../../../../../data/api/Api';
import { AppSkillId } from '../../../../../../components/staticComponents/StaticHtmlGenerator';
import { useAppDispatch, useAppSelector } from '../../../../../../reduxStore/redux-hooks';
import { showSnackbarErrorMessage } from '../../../../../../components/staticComponents/staticUtiles';
import { updateFileSelectionState } from '../../../../../../reduxStore/slices/FileUploadSlice';
import AttachSharepointDataset from './attach-sharepoint-dataset/AttachSharepointDataset';
import AttachExistingSharePointDataset from './attach-existing-sharepoint-dataset/AttachExistingSharePointDataset';

export interface AttachDataset {
  attachDataset: (datasetId: string) => void;
  setIsAgentCreateInProcess: (isInProcess: boolean) => void;
  selectedExistingDataset?: ListOfExistingDataset;
  attachDatasetTopK: (topKValue: number) => void;
}

function AttachDataset({ attachDataset, setIsAgentCreateInProcess, attachDatasetTopK }: AttachDataset) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [existingDatasetType, setExistingDatasetType] = useState<string>();
  const [newDatasetType, setNewDatasetType] = useState<string>(t('fileUpload.newDataset'));
  const [datasetList, setDatasetList] = useState<ListOfExistingDataset[]>([]);
  const [isDatasetListLoading, setIsDatasetListLoading] = useState<boolean>(false);
  const { isAgentView } = useAppSelector((state) => state.agents);
  const { isAgentToBeUpdated, updateAgentDetails } = useAppSelector((state) => state.agents);

  const [activeDatasetTab, setActiveDatasetTab] = useState<string>(t('fileUpload.new'));

  const handleTabSelectionChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveDatasetTab(newValue);
    dispatch(
      updateFileSelectionState({
        isVisionSummaryEnabled: false,
      })
    );
  };

  useEffect(() => {
    fetchDatasets();
    if (isAgentToBeUpdated) {
      setActiveDatasetTab(t('fileUpload.existing'));
    }
  }, [isAgentToBeUpdated]);

  const updateExistingDatasetTypeForEditAgent = (listOfExistingDataset: ListOfExistingDataset[]) => {
    const isDocumentDataset = listOfExistingDataset.find((dataset) => dataset._id === updateAgentDetails.dataset_id);
    if (isDocumentDataset) {
      setExistingDatasetType(t('fileUpload.existingDataset'));
    } else {
      setExistingDatasetType(t('fileUpload.existingSharePointDataset'));
    }
  };

  const fetchDatasets = async () => {
    try {
      setIsDatasetListLoading(true);
      const listOfExistingDataset = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
        .data as ListOfExistingDataset[];
      setDatasetList(listOfExistingDataset);
      updateExistingDatasetTypeForEditAgent(listOfExistingDataset);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    } finally {
      setIsDatasetListLoading(false);
    }
  };

  return (
    <Box sx={{ padding: '0px 35px' }}>
      {isDatasetListLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography sx={{ fontWeight: 700, color: '#0B0C0C' }}>
            {t('rightPanel.agent.createAgent.attachDataset.datasetSelection')}
          </Typography>

          <Tabs
            value={activeDatasetTab}
            onChange={handleTabSelectionChange}
            sx={{ marginBottom: '1rem' }}
            indicatorColor="primary"
            textColor="primary"
          >
            {datasetList.length > 0 && (
              <Tab label={t('fileUpload.existing')} value={t('fileUpload.existing')} disabled={isAgentView} />
            )}
            <Tab label={t('fileUpload.new')} value={t('fileUpload.new')} disabled={isAgentView || isAgentToBeUpdated} />
          </Tabs>

          {/* Show existingDatasetType selection when 'existing' is selected */}
          {activeDatasetTab === t('fileUpload.existing') && (
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="existingDatasetType"
                value={existingDatasetType}
                onChange={(event) => setExistingDatasetType(event.target.value)}
              >
                <FormControlLabel
                  value={t('fileUpload.existingDataset')}
                  control={<Radio />}
                  label={t('fileUpload.existingDataset')}
                  disabled={
                    isAgentView ||
                    (isAgentToBeUpdated && existingDatasetType === t('fileUpload.existingSharePointDataset'))
                  }
                />
                <FormControlLabel
                  value={t('fileUpload.existingSharePointDataset')}
                  control={<Radio />}
                  label={t('fileUpload.existingSharePointDataset')}
                  disabled={
                    isAgentView || (isAgentToBeUpdated && existingDatasetType === t('fileUpload.existingDataset'))
                  }
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Show components based on sub-selection */}
          {activeDatasetTab === t('fileUpload.existing') && existingDatasetType === t('fileUpload.existingDataset') && (
            <AttachExistingDataset
              attachDataset={attachDataset}
              setIsAgentCreateInProcess={setIsAgentCreateInProcess}
              attachDatasetTopK={attachDatasetTopK}
            />
          )}

          {activeDatasetTab === t('fileUpload.existing') &&
            existingDatasetType === t('fileUpload.existingSharePointDataset') && (
              <>
                <AttachExistingSharePointDataset attachDataset={attachDataset} />
              </>
            )}

          {/* Show newDatasetDatasetType selection when 'newDataset' is selected */}
          {activeDatasetTab === t('fileUpload.newDataset') && (
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="newDatasetType"
                value={newDatasetType}
                onChange={(event) => setNewDatasetType(event.target.value)}
              >
                <FormControlLabel
                  value={t('fileUpload.createNewDataset')}
                  control={<Radio />}
                  label={t('fileUpload.createNewDataset')}
                  disabled={isAgentView}
                />
                <FormControlLabel
                  value={t('fileUpload.sharePointDataset')}
                  control={<Radio />}
                  label={t('fileUpload.sharePointDataset')}
                  disabled={isAgentView}
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Show components based on sub-selection */}
          {activeDatasetTab === t('fileUpload.newDataset') && newDatasetType === t('fileUpload.createNewDataset') && (
            <AttachNewDataset
              attachDataset={attachDataset}
              setIsAgentCreateInProcess={setIsAgentCreateInProcess}
              attachDatasetTopK={attachDatasetTopK}
            />
          )}
          {activeDatasetTab === t('fileUpload.newDataset') && newDatasetType === t('fileUpload.sharePointDataset') && (
            <>
              <AttachSharepointDataset attachDataset={attachDataset} />
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default AttachDataset;
