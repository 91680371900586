//check if the given text is a tabular format(Markdown)
export const isTabularData = (text: string): boolean => {
  if (!text || typeof text !== 'string') {
    return false;
  }
  const lines = text.split('\n').map((line) => line.trim());
  if (lines.some((line) => line.startsWith('|')) && lines.some((line) => line.match(/^[-| ]+$/))) {
    return true;
  }
  if (text.includes('<table>') && text.includes('<tr>') && text.includes('<td>')) {
    return true;
  }
  if (text.includes('|') && text.includes('-')) {
    return true;
  }
  return false;
};

export const convertTableToHtml = (tableRows: string[]): string => {
  let htmlTable = "<table border='1' style='border-collapse: collapse; width: 100%;'>";
  tableRows.forEach((row, index) => {
    const cols = row
      .split('|')
      .map((col) => col.trim())
      .filter((col) => col !== '');
    if (cols.length > 0) {
      htmlTable += index === 0 ? '<thead><tr>' : '<tr>';
      cols.forEach((col) => {
        htmlTable +=
          index === 0
            ? `<th style='text-align: left; background-color: #f2f2f2; border: 1px solid #000;'>${col}</th>`
            : `<td style='text-align: left;border: 1px solid #000;'>${col}</td>`;
      });
      htmlTable += index === 0 ? '</tr></thead></tbody>' : '</tr>';
    }
  });
  htmlTable += '</tbody></table><br>';
  return htmlTable;
};

//convert Markdown table syntax into HTML table
export const markdownToHtmlTable = (markdown: string): string => {
  const rows = markdown.trim().split('\n');

  let currentTable: string[] = [];
  let formattedText = '';
  let insideTable = false;

  rows.forEach((line) => {
    if (line.includes('|') && !/^\s*$/.test(line)) {
      currentTable.push(line);
      insideTable = true;
    } else {
      if (insideTable) {
        formattedText += convertTableToHtml(currentTable);
        currentTable = [];
        insideTable = false;
      }
      formattedText += `<div>${line}</div>\n`;
    }
  });
  if (insideTable) {
    formattedText += convertTableToHtml(currentTable);
  }
  return formattedText.trim();
};
