import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { Box, Button } from '@mui/material';
import { ownerDataSet, refreshDatasetList, selectedDataSet } from '../../reduxStore/slices/DataSetSlice';
import { useTranslation } from 'react-i18next';
import {
  updateFileSelectionState,
  setSuccessResponseModal,
  updateDatasetState,
} from '../../reduxStore/slices/FileUploadSlice';
import { showUserActionContentOnChat } from '../../reduxStore/slices/NotifyUserActionContentSlice';
import { activeAgentMethod, resetActiveAgent, resetAgentActivationProcess } from '../../reduxStore/slices/AgentsSlice';
import { useContext } from 'react';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import { activeTeamMethod, resetActiveTeam, resetTeamActivationProcess } from '../../reduxStore/slices/TeamsSlice';
import { setMaxRoundsForTeams, setRightPanelOpen } from '../../reduxStore/slices/CommonSlice';
import useResetActiveItems from '../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import Api, { ApiError } from '../../data/api/Api';
import { ListOfExistingDataset } from '../../components/rightPanel/subpanel/dataset/createDataset/DatasetModal';
import { showSnackbarErrorMessage } from '../../components/staticComponents/staticUtiles';

// This component renders a dialog box that asks the user to confirm whether they want to activate a dataset or an agent.
const DialogBox = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { currentDataSetId, currentDataSetName } = useAppSelector((state) => state.dataset.currentDataSet);
  const { agentActivationProcess, agentSharedUsers } = useAppSelector((state) => state.agents);
  const { teamActivationProcess, teamSharedUsers } = useAppSelector((state) => state.teams);
  const { makeActiveDataset } = useAppSelector((state) => state.fileUpload.dataset);
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const { isCustomAppSelected, selectedCustomApp } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { isOpenRightPanel } = useAppSelector((state) => state.commonSlice);
  const resetActiveItems = useResetActiveItems();
  const { addMoreFiles } = useAppSelector((state) => state.dataset);

  const handleYesClick = () => {
    // If the user wants to activate a dataset.
    if (makeActiveDataset) {
      handleDatasetActivationOnYes();
    }
    // If the user wants to activate an agent.
    if (agentActivationProcess.ShowAgentActivationDialogOnChat) {
      handleAgentActivationOnYes();
    }
    // If the user wants to activate team.
    if (teamActivationProcess.ShowTeamActivationDialogOnChat) {
      handleTeamActivationOnYes();
    }
  };

  const handleNoClick = () => {
    // If the user wants to not activate a dataset.
    if (makeActiveDataset) {
      handleDatasetActivationOnNo();
    }
    // If the user wants to not activate an agent.
    if (agentActivationProcess.ShowAgentActivationDialogOnChat) {
      handleAgentActivationOnNo();
    }

    // If the user wants to not activate team.
    if (teamActivationProcess.ShowTeamActivationDialogOnChat) {
      handleTeamActivationOnNo();
    }
  };

  const handleTeamActivationOnYes = async () => {
    await resetActiveItems(t('welcomepage.Teams'));

    // Dispatch an action to update the team activation process state.
    dispatch(resetTeamActivationProcess());

    // Dispatch an action to activate the team.
    dispatch(activeTeamMethod(teamActivationProcess.activationTeam));

    // when making Team active make sure the Agent is inactive
    dispatch(resetActiveAgent());

    // Dispatch an action to show a user action content message on the chat.
    dispatch(
      // Show a message that the team has been activated.
      teamSharedUsers.users.length > 0
        ? showUserActionContentOnChat(
            t('rightPanel.team.activateTeamSharedWithUsers', {
              activeTeamName: teamActivationProcess.activationTeam.name,
              teamSharedUsers: teamSharedUsers.users.map((user) => user.displayName).join(', '),
            })
          )
        : showUserActionContentOnChat(
            t('rightPanel.team.activateTeam', {
              activeTeamName: teamActivationProcess.activationTeam.name,
            })
          )
    );
    setActiveNavItem(t('welcomepage.Teams'));
    dispatch(setMaxRoundsForTeams(teamActivationProcess.activationTeam.assistant_ids.length));
  };

  const handleTeamActivationOnNo = () => {
    // Dispatch an action to update the team activation process state.
    dispatch(resetTeamActivationProcess());
    setActiveNavItem(t('welcomepage.Chat'));
  };

  const handleAgentActivationOnYes = async () => {
    await resetActiveItems(t('welcomepage.Agent'));

    // Dispatch an action to update the agent activation process state.
    dispatch(resetAgentActivationProcess());

    // Dispatch an action to activate the agent.
    dispatch(activeAgentMethod(agentActivationProcess.activationAgent));

    // when making Agent active make sure the Team is inactive.
    dispatch(resetActiveTeam());

    // Dispatch an action to show a user action content message on the chat.
    dispatch(
      // Show a message that the agent has been activated.
      agentSharedUsers.users.length > 0
        ? showUserActionContentOnChat(
            t('rightPanel.agent.activateAgentSharedWithUsers', {
              activeAgentName: agentActivationProcess.activationAgent.name,
              agentSharedUsers: agentSharedUsers.users.map((user) => user.displayName).join(', '),
            })
          )
        : showUserActionContentOnChat(
            t('rightPanel.agent.activateAgent', {
              activeAgentName: agentActivationProcess.activationAgent.name,
            })
          )
    );
    setActiveNavItem(t('welcomepage.Agent'));
  };

  const handleAgentActivationOnNo = () => {
    // Dispatch an action to update the agent activation process state.
    dispatch(resetAgentActivationProcess());
    setActiveNavItem(t('welcomepage.Chat'));
  };

  const handleDatasetActivationOnYes = async () => {
    try {
      dispatch(refreshDatasetList());
      await resetActiveItems(t('welcomepage.Documents'));
      dispatch(
        updateDatasetState({
          makeActiveDataset: false,
        })
      );
      dispatch(
        showUserActionContentOnChat(t('fileUpload.exisitingDatasetactiveMessage', { fileName: currentDataSetName }))
      );
      dispatch(setSuccessResponseModal(false));
      dispatch(
        selectedDataSet({
          activeDataSetId: currentDataSetId,
          activeDataSetName: currentDataSetName,
        })
      );
      if (!addMoreFiles) {
        dispatch(
          showUserActionContentOnChat(t('fileUpload.newDatasetactiveMessage', { fileName: currentDataSetName }))
        );
      }
      setActiveNavItem(t('welcomepage.Documents'));
      if (!isOpenRightPanel) {
        dispatch(setRightPanelOpen(true));
      }
      const datasetDetails = await Api.getDataset(currentDataSetId);
      const datasetData = datasetDetails.data as ListOfExistingDataset;
      const ownersName = await Api.getUserDetailsByIds(datasetData.owners);
      const ownerList = datasetData.owners.map((id) => ownersName[id] || id);
      dispatch(
        ownerDataSet({
          ownersDataList: { [currentDataSetId]: ownerList },
        })
      );
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const handleDatasetActivationOnNo = () => {
    dispatch(refreshDatasetList());
    dispatch(setSuccessResponseModal(false));
    if (!addMoreFiles) {
      dispatch(showUserActionContentOnChat(t('fileUpload.successMessage', { fileName: currentDataSetName })));
    }
    dispatch(
      updateDatasetState({
        makeActiveDataset: false,
      })
    );
    dispatch(updateFileSelectionState({ isDatasetActive: false }));
  };

  // This function returns the content of the dialog box.
  const getActivationDialogContentOnChat = () => {
    return makeActiveDataset && !isCustomAppSelected ? (
      // If the user wants to activate a dataset, return a message asking if they want to activate the dataset.
      // For the Custom Apps this message is not showing as the response is auto generates when dataset is processed.
      <>
        {t('fileUpload.activateDataset')} <strong>{currentDataSetName}</strong>
      </>
    ) : agentActivationProcess.ShowAgentActivationDialogOnChat ? (
      // If the user wants to activate an agent, return a message asking if they want to activate the agent.
      <>
        <strong>&quot;{agentActivationProcess.activationAgent.name}&quot;</strong>{' '}
        {t('rightPanel.agent.createAgent.createAgentSuccess')}
      </>
    ) : teamActivationProcess.ShowTeamActivationDialogOnChat ? (
      // If the user wants to activate team, return a message asking if they want to activate the team.
      <>
        <strong>&quot;{teamActivationProcess.activationTeam.name}&quot;</strong>{' '}
        {t('rightPanel.team.createTeam.createTeamSuccess')}
      </>
    ) : null;
  };

  return (
    <Box marginLeft={'3.5rem'}>
      <span>{getActivationDialogContentOnChat()}</span>
      {!selectedCustomApp && (
        <Box padding={'15px 0px'}>
          <Button
            variant="contained"
            onClick={handleNoClick}
            sx={{
              marginRight: '1rem',
              bgcolor: '#AFB0B4',
              ':hover': {
                bgcolor: '#AFB0B4',
                color: 'white',
              },
            }}
          >
            {t('fileUpload.no')}
          </Button>

          <Button
            variant="contained"
            onClick={handleYesClick}
            sx={{
              bgcolor: '#256AFF',
              ':hover': {
                bgcolor: 'blue',
                color: 'white',
              },
            }}
          >
            {t('fileUpload.yes')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DialogBox;
