import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { chunkText } from '../../components/staticComponents/StaticHtmlGenerator';

export interface DocumentSource {
  source: string;
  content: string;
  [key: string]: string;
}

const DocumentSources: React.FC<{ documentSources: DocumentSource[] }> = ({ documentSources }) => {
  return (
    <Box sx={{ width: '100%', margin: 'auto', paddingBottom: '16px' }}>
      <Accordion
        style={{
          borderRadius: 'initial',
          border: '1px solid #ccc',
          boxShadow: 'none',
          backgroundColor: 'initial',
          color: 'inherit',
          marginTop: '10px',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            color: 'inherit',
            fontWeight: '700',
            userSelect: 'text',
            '&.Mui-expanded': {
              minHeight: '45px',
            },
            '& .MuiAccordionSummary-content': {
              margin: '10px 0px!important',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              marginRight: '10px',
            },
          }}
        >
          Sources
        </AccordionSummary>
        <AccordionDetails sx={{ color: 'inherit' }}>
          {documentSources.map((item, index) => (
            <>
              <Accordion
                key={index}
                style={{ boxShadow: 'none', backgroundColor: 'initial' }}
                sx={{
                  borderRadius: '6px',
                  color: 'inherit',
                  '&::before': {
                    height: '0',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    padding: '0px 12px',
                    color: 'inherit',
                    minHeight: 'unset',
                    '&.Mui-expanded': {
                      minHeight: '45px',
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: '10px 0px',
                    },
                  }}
                >
                  <p style={{ userSelect: 'text', marginBottom: 0, fontWeight: 700 }}>
                    {chunkText} {index}: {item.source}
                  </p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: '0px 12px',
                    color: 'inherit',
                  }}
                >
                  <p style={{ marginBottom: 0 }}>{item.content}</p>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DocumentSources;
