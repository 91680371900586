import React, { useEffect, useRef, useState } from 'react';
import * as assets from '../../assets';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from '../../components/sharedComponents/copyToClipBoard/CopyToClipboard';
import rehypeRaw from 'rehype-raw';
import { lengthOfVisibleAgentName } from '../../components/staticComponents/StaticHtmlGenerator';
import { isTabularData, markdownToHtmlTable } from '../../components/sharedComponents/copyToClipBoard/tableUtils';
import DocumentSources, { DocumentSource } from './DocumentSources';

interface ResponseContentProps {
  appMessage: string;
  modelIcon: string;
  modelTooltip?: string;
  isLoading: boolean;
  isStreamComplete: boolean;
  nameOfRepliedAgent?: string;
  documentSources?: DocumentSource[];
  highlightResponse?: boolean;
}

export const components = {
  code({ node, inline, className, children, ...props }: any) {
    const match = /language-(\w+)/.exec(className || '');
    const codeContent = String(children).replace(/\n$/, '');
    return !inline && match ? (
      <div style={{ position: 'relative' }}>
        <SyntaxHighlighter style={oneLight} language={match[1]} PreTag="div" {...props}>
          {codeContent}
        </SyntaxHighlighter>
        <Box sx={{ position: 'absolute', top: '10px', zIndex: '1', right: '10px' }}>
          <CopyToClipboard textToCopy={codeContent} />
        </Box>
      </div>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },

  //this helps in adding target attribute to generated link so that links can be opened in new tab.
  a({ href, children, ...props }: any) {
    return (
      <a href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  },
};

const ResponseContent: React.FC<ResponseContentProps> = ({
  appMessage,
  isLoading,
  modelIcon,
  modelTooltip,
  isStreamComplete,
  nameOfRepliedAgent,
  documentSources,
}) => {
  const prevMessageRef = useRef('');
  const [responseAnnouncement, setResponseAnnouncement] = useState('');

  const renderAgentNameAndToolTip = (agentName: string) => {
    //show tooltip only if agent name is greater than 15 characters.
    const tooltipClass = agentName.length > lengthOfVisibleAgentName ? 'agent-name-tooltip' : 'hide-tooltip';
    return (
      "<span class='" +
      tooltipClass +
      "'><strong style='white-space: pre-wrap;'>" +
      `${agentName.slice(0, lengthOfVisibleAgentName)}${agentName.length > lengthOfVisibleAgentName ? '... : ' : ' : '}` +
      "</strong><span class='agent-name-tooltip-text'>" +
      agentName +
      '</span> </span>'
    );
  };

  // It is written for accessibility check for the response
  useEffect(() => {
    if (isStreamComplete && appMessage !== prevMessageRef.current) {
      prevMessageRef.current = appMessage;
      setResponseAnnouncement(`Chatbot says ${appMessage}`);
    }
  }, [isStreamComplete, appMessage]);

  return (
    <>
      <Box className="msgBox highlight-background-content">
        <Box className="welcome-icon">
          <img id="changeicon" src={modelIcon} alt="" />
          {modelTooltip && (
            <Box className="toolTip">
              <strong>{modelTooltip}</strong>
            </Box>
          )}
        </Box>
        <Box
          aria-live="assertive"
          aria-hidden="false"
          role="status"
          sx={{
            position: 'absolute',
            opacity: 0,
            pointerEvents: 'none',
          }}
        >
          {responseAnnouncement}
        </Box>
        <Box
          className="chat-content"
          id="current-response-box"
          sx={{ '& p': { marginTop: 0 } }}
          aria-live="polite"
          tabIndex={-1}
        >
          {isLoading ? (
            <img src={assets.loading} id="loadingimg" alt="" />
          ) : (
            <div>
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={components}>
                {nameOfRepliedAgent
                  ? renderAgentNameAndToolTip(nameOfRepliedAgent) +
                    (isTabularData(appMessage) ? '\n' + appMessage : appMessage)
                  : appMessage}
              </ReactMarkdown>
            </div>
          )}

          {isStreamComplete && !isLoading && (
            <Box sx={{ display: 'flex', width: 'fit-content' }}>
              <CopyToClipboard
                textToCopy={isTabularData(appMessage) ? markdownToHtmlTable(appMessage) : appMessage}
                plainTextCopy={appMessage}
                isHtml={isTabularData(appMessage)}
              />
            </Box>
          )}
          {isStreamComplete && !isLoading && documentSources && documentSources.length > 0 && (
            <DocumentSources documentSources={documentSources} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResponseContent;
