import { useRef, useState } from 'react';
import { Input, Box, Typography, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { AppSkillId } from '../../../../../../../components/staticComponents/StaticHtmlGenerator';
import { enqueueSnackbar } from 'notistack';
import Api, { ApiError } from '../../../../../../../data/api/Api';
import { isValidUrl, showSnackbarErrorMessage } from '../../../../../../../components/staticComponents/staticUtiles';
import { useAppDispatch } from '../../../../../../../reduxStore/redux-hooks';
import { updateDatasetState } from '../../../../../../../reduxStore/slices/FileUploadSlice';
import { currentDataSet, refreshDatasetList } from '../../../../../../../reduxStore/slices/DataSetSlice';
import { LinearProgress } from '@mui/material';

interface AttachSharepointDatasetProps {
  attachDataset: (datasetId: string) => void;
}

export default function AttachSharepointDataset({ attachDataset }: AttachSharepointDatasetProps) {
  const [siteUrl, setSiteUrl] = useState('');
  const [workflowUrl, setWorkflowUrl] = useState('');
  const [sharePointDatasetName, setSharePointDatasetName] = useState('');
  const [isDatasetCreating, setIsDatasetCreating] = useState(false);
  const [datasetCreated, setDatasetCreated] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const datasetIdRef = useRef<string>('');

  const createSharePointDocument = async () => {
    if (!isValidUrl(siteUrl)) {
      enqueueSnackbar('Please enter a valid Site URL.', { variant: 'error' });
      return;
    }

    if (!isValidUrl(workflowUrl)) {
      enqueueSnackbar('Please enter a valid Workflow URL.', { variant: 'error' });
      return;
    }
    setIsDatasetCreating(true);
    try {
      const sharepoint_data = { site_url: siteUrl, power_automate_workflow_url: workflowUrl };

      const createDataset = await Api.createDataset(sharePointDatasetName, AppSkillId.sharePoint, sharepoint_data);
      datasetIdRef.current = createDataset.data._id;

      dispatch(updateDatasetState({ datasetName: sharePointDatasetName }));
      dispatch(currentDataSet({ currentDataSetName: sharePointDatasetName, currentDataSetId: datasetIdRef.current }));

      const prepare = await Api.prepareDataset(datasetIdRef.current, AppSkillId.DocCompletion);
      let workflowData = prepare.data;
      while (workflowData.status !== 'Completed') {
        await new Promise((r) => setTimeout(r, 5000));
        const workflow = await Api.getWorkflowForDataset(datasetIdRef.current, workflowData._id);
        if (workflow.data) workflowData = workflow.data;
      }

      if (workflowData.status === 'Completed') {
        dispatch(updateDatasetState({ listOfUserOwnedDataset: [] }));
        dispatch(refreshDatasetList());
        setDatasetCreated(true); // Show dataset details after completion
      }
    } catch (error) {
      console.error('Error in sharePointExecution:', error);
      enqueueSnackbar(t('fileUpload.uploadFileMessage.failureSharepointError'), { variant: 'error' });
    } finally {
      setIsDatasetCreating(false);
      attachDataset(datasetIdRef.current);
    }
  };

  const deleteDataset = async () => {
    try {
      await Api.deleteDataset(datasetIdRef.current);
      setDatasetCreated(false);
      setSiteUrl('');
      setWorkflowUrl('');
      setSharePointDatasetName('');
      datasetIdRef.current = '';
      attachDataset('');
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  return (
    <>
      {!datasetCreated ? (
        <Box>
          <Typography fontSize={16} fontWeight={700} sx={{ marginTop: '1.5rem' }}>
            {t('fileUpload.enterDocumentName')}
          </Typography>
          <Input
            fullWidth
            id="dataset-name"
            sx={{ padding: '0.2rem 0rem', marginBottom: '1rem' }}
            onChange={(event) => setSharePointDatasetName(event.target.value)}
            disabled={isDatasetCreating}
          />

          <Typography fontSize={16} fontWeight={700} sx={{ marginTop: '1rem' }}>
            {t('fileUpload.enterSiteUrl')}
          </Typography>
          <Input
            fullWidth
            id="site-url"
            sx={{ padding: '0.2rem 0rem' }}
            onChange={(event) => setSiteUrl(event.target.value)}
            disabled={isDatasetCreating}
          />

          <Typography fontSize={16} fontWeight={700} sx={{ marginTop: '1rem' }}>
            {t('fileUpload.enterWorkflowURL')}
          </Typography>
          <Input
            fullWidth
            id="workflow-url"
            sx={{ padding: '0.2rem 0rem' }}
            onChange={(event) => setWorkflowUrl(event.target.value)}
            disabled={isDatasetCreating}
          />

          {!isDatasetCreating && (
            <Button
              sx={{
                float: 'right',
                width: '200px',
                margin: '1rem 0',
                textTransform: 'none',
                backgroundColor: '#3C82F6',
              }}
              onClick={createSharePointDocument}
              variant="contained"
              disabled={!siteUrl || !workflowUrl || !sharePointDatasetName}
            >
              {t('fileUpload.createdataset')}
            </Button>
          )}

          {isDatasetCreating && (
            <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
              <LinearProgress />
              <Typography sx={{ fontWeight: 700, marginTop: '0.5rem', color: '#3C82F6' }}>
                {t('fileUpload.datasetCreationMessage')}...
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{ padding: '1rem', borderRadius: '8px', boxShadow: 2, backgroundColor: '#f9f9f9', position: 'relative' }}
        >
          <IconButton onClick={deleteDataset} sx={{ position: 'absolute', top: '10px', right: '10px', color: 'red' }}>
            <DeleteIcon />
          </IconButton>
          <Typography fontSize={18} fontWeight={700} sx={{ marginBottom: '10px' }}>
            {t('fileUpload.datasetDetails')}
          </Typography>
          <Typography fontSize={16} fontWeight={500}>
            <strong>{t('fileUpload.name')}:</strong> {sharePointDatasetName}
          </Typography>
          <Typography fontSize={16} fontWeight={500}>
            <strong>{t('fileUpload.siteUrl')}</strong> {siteUrl}
          </Typography>
          <Typography fontSize={16} fontWeight={500}>
            <strong>{t('fileUpload.workflowURl')}</strong> {workflowUrl}
          </Typography>
        </Box>
      )}
    </>
  );
}
