import BasicPopover from '../../../../sharedComponents/BasicPopover';
import ImageButton from '../../../../sharedComponents/ImageButton';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Api, { ApiError } from '../../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import EditAgent from '../editAgent/EditAgent';
import * as assets from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from '../../dataset/Dataset';
import { chatPageNavigation, hiddenMyAgentsList } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { useNavigate } from 'react-router-dom';
import UsePageNavigation from '../../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import {
  activeAgentMethod,
  refreshAgentsList,
  setSelectedAgentsTabForViewAll,
} from '../../../../../reduxStore/slices/AgentsSlice';
import useResetActiveItems from '../../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';

export interface MyAgentsData {
  name: string;
  _id: string;
  description: string;
  model_name: string;
  temperature: number;
  max_output_tokens: number | null;
  instructions: string;
  tools: string[] | null;
  dataset_id: string | null;
  top_k?: number;
  type: string;
  owners: string[];
  acl?: {
    users: string[];
  };
  allow_all_access?: boolean;
}

export interface MyAgentsDataResponse {
  data: MyAgentsData[];
  response: Response;
}

export const lengthOfVisibleAgentOrTeamName = 25;
export const lengthOfVisibleEditAgentOrTeamName = 20;

const MyAgents = () => {
  const [allAgents, setAllAgents] = useState<MyAgentsData[]>([]);
  const [myAgents, setMyAgents] = useState<MyAgentsData[]>([]);
  const [sharedWithMeAgents, setSharedWithMeAgents] = useState<MyAgentsData[]>([]);
  const [ownedByMeChecked, setOwnedByMeChecked] = useState<boolean>(true);
  const [sharedWithMeChecked, setSharedWithMeChecked] = useState<boolean>(false);
  const [filteredAgentsList, setFilteredAgentsList] = useState<MyAgentsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { activeAgent, refetchAgentsList, selectedAgentsTabForViewAll } = useAppSelector((state) => state.agents);
  const { activeNavItem } = useContext(ActiveNavItemContext);

  const { t } = useTranslation();
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const resetActiveItems = useResetActiveItems();

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    fetchAgentsList();
    if (refetchAgentsList) {
      dispatch(refreshAgentsList(false));
    }
  }, [refetchAgentsList]);

  useEffect(() => {
    modifyAgentsListFromSelectedCheckBox();
  }, [ownedByMeChecked, sharedWithMeChecked, allAgents]);

  useEffect(() => {
    modifyAgentsCheckBox();
  }, [selectedAgentsTabForViewAll]);

  const modifyAgentsCheckBox = () => {
    const agentsCheckBoxesStateMap = {
      [t('quickView.allAgents')]: { ownedByMeChecked: true, sharedWithMeChecked: true },
      [t('rightPanel.agent.myAgents')]: { ownedByMeChecked: true, sharedWithMeChecked: false },
      [t('rightPanel.dataset.sharedWithMe')]: { ownedByMeChecked: false, sharedWithMeChecked: true },
    };

    const { ownedByMeChecked, sharedWithMeChecked } = agentsCheckBoxesStateMap[selectedAgentsTabForViewAll] || {};

    if (ownedByMeChecked !== undefined && sharedWithMeChecked !== undefined) {
      setOwnedByMeChecked(ownedByMeChecked);
      setSharedWithMeChecked(sharedWithMeChecked);
      dispatch(setSelectedAgentsTabForViewAll(''));
    }
  };

  const modifyAgentsListFromSelectedCheckBox = () => {
    // Update the filtered agents list based on the current state
    if (ownedByMeChecked && sharedWithMeChecked) {
      setFilteredAgentsList(allAgents);
    } else if (ownedByMeChecked) {
      setFilteredAgentsList(myAgents);
    } else if (sharedWithMeChecked) {
      setFilteredAgentsList(sharedWithMeAgents);
    } else {
      setFilteredAgentsList([]);
    }
  };

  // Function to fetch the list of agents from the API's
  const fetchAgentsList = async () => {
    try {
      const [allAgentsList, myAgentsList, sharedWithMeAgentsList] = await Promise.all([
        Api.getAgentsList(true, true),
        Api.getAgentsList(true, false),
        Api.getAgentsList(false, true),
      ]);

      // Filter, sort, and slice different agents lists
      const allAgentsListData = filterAndSortAgents(allAgentsList.data as MyAgentsData[]);
      const myAgentsListData = filterAndSortAgents(myAgentsList.data as MyAgentsData[]);
      const sharedWithMeAgentsListData = filterAndSortAgents(sharedWithMeAgentsList.data as MyAgentsData[]);

      // Set the filtered agents
      setAllAgents(allAgentsListData);
      setMyAgents(myAgentsListData);
      setSharedWithMeAgents(sharedWithMeAgentsListData);

      //default agents list will be owned by me agents.
      setFilteredAgentsList(myAgentsListData);

      //if the agent is active and if its data gets updated then make sure
      //to update the active agent data too.
      if (activeAgent._id) {
        dispatch(activeAgentMethod(allAgentsListData.find((agent) => agent._id === activeAgent._id) as MyAgentsData));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error in fetching my agents list:', error);
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  // function to filter, sort, and slice agents list
  const filterAndSortAgents = (agents: MyAgentsData[]) => {
    return agents
      .filter((agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  // Function to set the active agent
  const makeAgentActive = async (agentData: MyAgentsData) => {
    await resetActiveItems(activeNavItem);
    dispatch(activeAgentMethod(agentData));
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.activateAgent', { activeAgentName: agentData.name })));

    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  // Function to filter the list of agents based on the search query
  const filterMyAgentsList = (agentName: string) => {
    setFilteredAgentsList(
      filteredAgentsList.filter((agent) => agent.name.toLowerCase().includes(agentName.toLowerCase()))
    );
    if (agentName === '') {
      modifyAgentsListFromSelectedCheckBox();
    }
  };

  const renderEditAgentPopover = (agent: MyAgentsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditAgent agent={agent} isActiveAgent={false} />}
      />
    );
  };

  const handleAgentSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === t('rightPanel.dataset.ownedByMe')) {
      setOwnedByMeChecked(event.target.checked);
    } else if (value === t('rightPanel.dataset.sharedWithMe')) {
      setSharedWithMeChecked(event.target.checked);
    }
  };

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.agent.myAgents')}
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box>
          {!myAgents.length ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.agent.noCustomAgents')}
            </Typography>
          ) : (
            <>
              <Box>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ownedByMeChecked}
                        onChange={(event) => handleAgentSelectionChange(event, t('rightPanel.dataset.ownedByMe'))}
                      />
                    }
                    label={t('rightPanel.dataset.ownedByMe')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sharedWithMeChecked}
                        onChange={(event) => handleAgentSelectionChange(event, t('rightPanel.dataset.sharedWithMe'))}
                      />
                    }
                    label={t('rightPanel.dataset.sharedWithMe')}
                  />
                </FormGroup>
              </Box>
              <TextField
                label={t('rightPanel.agent.searchAgentName')}
                variant="standard"
                id="searchAgent"
                onChange={(event) => filterMyAgentsList(event.target.value)}
                size="small"
                fullWidth
              />
              <List sx={{ maxHeight: '25rem', overflow: 'auto' }}>
                {filteredAgentsList.map((agent) => (
                  <Box key={agent._id} className="agents-list">
                    <ListItemButton
                      disabled={activeAgent._id === agent._id}
                      sx={{
                        padding: '5px 10px',
                        // paddingLeft: '0px',
                      }}
                    >
                      {/* <ListItemAvatar sx={{ minWidth: '50px' }} onClick={() => makeAgentActive(agent)}>
                        <Avatar alt="default agent" src={assets.ai360Agent} />
                      </ListItemAvatar> */}
                      <Tooltip title={agent.name.length > lengthOfVisibleAgentOrTeamName ? agent.name : null} arrow>
                        <ListItemText
                          primary={agent.name}
                          sx={{
                            padding: '8px 0px',
                            margin: '0px',
                            '& .MuiListItemText-primary': {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            },
                          }}
                          onClick={() => makeAgentActive(agent)}
                        />
                      </Tooltip>
                      {activeAgent._id !== agent._id && (
                        <span className="buttonShowOnHover">{renderEditAgentPopover(agent)}</span>
                      )}
                    </ListItemButton>
                  </Box>
                ))}
                {filteredAgentsList.length === 0 && <Typography color="red">{t('rightPanel.noResult')}</Typography>}
              </List>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MyAgents;
