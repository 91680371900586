import { Box, Input, InputLabel } from '@mui/material';
import { supportedFileTypes } from './DatasetModal';
import { toggleModal, updateDatasetState } from '../../../../../reduxStore/slices/FileUploadSlice';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import { useTranslation } from 'react-i18next';
import {
  setAddMoreFiles,
  setEditDataset,
  setOpenCloseDatasetModal,
} from '../../../../../reduxStore/slices/DataSetSlice';
import { setIsSharePointSelected } from '../../../../../reduxStore/slices/FileUploadSlice';
import { AppSkillId } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { LogButtonEventToGA } from '../../../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';

interface DatasetFileInputProps {
  inputText: string;
  currentDatasetId?: string;
  skillId?: string;
}

const DatasetFileInput: React.FC<DatasetFileInputProps> = ({
  inputText,
  currentDatasetId,
  skillId,
}: DatasetFileInputProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { responseMessage } = useAppSelector((state) => state.fileUpload.fileSelection);
  const { addMoreFiles } = useAppSelector((state) => state.dataset);

  const fileInput = async () => {
    dispatch(setOpenCloseDatasetModal(true));

    if (inputText === t('rightPanel.newDatasets')) {
      LogButtonEventToGA(t('rightPanel.newDatasets'));
      if (addMoreFiles) {
        dispatch(setAddMoreFiles());
      }
      dispatch(toggleModal());
    }
    if (inputText === t('rightPanel.addFiles')) {
      LogButtonEventToGA(t('rightPanel.addFiles'));
      if (!addMoreFiles) {
        dispatch(setAddMoreFiles());
      }
    }
    if (!currentDatasetId) return;
    if (skillId === AppSkillId.sharePoint) {
      dispatch(setIsSharePointSelected(AppSkillId.sharePoint));
    } else {
      dispatch(setIsSharePointSelected(''));
    }
    dispatch(
      setEditDataset({
        addFiles: true,
        datasetId: currentDatasetId,
      })
    );
    dispatch(
      updateDatasetState({
        selectedDatasetId: currentDatasetId,
      })
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: responseMessage ? '0.4' : '',
      }}
    >
      <Input
        id={`${inputText}-file-upload`}
        type="button"
        sx={{
          position: 'absolute',
          opacity: '0',
          pointerEvents: 'auto',
        }}
        inputProps={{
          multiple: true,
          accept: supportedFileTypes,
        }}
        onClick={fileInput}
        disabled={responseMessage}
      />
      <InputLabel
        sx={{
          padding: 0,
          color: '#256AFF',
          textDecoration: 'underline',
          cursor: responseMessage ? 'not-allowed' : 'pointer',
        }}
        htmlFor={`${inputText}-file-upload`}
      >
        {inputText}
      </InputLabel>
    </Box>
  );
};

export default DatasetFileInput;
