import { ReactNode } from 'react';
import CopyToClipboard from '../../../../components/sharedComponents/copyToClipBoard/CopyToClipboard';
import { AppSkillId } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { SowCommitAiQueryResponse } from '../../../../reduxStore/slices/CustomAppsSlice';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableCellProps } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface SowCommitAIResponse {
  row: {
    title: string;
    GenAI: string;
  };
}

type SOWPredictorResponse = {
  [key: string]: [string, string];
};

interface AdvancedSummaryResponse {
  results: {
    doc_summary: string;
    adv_summary: string;
  };
}

//Reusable styled table cell for headers
const HeaderCell: React.FC<
  {
    children: ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => {
  return (
    <TableCell sx={{ fontWeight: '700', border: '1px solid black' }} {...props}>
      {children}
    </TableCell>
  );
};

//Reusable styled table cell for normal cells
const BodyCell: React.FC<
  {
    children: ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => {
  return (
    <TableCell sx={{ border: '1px solid black' }} {...props}>
      {children}
    </TableCell>
  );
};

//TableData gives array of data items to render
//renderItem function to render each item as a string
const generateContent = <TableData,>(
  data: TableData[],
  renderItem: (item: TableData, index: number) => string
): string => {
  return data.map((item, index) => renderItem(item, index)).join('\n');
};

//Render plain text and HTML text for microsoft teams in SOWCommit
const sowCommitAIRenderes = {
  plainText: (item: SowCommitAIResponse, index: number): string => `${index + 1}. ${item.row.title}: ${item.row.GenAI}`,

  html: (item: SowCommitAIResponse, index: number): string => `
   <tr>
    <td>${index + 1}</td>
    <td>${item.row.title}</td>
    <td>${item.row.GenAI}</td>
   </tr>
  `,
};

//Render plain text and HTML text for microsoft teams in SOWPredictor
const sowPredictorRenderers = {
  plainText: (item: [string, string], index: number): string => `${index + 1}. ${item[0]}: ${item[1]}`,

  html: (item: [string, string], index: number): string => `
   <tr>
    <td>${item[0]}</td>
    <td>${item[1]}</td>
   </tr>
  `,
};

//Render plain text and HTML text for microsoft teams in Advance Summary
const advanceSummaryRenderers = {
  plainText: (response: AdvancedSummaryResponse): string => `
   Default Summary: 
   ${response.results.doc_summary}

   Advanced Summary:
   ${response.results.adv_summary}
  `,

  html: (response: AdvancedSummaryResponse): string => `
    <table style="border-collapse: collapse; width: 100%;">
    <thead>
    <tr>
     <th style="font-weight: 700; text-align: left;">Default Summary</th>
     <th style="font-weight: 700; text-align: left;">Advanced Summary</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td style="vertical-align: top;">${response.results.doc_summary}</td>
      <td style="vertical-align: top;">${response.results.adv_summary}</td>
    </tr>
    </tbody>
    </table>
  `,
};

export function SowResponseTable({
  sowQueryResponse,
  selectedCustomAppSkills,
}: {
  sowQueryResponse: any;
  selectedCustomAppSkills: string;
}) {
  return (
    <>
      {(selectedCustomAppSkills === AppSkillId.SOWCommitAI || selectedCustomAppSkills === AppSkillId.SOWFinAI) && (
        <>
          <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
            <TableHead>
              <TableRow>
                <HeaderCell>Sl. No.</HeaderCell>
                <HeaderCell>Check Commitment</HeaderCell>
                <HeaderCell>Available Commitments</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sowQueryResponse.map((sowResponseList: SowCommitAiQueryResponse, index: number) => (
                <TableRow key={index}>
                  <BodyCell>{index}</BodyCell>
                  <BodyCell>{sowResponseList.row.title}</BodyCell>
                  <BodyCell>{sowResponseList.row.GenAI}</BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <CopyToClipboard
              textToCopy={`
                 <table style="border-collapse: collapse; width: 100%;">
                 <thead>
                 <tr>
                  <th style="font-weight: 700; text-align: left;">SI. No.</th>
                  <th style="font-weight: 700; text-align: left;">Check Commitment</th>
                  <th style="font-weight: 700; text-align: left;">Available Commitment</th>
                 </tr>
                 </thead>
                 <tbody>
                   ${generateContent(sowQueryResponse, sowCommitAIRenderes.html)}
                  </tbody>
                  </table>
                `}
              plainTextCopy={generateContent(sowQueryResponse, sowCommitAIRenderes.plainText)}
              isHtml={true}
            />
          </Box>
        </>
      )}

      {selectedCustomAppSkills === AppSkillId.SOWPredictor && (
        <>
          <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
            <TableHead>
              <TableRow>
                <HeaderCell>Attribute</HeaderCell>
                <HeaderCell>Values</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(sowQueryResponse).map((item: any, index: number) => (
                <TableRow key={index}>
                  <BodyCell>{item[0]}</BodyCell>
                  <BodyCell>{item[1]}</BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <CopyToClipboard
              textToCopy={`
                 <table style="border-collapse: collapse; width: 100%;">
                 <thead>
                 <tr>
                  <th style="font-weight: 700; text-align: left;">Attribute</th>
                  <th style="font-weight: 700; text-align: left;">Value</th>
                 </tr>
                 </thead>
                 <tbody>
                    ${generateContent(Object.values(sowQueryResponse as SOWPredictorResponse), sowPredictorRenderers.html)}
                  </tbody>
                  </table>
                `}
              plainTextCopy={generateContent(
                Object.values(sowQueryResponse as SOWPredictorResponse),
                sowPredictorRenderers.plainText
              )}
              isHtml={true}
            />
          </Box>
        </>
      )}

      {selectedCustomAppSkills === AppSkillId.AdvancedSummary && sowQueryResponse.results && (
        <>
          <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
            <TableHead>
              <TableRow>
                <HeaderCell>Default Summary</HeaderCell>
                <HeaderCell>Advanced Summary</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <BodyCell
                  sx={{ width: '53%', verticalAlign: 'text-top', textAlign: 'justify', border: '1px solid black' }}
                >
                  <Markdown remarkPlugins={[remarkGfm]}>{sowQueryResponse.results.doc_summary}</Markdown>
                </BodyCell>
                <BodyCell
                  sx={{ width: '53%', verticalAlign: 'text-top', textAlign: 'justify', border: '1px solid black' }}
                >
                  <Markdown remarkPlugins={[remarkGfm]}>{sowQueryResponse.results.adv_summary}</Markdown>
                </BodyCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <CopyToClipboard
              textToCopy={advanceSummaryRenderers.html(sowQueryResponse)}
              plainTextCopy={advanceSummaryRenderers.plainText(sowQueryResponse)}
              isHtml={true}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default SowResponseTable;
