import * as assets from '../../assets';
import Input from '@mui/material/Input';
import { Box, useTheme } from '@mui/material';
import ImageButton from '../../components/sharedComponents/ImageButton';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
// import { setFiles, toggleModal } from '../../reduxStore/slices/FileUploadSlice';
import DatasetModal, {
  supportedFileTypes,
} from '../../components/rightPanel/subpanel/dataset/createDataset/DatasetModal';
// import { findEmptyFileName } from 'components/staticComponents/staticUtiles';
// import { enqueueSnackbar } from 'notistack';
// import { useTranslation } from 'react-i18next';
import { setOpenCloseDatasetModal } from '../../reduxStore/slices/DataSetSlice';

interface fileUploadProps {
  disabled: boolean;
}

const FileUpload = ({ disabled }: fileUploadProps) => {
  const dispatch = useAppDispatch();
  // const { t } = useTranslation();
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const theme = useTheme();

  const { openCloseDatasetModal } = useAppSelector((state) => state.dataset);
  // const fileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (!files && files!.length === 0) return;
  //   if (findEmptyFileName(files!)) {
  //     enqueueSnackbar(t('fileUpload.fileIsEmpty', { fileName: findEmptyFileName(files!) }), {
  //       variant: 'error',
  //     });
  //     return;
  //   }
  //   dispatch(setFiles(Array.from(files as FileList)));
  //   dispatch(toggleModal());
  //   e.target.value = '';
  // };
  const openModal = () => {
    dispatch(setOpenCloseDatasetModal(true));
  };
  const closeModal = () => {
    dispatch(setOpenCloseDatasetModal(false));
  };
  return (
    <>
      <Box
        component="div"
        sx={{
          [theme.breakpoints.down('sm')]: {
            position: 'static',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: fileUpload.uploadedFiles.length > 0 ? '-13px' : '11px',
          }}
        >
          <Input
            id="file-upload"
            type="button"
            sx={{
              opacity: '0',
              zIndex: '99',
              left: fileUpload.uploadedFiles.length > 0 ? '10px' : '-10px',
              width: '1.86rem',
              pointerEvents: disabled ? 'none' : 'auto',
            }}
            inputProps={{
              multiple: true,
              accept: supportedFileTypes,
            }}
            onClick={openModal}
          />
          <label htmlFor="file-upload" className="custom-file-upload" style={{ position: 'absolute', left: '0px' }}>
            <ImageButton disabled={disabled} srcFile={assets.attachFile} />
          </label>
        </Box>
      </Box>
      {openCloseDatasetModal && <DatasetModal open={openCloseDatasetModal} onClose={closeModal} />}
    </>
  );
};

export default FileUpload;
