import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { ActiveNavItemContext } from 'components/rightPanel/RightPanelContext';
import '../../../../css/Modal.css';

export default function HistoryInfoText() {
  const { t } = useTranslation();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  return (
    <Box className="modal fade">
      <Box className="modal-md">
        <Box className="modal-content borderStyle">
          <Box className="modal-header">
            <Typography variant="h5" className="modal-title">
              {t('history.infoIcon.title')}
            </Typography>
          </Box>
          <Box className="modal-body scroll-spy">
            <Box className="historyterms">
              <Typography>{t('history.infoIcon.aboutHistoryFeature')}</Typography>
              <br />
              <Typography variant="h6">{t('history.infoIcon.youNeedToKnow')}</Typography>
              <br />
              <Typography>
                <strong>{t('history.infoIcon.currentFunctionality')}</strong>
              </Typography>
              <br />
              <Typography>
                <strong>{t('history.infoIcon.unifiedHistory')}</strong> {t('history.infoIcon.unifiedHistoryText')}
              </Typography>
              <br />
              <Typography>
                <strong>{t('history.infoIcon.clearIdentification')}</strong> {t('history.infoIcon.conversationTypes')}
              </Typography>
              <Box component={'ul'}>
                <Box component={'li'}>{t('history.infoIcon.ConversationForChatAI')}</Box>
                <Box component={'li'}>{t('history.infoIcon.ConversationForDocAI')}</Box>
                <Box component={'li'}>{t('history.infoIcon.ConversationForAgent')}</Box>
                <Box component={'li'}>{t('history.infoIcon.ConversationForTeam')}</Box>
              </Box>
              <Typography variant="h6">{t('history.infoIcon.guidelines')}</Typography>
              <br />
              <Typography>{t('history.infoIcon.guidelinesText')}</Typography>
              <br />
              <Typography>
                <strong>{t('history.infoIcon.waitForResponse')}</strong> {t('history.infoIcon.waitForResponseText')}
              </Typography>
              <br />
              <Typography>
                <strong>{t('history.infoIcon.interruptingResponses')}</strong>
                {t('history.infoIcon.interruptingResponsesText')}
              </Typography>
              <br />
              <Typography>{t('history.infoIcon.feedbacktext')}</Typography>
            </Box>
          </Box>
          <Box className="modal-footer d-flex justify-content-between">
            <Box></Box>
            <Button onClick={() => setActiveNavItem(null)}>{t('history.infoIcon.closeButton')}</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
