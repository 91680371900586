import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import {
  updateFileUploadState,
  updateFileSelectionState,
  updateDatasetState,
} from '../../../../../reduxStore/slices/FileUploadSlice';
import { Radio, RadioGroup, FormControlLabel, FormControl, SelectChangeEvent } from '@mui/material';

interface ExistingDatasetRadioSelectProps {
  existingDatasetRadioSelect: string;
  setExistingDatasetRadioSelect: (radioSelectMessage: string) => void;
  siteUrl: string;
  isExistingDatasetHasData: boolean;
}

const ExistingDatasetRadioSelect: React.FC<ExistingDatasetRadioSelectProps> = ({
  existingDatasetRadioSelect,
  setExistingDatasetRadioSelect,
  siteUrl,
  isExistingDatasetHasData,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDatasetSelect = (event: SelectChangeEvent<HTMLInputElement>) => {
    const selectedRadioButtonValue = (event.target as HTMLInputElement).value;
    setExistingDatasetRadioSelect(selectedRadioButtonValue);
    if (
      selectedRadioButtonValue === t('fileUpload.existingSharePointDataset') ||
      selectedRadioButtonValue === t('fileUpload.existingDataset')
    ) {
      dispatch(
        updateFileSelectionState({
          isDatasetButtonDisabled: false,
        })
      );
    } else {
      dispatch(
        updateFileUploadState({
          listOfFilesInSelectedDataset: [],
          listOfFilteredUploadedFilesExistInDataset: [],
        })
      );

      dispatch(
        updateDatasetState({
          selectedDatasetId: '',
        })
      );

      dispatch(
        updateFileSelectionState({
          isReplaceFileCheckboxChecked: false,
          isDatasetButtonDisabled: true,
        })
      );
    }
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="existing-dataset-row-radio-buttons-group-label"
        name={t('fileUpload.radioGroup')}
        defaultValue={existingDatasetRadioSelect}
        sx={{ display: 'flex', gap: '2rem' }}
        onChange={handleDatasetSelect}
      >
        <FormControlLabel
          value={t('fileUpload.existingDataset')}
          control={<Radio />}
          label={t('fileUpload.existingDataset')}
          disabled={siteUrl !== ''}
        />
        <FormControlLabel
          value={t('fileUpload.existingSharePointDataset')}
          control={<Radio />}
          label={t('fileUpload.existingSharePointDataset')}
          disabled={isExistingDatasetHasData}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default ExistingDatasetRadioSelect;
