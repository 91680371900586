import {
  Box,
  Chip,
  Divider,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import ModelSelect from './ModelSelect';
import ImageButton from '../../components/sharedComponents/ImageButton';
import { LlmModel } from '../../data/models/LlmModel';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import * as assets from '../../assets';
import SendChat from './SendChat';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import {
  customAppsWithSpecificSkills,
  customAppsJsonNames,
} from '../../components/staticComponents/StaticHtmlGenerator';
import NumberInput from '../../components/sharedComponents/numberInput/NumberInput';
import { setMaxRoundsForTeams } from '../../reduxStore/slices/CommonSlice';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import SettingsModal from './SettingsModal';
import ImageUpload from './ImageUpload';
import { setImageUploadData } from '../../reduxStore/slices/FileUploadSlice';

interface QueryInputActionsProps {
  disabled: boolean;
  maxLength: number;
  currentMessage: string;
  selectedModel: LlmModel;
  llmModels: LlmModel[];
  resetsTranscript: boolean;
  handleModelChange: (event: SelectChangeEvent) => void;
  setCurrentMessage: (transcript: string) => void;
  __ask: () => Promise<void>;
  setResetsTranscript: (value: boolean) => void;
}

const QueryInputActions: React.FC<QueryInputActionsProps> = ({
  disabled,
  maxLength,
  currentMessage,
  selectedModel,
  llmModels,
  resetsTranscript,
  handleModelChange,
  setCurrentMessage,
  __ask,
  setResetsTranscript,
}) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [micEnabled, setMicEnabled] = useState(false);
  const [resetTranscripts, setResetTranscripts] = useState(false);
  const [usableTranscript, setUsableTranscript] = useState('');
  const { selectedCustomApp } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { responseMessage } = useAppSelector((state) => state.fileUpload.fileSelection);
  const { activeAgent } = useAppSelector((state) => state.agents);
  const { activeTeam } = useAppSelector((state) => state.teams);
  const { choosenCustomApp } = useAppSelector((state) => state.customApps);
  const { maxRoundsForTeams } = useAppSelector((state) => state.commonSlice);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMicAndAskDisabled = customAppsWithSpecificSkills.includes(selectedCustomApp);
  const { imageUploadedData } = useAppSelector((state) => state.fileUpload);
  const isAttachFileDisabled =
    customAppsJsonNames.includes(selectedCustomApp) || !!(activeAgent._id || activeTeam._id) || responseMessage;

  const { control } = useForm({
    defaultValues: {
      temperature: 0,
    },
  });
  const { isResponseBeingGenerated } = useAppSelector((state) => state.commonSlice);

  useEffect(() => {
    if (transcript.length && resetTranscripts) {
      setResetsTranscript(false);
      setResetTranscripts(false);
      checkInputUpdated();
      setCurrentMessage(currentMessage + (transcript.length ? (currentMessage.length ? ' ' : '') + transcript : ''));
    }
    if (transcript.length && !resetTranscripts) {
      // usual case when the input is not chnaged by the user
      const str = usableTranscript + transcript;
      setCurrentMessage(str);
    }
  }, [transcript]);

  useEffect(() => {
    if (resetsTranscript) {
      resetTranscript();
      setResetTranscripts(true);
      setUsableTranscript(currentMessage);
    }
  }, [resetsTranscript]);

  const checkInputUpdated = () => {
    if (currentMessage !== usableTranscript) {
      setUsableTranscript(currentMessage);
    }
  };

  const startStopListening = () => {
    if (!micEnabled) {
      setMicEnabled(true);
      SpeechRecognition.startListening({ continuous: true });
    } else {
      setMicEnabled(false);
      stopVoiceInput();
      resetTranscript();
      setResetTranscripts(false);
    }
  };

  const stopVoiceInput = () => {
    if (currentMessage && currentMessage.length) {
      setCurrentMessage(transcript);
    } else {
      setCurrentMessage(transcript.length ? transcript : '');
    }
    SpeechRecognition.stopListening();
  };

  const queryInputActionsTooltipStyles = {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000000BA',
      },
      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
        '&::before': {
          backgroundColor: '#000000BA',
        },
      },
    },
  };

  const handleRemoveUrlAndImage = (itemToRemove: string, isUrl: boolean) => {
    dispatch(
      setImageUploadData({
        imageURLList: isUrl
          ? imageUploadedData.imageURLList.filter((url) => url !== itemToRemove)
          : imageUploadedData.imageURLList.filter(() => !imageUploadedData.imagesNames.includes(itemToRemove)),
        imagesNames: isUrl ? [] : imageUploadedData.imagesNames.filter((name) => name !== itemToRemove),
      })
    );
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box
        className="query-input-actions"
        sx={{
          padding: isSmallScreen ? '0 7px 0 0' : '0',
          display: isSmallScreen ? 'block' : 'flex',
          width: '100%',
        }}
      >
        {activeTeam?._id && (
          <>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Box>
                <InputLabel sx={{ color: '#000' }}>{t('chatInterface.maximumRounds')}</InputLabel>
              </Box>
              <Box>
                <NumberInput
                  min={2}
                  max={20}
                  value={maxRoundsForTeams}
                  onChange={(event, newValue) => dispatch(setMaxRoundsForTeams(newValue ? newValue : 256))}
                />
              </Box>
            </Box>
          </>
        )}

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', maxWidth: '100%' }}>
          {(imageUploadedData.imagesNames.length > 0
            ? imageUploadedData.imagesNames
            : imageUploadedData.imageURLList
          ).map((imageName) => (
            <Chip
              key={imageName}
              label={imageName.length > 35 ? `${imageName.slice(0, 35)}...` : imageName}
              onDelete={() => handleRemoveUrlAndImage(imageName, imageUploadedData.imageURLList.includes(imageName))}
              deleteIcon={<img src={assets.cancelIcon} alt="delete" />}
              sx={{ display: 'flex', alignItems: 'center' }}
            />
          ))}
        </Box>

        <Box
          className="query-input-actions"
          sx={{
            padding: isSmallScreen ? '0.5rem 0rem 0.5rem 0rem' : '0.5rem 1.25rem 0.5rem 0rem',
            display: isSmallScreen ? 'block' : 'flex',
          }}
        >
          {!disabled && (
            <Box>
              <span data-testid="character-count">
                {currentMessage.length} / {maxLength}
              </span>
            </Box>
          )}

          <Box>
            <ModelSelect handleModelChange={handleModelChange} selectedModel={selectedModel} llmModels={llmModels} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: isSmallScreen ? '7px' : '0',
              minWidth: '150px',
            }}
          >
            <Tooltip
              title="Upload file"
              enterDelay={1000}
              arrow
              placement="top"
              slotProps={{
                popper: queryInputActionsTooltipStyles,
              }}
            >
              <Box className="query-input-action-item">
                <FileUpload disabled={!disabled || isAttachFileDisabled} />
              </Box>
            </Tooltip>
            <Tooltip
              title="Upload image"
              enterDelay={1000}
              arrow
              placement="top"
              slotProps={{
                popper: queryInputActionsTooltipStyles,
              }}
            >
              <Box className="query-input-action-item">
                <ImageUpload disabled={false} />
              </Box>
            </Tooltip>
            {!activeTeam?._id && !activeAgent?._id && !choosenCustomApp?.isCustomAppSelected && (
              <SettingsModal control={control} />
            )}

            <Tooltip
              title="Speak"
              enterDelay={1000}
              arrow
              placement="top"
              slotProps={{
                popper: queryInputActionsTooltipStyles,
              }}
            >
              <Box
                className={
                  transcript
                    ? micEnabled
                      ? 'beat query-input-action-item'
                      : 'query-input-action-item'
                    : 'query-input-action-item'
                }
              >
                <Box
                  sx={{
                    position: 'absolute',
                    left: micEnabled ? (transcript ? '-3px' : '-3px') : '8px',
                  }}
                >
                  <ImageButton
                    srcFile={
                      micEnabled ? (transcript ? assets.speakerListening : assets.speakerActive) : assets.speaker
                    }
                    className="speak"
                    disabled={isMicAndAskDisabled || isResponseBeingGenerated}
                    handleClick={() => {
                      startStopListening();
                    }}
                    ariaLabel="speak"
                    tabIndex={isMicAndAskDisabled ? -1 : 0}
                  />
                </Box>
              </Box>
            </Tooltip>

            <Divider
              sx={{ marginRight: '1px', marginLeft: '-5px', borderLeft: '1px solid #DBDBDB' }}
              orientation="vertical"
              flexItem
            />
            <Tooltip
              title="Send"
              enterDelay={1000}
              arrow
              placement="top"
              slotProps={{
                popper: queryInputActionsTooltipStyles,
              }}
            >
              <Box className="query-input-action-item">
                <SendChat disabled={disabled || isMicAndAskDisabled} onSendChatPressed={__ask} aria-label="send" />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default QueryInputActions;
