import { Input, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SharePointDatasetProps {
  setSiteUrl: (url: string) => void;
  setWorkflowUrl: (url: string) => void;
  setSharePointDatasetName: (name: string) => void;
}

export default function SharePointDataset({
  setSiteUrl,
  setWorkflowUrl,
  setSharePointDatasetName,
}: SharePointDatasetProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography fontSize={16} fontWeight={700}>
        {t('fileUpload.enterDocumentName')}
      </Typography>
      <Input
        fullWidth
        id="site-url"
        sx={{ padding: '0.2rem 0rem', marginBottom: '1rem' }}
        onChange={(e) => {
          setSharePointDatasetName(e.target.value);
        }}
      />
      <Typography fontSize={16} fontWeight={700}>
        {t('fileUpload.enterSiteUrl')}
      </Typography>
      <Input
        fullWidth
        id="site-url"
        sx={{ padding: '0.2rem 0rem' }}
        onChange={(e) => {
          setSiteUrl(e.target.value);
        }}
      />

      <Typography fontSize={16} fontWeight={700} sx={{ marginTop: '1rem' }}>
        {t('fileUpload.enterWorkflowURL')}
      </Typography>
      <Input
        fullWidth
        id="workflow-url"
        sx={{ padding: '0.2rem 0rem' }}
        onChange={(e) => {
          setWorkflowUrl(e.target.value);
        }}
      />
    </Box>
  );
}
