import { useEffect } from 'react';
import useResetActiveItems from '../useResetActiveItems/useResetActiveItem';
import { useTranslation } from 'react-i18next';
import { welcomePageNavigation } from '../../../../components/staticComponents/StaticHtmlGenerator';

function UseBrowserBackNavigation() {
  const { t } = useTranslation();
  const resetActiveItems = useResetActiveItems();

  useEffect(() => {
    const handlePopState = () => {
      if (window.location.pathname.toLowerCase() === welcomePageNavigation.toLowerCase()) {
        resetActiveItems(t('welcomepage.Home'));
      }
    };
    // Listen for location changes happened through browser navigation.
    window.addEventListener('popstate', handlePopState);
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [resetActiveItems, t]);
}

export default UseBrowserBackNavigation;
