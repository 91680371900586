import { datasetSharedUser } from '../../components/rightPanel/subpanel/dataset/myDatasets/MyDatasets';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedDataSet {
  activeDataSetName: string;
  activeDataSetId: string;
}
interface currentDataSet {
  currentDataSetName: string;
  currentDataSetId: string;
}

interface ownerDataSet {
  ownersDataList: { [datasetId: string]: string[] };
}
interface sharedDataSet {
  sharedDataList: { [datasetId: string]: datasetSharedUser[] };
}

interface EditDataset {
  addFiles: boolean;
  datasetId: string;
}

interface DataSetState {
  selectedDataSet: SelectedDataSet;
  currentDataSet: currentDataSet;
  ownerDataSet: ownerDataSet;
  sharedDataSet: sharedDataSet;
  ownerEmail: string;
  editDataset: EditDataset;
  refreshDatasetList: boolean;
  addMoreFiles: boolean;
  selectedDocumentsTabForViewAll: string;
  openCloseDatasetModal: boolean;
}

const initialState: DataSetState = {
  selectedDataSet: {
    activeDataSetName: '',
    activeDataSetId: '',
  },
  currentDataSet: {
    currentDataSetName: '',
    currentDataSetId: '',
  },
  ownerDataSet: {
    ownersDataList: {},
  },
  sharedDataSet: {
    sharedDataList: {},
  },
  editDataset: {
    addFiles: false,
    datasetId: '',
  },
  ownerEmail: '',
  addMoreFiles: false,
  refreshDatasetList: false,
  selectedDocumentsTabForViewAll: '',
  openCloseDatasetModal: false,
};

export const dataSetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    selectedDataSet: (state, action: PayloadAction<SelectedDataSet>) => {
      state.selectedDataSet = action.payload;
    },
    currentDataSet: (state, action: PayloadAction<currentDataSet>) => {
      state.currentDataSet = action.payload;
    },
    ownerDataSet: (state, action: PayloadAction<ownerDataSet>) => {
      state.ownerDataSet = action.payload;
    },
    sharedDataSet: (state, action: PayloadAction<sharedDataSet>) => {
      state.sharedDataSet = action.payload;
    },
    refreshDatasetList: (state) => {
      state.refreshDatasetList = !state.refreshDatasetList;
    },
    setEditDataset: (state, action: PayloadAction<EditDataset>) => {
      state.editDataset = action.payload;
    },
    setAddMoreFiles: (state) => {
      state.addMoreFiles = !state.addMoreFiles;
    },
    setOwnerEmail: (state, action: PayloadAction<string>) => {
      state.ownerEmail = action.payload;
    },
    setSelectedDocumentsTabForViewAll: (state, action: PayloadAction<string>) => {
      state.selectedDocumentsTabForViewAll = action.payload;
    },
    setOpenCloseDatasetModal: (state, action: PayloadAction<boolean>) => {
      state.openCloseDatasetModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectedDataSet,
  currentDataSet,
  ownerDataSet,
  sharedDataSet,
  refreshDatasetList,
  setEditDataset,
  setAddMoreFiles,
  setOwnerEmail,
  setSelectedDocumentsTabForViewAll,
  setOpenCloseDatasetModal,
} = dataSetSlice.actions;

export default dataSetSlice.reducer;
