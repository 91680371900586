import { Box, Drawer, useMediaQuery, Slide } from '@mui/material';
import React, { useContext } from 'react';
import { NavItemButton } from './navItemButton/NavItemButton';
import { SubPanel } from '../subpanel/SubPanel';
import { useTranslation } from 'react-i18next';
import {
  chatPageNavigation,
  NAV_PANEL_WIDTH,
  NavItemImages,
  welcomePageNavigation,
} from '../../staticComponents/StaticHtmlGenerator';
import Logout from '../subpanel/logout/Logout';
import { ActiveNavItemContext } from '../RightPanelContext';
import UserAvatar from './userAvatar/UserAvatar';
import { UseTrackedNavigate } from '../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { LogTabEventToGA } from '../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';
import useResetActiveItems from '../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { useAppSelector } from '../../../reduxStore/redux-hooks';
import { getTenantName } from '../../../MsalConfig';
import { TenantNames } from '../../staticComponents/StaticHtmlGenerator';

export function RightNavPanel({ userFullName, initials }: { userFullName: string; initials: string }) {
  const portrait = useMediaQuery('(orientation: portrait)');
  const { t } = useTranslation();
  const navigate = UseTrackedNavigate();
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const resetActiveItems = useResetActiveItems();
  const { isUserHasCustomAppAccess } = useAppSelector((state) => state.commonSlice);
  const tenantName = getTenantName();
  const { activeDataSetName } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { activeAgent } = useAppSelector((state) => state.agents);
  const { activeTeam } = useAppSelector((state) => state.teams);
  const { isCustomAppSelected } = useAppSelector((state) => state.customApps.choosenCustomApp);

  // Configuration for the nav items
  const navItemsConfig = [
    { name: t('welcomepage.Home'), imageUrl: NavItemImages.home, visible: true },
    { name: t('welcomepage.Chat'), imageUrl: NavItemImages.chat, visible: false },
    { name: t('welcomepage.Documents'), imageUrl: NavItemImages.dataset, visible: true },
    { name: t('welcomepage.Agent'), imageUrl: NavItemImages.agents, visible: tenantName !== TenantNames.StateStreet },
    { name: t('welcomepage.Teams'), imageUrl: NavItemImages.teams, visible: tenantName !== TenantNames.StateStreet },
    {
      name: t('welcomepage.Apps'),
      imageUrl: NavItemImages.apps,
      visible: isUserHasCustomAppAccess && tenantName !== TenantNames.StateStreet,
    },
    { name: t('welcomepage.History'), imageUrl: NavItemImages.history, visible: true },
    { name: t('welcomepage.Bell'), imageUrl: NavItemImages.bell, visible: false, badge: true },
  ];

  const clickOnNavButton = (name: string) => {
    setActiveNavItem(name);
    LogTabEventToGA(name);
    if (name === t('welcomepage.Home')) {
      navigate(welcomePageNavigation);
      resetActiveItems(name);
    }
    if (name === t('welcomepage.Chat')) {
      navigate(chatPageNavigation);
    }
  };

  const shouldRenderSubPanel = (activeNavItem: string | null) => {
    if (!activeNavItem) return false; // Handles null and empty string cases
    const excludedNavItems = [t('welcomepage.Home'), t('welcomepage.Chat')];
    return !excludedNavItems.includes(activeNavItem);
  };

  const isActiveNavItem = (name: string) => {
    return (
      activeNavItem === name ||
      (name === t('welcomepage.Agent') && activeAgent._id && activeNavItem === null) ||
      (name === t('welcomepage.Teams') && activeTeam._id && activeNavItem === null) ||
      (name === t('welcomepage.Documents') && activeDataSetName && activeNavItem === null) ||
      (name === t('welcomepage.Apps') && isCustomAppSelected && activeNavItem === null)
    );
  };

  const loadNavItems = () => {
    return (
      <>
        {navItemsConfig.map(({ name, imageUrl, visible, badge }, index) => {
          if (visible) {
            return (
              <Box key={index}>
                <NavItemButton
                  key={index}
                  name={name}
                  imageUrl={imageUrl}
                  badge={badge}
                  isActive={!!isActiveNavItem(name)}
                  clickOnNavButton={clickOnNavButton}
                />
              </Box>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <Box>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Drawer
          id={'right-nav-panel'}
          PaperProps={{
            sx: {
              backgroundColor: '#FBFAFA',
              border: '1px solid #F2F2F2',
              width: NAV_PANEL_WIDTH,
              height: '100%',
              marginTop: 0,
            },
          }}
          anchor={'right'}
          variant={'permanent'}
          open={true}
        >
          <Box
            sx={{
              padding: '14px 0 30px 0',
            }}
          >
            <Box
              onClick={tenantName !== TenantNames.StateStreet ? () => clickOnNavButton('developerKey') : undefined}
              sx={{ cursor: tenantName !== TenantNames.StateStreet ? 'pointer' : 'default' }}
            >
              <UserAvatar initials={initials} />
            </Box>

            {loadNavItems()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'flex-end',
              marginBottom: {
                xs: portrait ? '64px' : '0',
                md: '0',
              },
            }}
          >
            <Logout />
          </Box>
        </Drawer>
      </Slide>
      {shouldRenderSubPanel(activeNavItem) && <SubPanel userFullName={userFullName} />}
    </Box>
  );
}
