import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../reduxStore/redux-hooks';
import { customAppsWithSpecificSkills } from '../../components/staticComponents/StaticHtmlGenerator';

interface QueryInputProps {
  handleTranscript: () => void;
  currentMessage: string;
  maxLength: number;
  submitQuery: () => void;
  setCurrentMessage: (queryInputValue: string) => void;
}

const QueryInput = forwardRef((props: QueryInputProps, ref) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const maxLength = props.maxLength;
  const warningThreshold = Math.round(maxLength * 0.9);
  const { selectedCustomApp } = useAppSelector((state) => state.customApps.choosenCustomApp);

  const isQueryInputFielddisabled = customAppsWithSpecificSkills.includes(selectedCustomApp);
  const { isResponseBeingGenerated } = useAppSelector((state) => state.commonSlice);

  useEffect(() => {
    if (props.currentMessage) {
      setInputValue(props.currentMessage);
    }
    if (isQueryInputFielddisabled) {
      setInputValue('');
    }
  }, [props.currentMessage, isQueryInputFielddisabled]);

  const __keycatcher = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      props.submitQuery();
      setInputValue('');
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      props.handleTranscript();
    }

    if (event.key === ' ' && inputValue.trim() === '') {
      event.preventDefault();
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setCurrentMessage(event.target.value);
    setInputValue(event.target.value); // Update the input value
  };

  useImperativeHandle(ref, () => ({
    clearInput: () => {
      setInputValue('');
    },
  }));

  return (
    <>
      {(inputValue.length === maxLength || inputValue.length === warningThreshold) && (
        <Box
          data-testid="character-limit"
          sx={{
            color: 'red',
            background: '#ccc',
            borderRadius: '3px',
            padding: '3px 10px',
            fontSize: '14px',
          }}
        >
          {inputValue.length === warningThreshold
            ? t('charecterLengthWarning', { lengthValue: maxLength })
            : t('charecterLengthAlert', { lengthValue: maxLength })}
        </Box>
      )}

      <TextField
        fullWidth
        multiline
        inputProps={{ maxLength }}
        inputRef={(input) => input && input.focus()}
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={__keycatcher}
        autoFocus
        data-testid="standard-basic"
        id="standard-basic"
        variant="outlined"
        disabled={isQueryInputFielddisabled || isResponseBeingGenerated}
        placeholder={t('chatInterface.askText')}
        sx={{
          '& .MuiInputBase-root': {
            paddingRight: { xs: '20px', sm: '20px', lg: '160px' },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          overflowY: 'auto',
          height: '3.8rem',
        }}
      />
    </>
  );
});

//Since we are using forwardRef, Adding a display name to the QueryInput component,
//makes it easier to identify the component in error messages and stack traces.
QueryInput.displayName = 'QueryInput';

export default QueryInput;
