import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Tooltip from '@mui/material/Tooltip';
import { handleEnterOrSpace } from '../../../components/staticComponents/StaticHtmlGenerator';
interface CopyToClipboardProps {
  textToCopy: string;
  plainTextCopy?: string;
  isHtml?: boolean;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy, plainTextCopy, isHtml = false }) => {
  const { t } = useTranslation();
  const [showCopiedIcon, setShowCopiedIcon] = useState(false);
  const COPY_DELAY = 2000;

  const copyResponseText = async () => {
    try {
      if (isHtml && navigator.clipboard) {
        const htmlBlob = new Blob([textToCopy], { type: 'text/html' });
        const textBlob = new Blob([plainTextCopy || ''], { type: 'text/plain' });
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': htmlBlob,
            'text/plain': textBlob,
          }),
        ]);
      } else {
        await navigator.clipboard.writeText(textToCopy);
      }
      setShowCopiedIcon(true);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setShowCopiedIcon(false);
      }, COPY_DELAY);
    }
  };

  return (
    <>
      <Tooltip title={showCopiedIcon ? t('copyToClipboard.tooltipCopied') : t('copyToClipboard.tooltipCopy')} arrow>
        <Box
          role="button"
          tabIndex={0}
          aria-label="Copy to clipboard"
          className={showCopiedIcon ? 'done-svg-icon' : 'copy-svg-icon'}
          sx={{ cursor: showCopiedIcon ? 'default' : 'pointer' }}
          onClick={copyResponseText}
          onKeyDown={(e) => handleEnterOrSpace(e, copyResponseText)}
        >
          {showCopiedIcon ? <DoneRoundedIcon /> : <ContentCopyRoundedIcon />}
        </Box>
      </Tooltip>
      {showCopiedIcon && (
        <Typography
          role="alert"
          aria-live="assertive"
          sx={{ position: 'absolute', left: '-9999px', width: '1px', height: '1px', overflow: 'hidden' }}
        >
          The content has been copied successfully
        </Typography>
      )}
    </>
  );
};
export default CopyToClipboard;
