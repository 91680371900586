import React, { useEffect, useState, useCallback, useContext, useRef, useMemo } from 'react';
import Api, { ApiError, SkillId } from '../../../../../data/api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import BasicPopover from '../../../../sharedComponents/BasicPopover';
import EditDataset from '../editDataset/EditDataset';
import * as assets from '../../../../../assets';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import {
  selectedDataSet,
  ownerDataSet,
  sharedDataSet,
  currentDataSet,
  setOwnerEmail,
  setSelectedDocumentsTabForViewAll,
} from '../../../../../reduxStore/slices/DataSetSlice';
import { useTranslation } from 'react-i18next';
import ImageButton from '../../../../sharedComponents/ImageButton';
import { useSnackbar } from 'notistack';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from '../Dataset';
import { chatPageNavigation, customAppsSkillIds } from '../../../../staticComponents/StaticHtmlGenerator';
import UsePageNavigation from '../../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import {
  SowCommitAiQueryResponse,
  SowPredictorQueryResponse,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
  saveAdvanceSummaryResponse,
  AdvanceSummaryResponse,
  saveSelectedCustomApp,
} from '../../../../../reduxStore/slices/CustomAppsSlice';
import { AppSkillId } from '../../../../staticComponents/StaticHtmlGenerator';
import { UseTrackedNavigate } from '../../../../sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { ActiveNavItemContext } from '../../../RightPanelContext';
import useResetActiveItems from '../../../../sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { updateDatasetState } from '../../../../../reduxStore/slices/FileUploadSlice';
import { showSnackbarErrorMessage } from '../../../../../components/staticComponents/staticUtiles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SharePointDataset } from '../createDataset/existingSharePointdataset/ExistingSharePointDatset';
import { setDocumentPanelExpand } from '../../../../../reduxStore/slices/FileUploadSlice';

export interface Acl {
  users: string[]; // Array of user IDs
}
export interface Dataset {
  _id: string;
  name: string;
  owners: string[];
  acl: Acl;
}

export interface datasetSharedUser {
  id: string;
  name: string;
}

interface DatasetListProps {
  selectedApp: string;
}

const MyDatasets: React.FC<DatasetListProps> = () => {
  const dispatch = useAppDispatch();
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const { refreshDatasetList, selectedDocumentsTabForViewAll } = useAppSelector((state) => state.dataset);

  const { enqueueSnackbar } = useSnackbar();
  const [ownersData, setOwnersData] = useState<{ [key: string]: string[] }>({});
  const [ownersMail, setOwnersMail] = useState<string>('');
  const [datasetSharedUsersList, setDatasetSharedUsersList] = useState<{ [key: string]: datasetSharedUser[] }>({});
  const navigate = UseTrackedNavigate();
  const { isWelcomePage } = UsePageNavigation();
  const resetActiveItems = useResetActiveItems();

  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sharedChecked, setSharedChecked] = useState(false);
  const [ownedChecked, setOwnedChecked] = useState(true);
  const [loading, setLoading] = useState(true);

  const listRef = useRef<HTMLUListElement>(null);
  const [ownedByMeDatasets, setOwnedByMeDatasets] = useState<Dataset[]>();
  const [sharedWithDatasets, setSharedWithMeDatasets] = useState<Dataset[]>();
  const [ownedAndSharedDataset, setOwnedAndSharedDataset] = useState<Dataset[]>();
  const [selectedCustomAppDataset, setSelectedCustomAppDataset] = useState<Dataset[]>();
  const { isCustomAppSelected, selectedCustomAppSkill } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { isCustomAppHistorySelected } = useAppSelector((state) => state.customApps);
  const { documentPanelExpand } = useAppSelector((state) => state.fileUpload);
  const [sharedPointDataset, setSharedPointDataset] = useState<SharePointDataset[]>();
  const [ownedByMeSharePoint, setOwnedByMeSharePoint] = useState<SharePointDataset[]>();
  const [sharedWithSharePoint, setSharedWithMeSharePoint] = useState<SharePointDataset[]>();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setDocumentPanelExpand(isExpanded ? panel : false);
    dispatch(setDocumentPanelExpand(isExpanded ? panel : false));
  };
  const fetchlistofSharedPointDatasets = async () => {
    try {
      const sharedDatasetResponse = await Api.listDatasets(true, false, AppSkillId.sharePoint);
      setSharedPointDataset(sharedDatasetResponse.data as SharePointDataset[]);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };
  const fetchListOfDatasets = async () => {
    try {
      deactivateDataset();
      setLoading(true);
      if (activeNavItem === t('welcomepage.Documents')) {
        const [ownedDatasetsResponse, sharedDatasetResponse, allDatasetResponse] = await Promise.all([
          Api.listDatasets(true, false, AppSkillId.DocCompletion),
          Api.listDatasets(false, true, AppSkillId.DocCompletion),
          Api.listDatasets(true, true, AppSkillId.DocCompletion),
        ]);

        setOwnedByMeDatasets(ownedDatasetsResponse.data as Dataset[]);
        setSharedWithMeDatasets(sharedDatasetResponse.data as Dataset[]);
        setOwnedAndSharedDataset(allDatasetResponse.data as Dataset[]);
      } else {
        const [customAppResponse] = await Promise.all([
          Api.listDatasets(true, true, selectedCustomAppSkill as SkillId),
        ]);
        setSelectedCustomAppDataset(customAppResponse.data as Dataset[]);
      }
      // Fetch SharePoint datasets
      const [ownedSharePointResponse, sharedSharePointResponse, allSharePointResponse] = await Promise.all([
        Api.listDatasets(true, false, AppSkillId.sharePoint),
        Api.listDatasets(false, true, AppSkillId.sharePoint),
        Api.listDatasets(true, true, AppSkillId.sharePoint),
      ]);

      setOwnedByMeSharePoint(ownedSharePointResponse.data as SharePointDataset[]);
      setSharedWithMeSharePoint(sharedSharePointResponse.data as SharePointDataset[]);
      setSharedPointDataset(allSharePointResponse.data as SharePointDataset[]);

      setLoading(false);
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const filteredDatasets = useMemo(() => {
    let datasets: Dataset[] = [];
    if (isCustomAppSelected && activeNavItem !== t('welcomepage.Documents')) {
      datasets = selectedCustomAppDataset ?? [];
    } else if (ownedChecked && sharedChecked) {
      datasets = ownedAndSharedDataset ?? [];
    } else if (ownedChecked) {
      datasets = ownedByMeDatasets ?? [];
    } else if (sharedChecked) {
      datasets = sharedWithDatasets ?? [];
    }

    return datasets.filter((dataset) => dataset.name && dataset.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [
    searchQuery,
    ownedChecked,
    sharedChecked,
    ownedByMeDatasets,
    sharedWithDatasets,
    ownedAndSharedDataset,
    selectedCustomAppSkill,
    selectedCustomAppDataset,
  ]);

  useEffect(() => {
    fetchListOfDatasets();
    fetchlistofSharedPointDatasets();
  }, [refreshDatasetList]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredSharePointDatasets = useMemo(() => {
    let datasets: SharePointDataset[] = [];
    if (ownedChecked && sharedChecked) {
      datasets = sharedPointDataset ?? [];
    } else if (ownedChecked) {
      datasets = ownedByMeSharePoint ?? [];
    } else if (sharedChecked) {
      datasets = sharedWithSharePoint ?? [];
    }

    return datasets.filter((dataset) => dataset.name && dataset.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [
    searchQuery,
    ownedChecked,
    sharedChecked,
    ownedByMeSharePoint,
    sharedWithSharePoint,
    sharedPointDataset,
    selectedCustomAppSkill,
    selectedCustomAppDataset,
  ]);

  const deactivateDataset = () => {
    if (isCustomAppSelected && activeNavItem === t('welcomepage.Documents') && !isCustomAppHistorySelected) {
      dispatch(
        selectedDataSet({
          activeDataSetId: '',
          activeDataSetName: '',
        })
      );
      dispatch(
        currentDataSet({
          currentDataSetName: '',
          currentDataSetId: '',
        })
      );
      dispatch(
        updateDatasetState({
          makeActiveDataset: false,
        })
      );
      dispatch(
        saveSelectedCustomApp({
          isCustomAppSelected: false,
          selectedCustomApp: '',
          selectedCustomAppSkill: '',
          welcomeMessage: '',
        })
      );
    }
  };
  useEffect(() => {
    modifyDocumentsCheckBox();
  }, [selectedDocumentsTabForViewAll]);

  const modifyDocumentsCheckBox = () => {
    const documentsCheckBoxesStateMap = {
      [t('quickView.allDocuments')]: { ownedByMeChecked: true, sharedWithMeChecked: true },
      [t('rightPanel.myDocuments')]: { ownedByMeChecked: true, sharedWithMeChecked: false },
      [t('rightPanel.dataset.sharedWithMe')]: { ownedByMeChecked: false, sharedWithMeChecked: true },
    };

    const { ownedByMeChecked, sharedWithMeChecked } = documentsCheckBoxesStateMap[selectedDocumentsTabForViewAll] || {};

    if (ownedByMeChecked !== undefined && sharedWithMeChecked !== undefined) {
      setOwnedChecked(ownedByMeChecked);
      setSharedChecked(sharedWithMeChecked);
      dispatch(setSelectedDocumentsTabForViewAll(''));
    }
  };

  const getGraphData = useCallback(
    async (dataset: Dataset | SharePointDataset) => {
      try {
        // Fetch user details using the acl.users from the original Dataset interface
        if ('acl' in dataset && dataset.acl) {
          const aclResponse = await Api.getUserDetailsByIds(dataset.acl.users);
          const datasetSharedList: datasetSharedUser[] = dataset.acl.users.map((id) => ({
            id,
            name: aclResponse[id] || id,
          }));
          setDatasetSharedUsersList((prev) => ({ ...prev, [dataset._id]: datasetSharedList }));
          dispatch(
            sharedDataSet({
              sharedDataList: { ...datasetSharedUsersList, [dataset._id]: datasetSharedList },
            })
          );
        }
        const ownersName = await Api.getUserDetailsByIds(dataset.owners);
        const ownerList = dataset.owners.map((id) => ownersName[id] || id);
        setOwnersMail(ownersName['email']);
        dispatch(setOwnerEmail(ownersName['email']));

        //for local data fetch
        setOwnersData((prev) => ({ ...prev, [dataset._id]: ownerList }));

        //for redux data dispatch
        dispatch(
          ownerDataSet({
            ownersDataList: { ...ownersData, [dataset._id]: ownerList },
          })
        );
      } catch (error) {
        showSnackbarErrorMessage(error as ApiError);
        console.log(error);
      }
    },
    [dispatch, enqueueSnackbar, t]
  );
  const handleDatasetSelect = async (dataset: Dataset | SharePointDataset) => {
    try {
      await resetActiveItems(activeNavItem);
      dispatch(
        selectedDataSet({
          activeDataSetName: dataset.name,
          activeDataSetId: dataset._id,
        })
      );
      dispatch(showUserActionContentOnChat(t('fileUpload.exisitingDatasetactiveMessage', { fileName: dataset.name })));
      if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
        getGraphData(dataset);
      }
      //Executing Custom Apps Query when Custom Apps Dataset is selected
      if (activeNavItem === t('welcomepage.Apps')) {
        const sowresponse = await Api.fetchSowQuery(dataset._id, selectedCustomAppSkill as SkillId);

        dispatch(
          selectedCustomAppSkill === AppSkillId.AdvancedSummary
            ? saveAdvanceSummaryResponse(sowresponse.data as AdvanceSummaryResponse)
            : customAppsSkillIds.includes(selectedCustomAppSkill)
              ? saveSowCommitAiQueryResponse(sowresponse.data as SowCommitAiQueryResponse[])
              : saveSowPredictorQueryResponse(sowresponse.data as SowPredictorQueryResponse)
        );
      }
      if (isWelcomePage) {
        navigate(chatPageNavigation);
      }
    } catch (error) {
      showSnackbarErrorMessage(error as ApiError);
    }
  };

  const renderEditPopover = (dataset: Dataset | SharePointDataset) => {
    const handleOpenPopover = () => {
      if (!ownersData[dataset._id]) {
        getGraphData(dataset);
      }
    };

    // Type guard to check if dataset is SharePointDataset
    const isSharePointDataset = (dataset: Dataset | SharePointDataset): dataset is SharePointDataset => {
      return (dataset as SharePointDataset).skill_id !== undefined;
    };

    return (
      <BasicPopover
        srcComponent={<ImageButton srcFile={assets.dots} style={buttonStyle} />}
        onOpen={handleOpenPopover}
        contentComponent={
          <EditDataset
            activeDataSetName={dataset.name}
            activeDataSetId={dataset._id}
            currentDatasetId={dataset._id}
            currentDatasetName={dataset.name}
            isFromDatasetList={true}
            isItemVisible={false}
            ownerListDataset={ownersData[dataset._id] || []}
            ownerMail={ownersMail}
            sharedListDataset={datasetSharedUsersList[dataset._id] || []}
            skillId={isSharePointDataset(dataset) ? dataset.skill_id : undefined} // Use type guard here
          />
        }
      />
    );
  };

  return (
    <Box sx={{ marginTop: '10px' }}>
      {loading ? (
        <CircularProgress size={20} sx={{ marginTop: '10px' }} />
      ) : (
        <>
          <Accordion expanded={documentPanelExpand === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  marginBottom: '0',
                },
                '&.MuiAccordionSummary-root.Mui-expanded': {
                  minHeight: 'inherit',
                  alignItems: 'normal',
                },
              }}
            >
              <Typography
                component="span"
                sx={{ width: 'auto', flexShrink: 0, color: '#6F6C6C', fontWeight: '700', fontSize: '14px' }}
              >
                {t('rightPanel.myDatasets')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!isCustomAppSelected && (
                <Box sx={{ display: 'flex' }}>
                  <FormControlLabel
                    control={<Checkbox checked={ownedChecked} onChange={() => setOwnedChecked((prev) => !prev)} />}
                    label={t('rightPanel.dataset.ownedByMe')}
                    sx={{
                      '& .MuiTypography-root': { fontSize: '13px' }, // Adjust font size here
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={sharedChecked} onChange={() => setSharedChecked((prev) => !prev)} />}
                    label={t('rightPanel.dataset.sharedWithMe')}
                    sx={{
                      '& .MuiTypography-root': { fontSize: '13px' }, // Adjust font size here
                    }}
                  />
                </Box>
              )}

              <TextField
                label={t('rightPanel.labelName')}
                variant="standard"
                value={searchQuery || ''}
                onChange={handleInputChange}
                size="small"
                fullWidth
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                  <List ref={listRef} className="dataList_ul" sx={{ padding: 0 }}>
                    {filteredDatasets.map((listItem) => (
                      <ListItemButton
                        key={listItem._id}
                        disabled={activeDataSetId === listItem._id}
                        sx={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <ListItem
                          key={listItem._id}
                          sx={{
                            color: '#256AFF',
                            transition: 'all 300ms ease-in-out',
                            textDecoration: 'underline',
                            padding: 0,
                            cursor: 'pointer',
                            wordWrap: 'break-word',
                          }}
                        >
                          <ListItemText primary={listItem.name} onClick={() => handleDatasetSelect(listItem)} />
                          {renderEditPopover ? (
                            <span className="buttonShowOnHover">{renderEditPopover(listItem)}</span>
                          ) : null}
                        </ListItem>
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Box>

              {filteredDatasets?.length === 0 && !loading && (
                <Typography color="red">{t('rightPanel.noResult')}</Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={documentPanelExpand === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  marginBottom: '0px',
                },
                '&.MuiAccordionSummary-root.Mui-expanded': {
                  minHeight: 'inherit',
                  alignItems: 'normal',
                },
              }}
            >
              <Typography
                component="span"
                sx={{ width: 'auto', flexShrink: 0, color: '#6F6C6C', fontWeight: '700', fontSize: '14px' }}
              >
                Share Point Documents
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                  control={<Checkbox checked={ownedChecked} onChange={() => setOwnedChecked((prev) => !prev)} />}
                  label={t('rightPanel.dataset.ownedByMe')}
                  sx={{
                    '& .MuiTypography-root': { fontSize: '13px' }, // Adjust font size here
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={sharedChecked} onChange={() => setSharedChecked((prev) => !prev)} />}
                  label={t('rightPanel.dataset.sharedWithMe')}
                  sx={{
                    '& .MuiTypography-root': { fontSize: '13px' }, // Adjust font size here
                  }}
                />
              </Box>

              <TextField
                label={t('rightPanel.labelName')}
                variant="standard"
                value={searchQuery || ''}
                onChange={handleInputChange}
                size="small"
                fullWidth
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                  <List ref={listRef} className="dataList_ul" sx={{ padding: 0 }}>
                    {filteredSharePointDatasets?.map((listItem) => (
                      <ListItemButton
                        key={listItem._id}
                        disabled={activeDataSetId === listItem._id}
                        sx={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <ListItem
                          key={listItem._id}
                          sx={{
                            color: '#256AFF',
                            transition: 'all 300ms ease-in-out',
                            textDecoration: 'underline',
                            padding: 0,
                            cursor: 'pointer',
                            wordWrap: 'break-word',
                          }}
                        >
                          <ListItemText primary={listItem.name} onClick={() => handleDatasetSelect(listItem)} />
                          {renderEditPopover ? (
                            <span className="buttonShowOnHover">{renderEditPopover(listItem)}</span>
                          ) : null}
                        </ListItem>
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Box>

              {filteredSharePointDatasets?.length === 0 && !loading && (
                <Typography color="red">{t('rightPanel.noResult')}</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
};
export default MyDatasets;
