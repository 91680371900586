export enum SubPanelClasses {
  CreateAgentSubPanel = 'create-agent-sub-panel',
}

export enum SubPanelAssistants {
  agent = 'agent',
  team = 'team',
  dataset = 'dataset',
  app = 'app',
  releaseNotes = 'releaseNotes',
}
