import { Box, Tooltip } from '@mui/material';
import { getAuthInstance } from '../../../../helpers/Auth';
import React from 'react';
import ImageButton from '../../../sharedComponents/ImageButton';
import * as assets from '../../../../assets';
import { useTranslation } from 'react-i18next';

function Logout() {
  const handleLogoutClick = () => {
    getAuthInstance().then(async (Auth) => await Auth.logout());
  };
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t('welcomepage.Logout').charAt(0).toUpperCase() + t('welcomepage.Logout').slice(1).toLowerCase()}
      arrow
      placement="left"
    >
      <Box
        id={'logout'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 65,
          position: 'relative',
          bottom: 1,
        }}
      >
        <Box
          role="button"
          aria-label={t('welcomepage.Logout')}
          tabIndex={0}
          sx={{
            height: 40,
            width: 40,
            backgroundColor: '#FFF3F3',
            borderRadius: '7px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': { cursor: 'pointer' },
          }}
        >
          <ImageButton srcFile={assets.logout} handleClick={handleLogoutClick} />
        </Box>
      </Box>
    </Tooltip>
  );
}

export default Logout;
