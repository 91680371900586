import { Avatar, Box, Chip, IconButton } from '@mui/material';
import React, { useState } from 'react';
import AgentsInfo from './agentsInfo/AgentsInfo';
import DatasetInfo from './datasetInfo/DatasetInfo';
import { useTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { getTenantName } from '../../../MsalConfig';
import { TenantNames } from '../../../components/staticComponents/StaticHtmlGenerator';
import { LogButtonEventToGA } from '../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';

function QuickView() {
  const [selectedAssistant, setSelectedAssistant] = useState<string>('');
  const { t } = useTranslation();
  const tenantName = getTenantName();

  const getQuickViewComponents = () => {
    const components = [
      {
        title: t('quickView.document'),
        component: DatasetInfo,
        icon: DescriptionOutlinedIcon,
      },
    ];

    if (tenantName !== TenantNames.StateStreet) {
      components.unshift({
        title: t('quickView.agent'),
        component: AgentsInfo,
        icon: PersonOutlineOutlinedIcon,
      });
    }

    return components;
  };

  const mapQuickViewcomponents = getQuickViewComponents();

  const loadQuickViewComponents = (selectedAssist: string) => {
    setSelectedAssistant(selectedAssist);
    LogButtonEventToGA(selectedAssist);
  };
  const removeAssistantInfo = () => {
    setSelectedAssistant('');
  };

  const loadAssistantsInfo = () => {
    const selectedAssist = mapQuickViewcomponents.find(
      (quickViewComponent) => quickViewComponent.title === selectedAssistant
    );
    if (selectedAssist) {
      const SelectedComponent = selectedAssist.component;
      return <Box>{SelectedComponent && <SelectedComponent />}</Box>;
    }
  };

  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>
        {mapQuickViewcomponents.map((assistant, index) => {
          const IconComponent = assistant.icon;
          const isActive = selectedAssistant === assistant.title;
          return (
            <Chip
              avatar={
                <Avatar sx={{ background: 'transparent' }}>
                  {IconComponent && <IconComponent sx={{ color: isActive ? '#fff' : '#000' }} />}
                </Avatar>
              }
              key={index}
              sx={{
                padding: '18px 15px',
                cursor: 'pointer',
                borderRadius: '6px',
                margin: '0 4px',
                background: isActive ? '#3C82F6' : '#fff',
                color: isActive ? '#fff' : '#000',
                border: isActive ? '1px solid #3C82F6' : '1px solid #bdbdbd',
                fontSize: '14px',
                textTransform: 'capitalize',
                '&.MuiChip-clickable:hover': {
                  background: isActive ? '#1565c0' : 'transparent',
                  color: isActive ? '#fff' : '#000',
                },
              }}
              label={assistant.title}
              onClick={() => loadQuickViewComponents(assistant.title)}
              variant="outlined"
            />
          );
        })}
      </Box>

      {selectedAssistant && (
        <Box sx={{ padding: '15px', background: '#fff', position: 'relative', marginTop: '30px' }}>
          <IconButton
            onClick={() => removeAssistantInfo()}
            aria-label="close"
            sx={{ position: 'absolute', right: '0', top: '-20px', marginTop: '-20px' }}
          >
            <CloseIcon />
          </IconButton>

          <Box>{loadAssistantsInfo()}</Box>
        </Box>
      )}
    </Box>
  );
}

export default QuickView;
