import { UserDetails } from '../../components/rightPanel/subpanel/developerKey/DeveloperKey';
import { MyTeamsData } from '../..//components/rightPanel/subpanel/teams/myTeams/MyTeams';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TeamActivationProcess {
  ShowTeamActivationDialogOnChat: boolean;
  activationTeam: MyTeamsData;
}

interface TeamSharedUsers {
  users: UserDetails[];
}

interface TeamsState {
  activeTeam: MyTeamsData;
  teamActivationProcess: TeamActivationProcess;
  teamSharedUsers: TeamSharedUsers;
  updateTeamDetails: MyTeamsData;
  isTeamToBeUpdated: boolean;
  refetchTeamsList: boolean;
  isTeamView: boolean;
  isTeamOwner: boolean;
}

const teamsInitialState = (): MyTeamsData => ({
  name: '',
  _id: '',
  description: '',
  assistant_ids: [],
  owners: [],
  acl: { users: [] },
});

const initialState: TeamsState = {
  activeTeam: teamsInitialState(),
  teamActivationProcess: {
    ShowTeamActivationDialogOnChat: false,
    activationTeam: teamsInitialState(),
  },
  teamSharedUsers: {
    users: [],
  },
  updateTeamDetails: teamsInitialState(),
  isTeamToBeUpdated: false,
  refetchTeamsList: false,
  isTeamOwner: false,
  isTeamView: false,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    activeTeamMethod: (state, action: PayloadAction<MyTeamsData>) => {
      state.activeTeam = action.payload;
    },
    teamActivationMethod: (state, action: PayloadAction<TeamActivationProcess>) => {
      state.teamActivationProcess = action.payload;
    },
    teamSharedUsers: (state, action: PayloadAction<TeamSharedUsers>) => {
      state.teamSharedUsers = action.payload;
    },
    updateTeam: (state, action: PayloadAction<MyTeamsData>) => {
      state.updateTeamDetails = action.payload;
    },
    confirmTeamUpdate: (state, action: PayloadAction<boolean>) => {
      state.isTeamToBeUpdated = action.payload;
    },
    refreshTeamsList: (state, action: PayloadAction<boolean>) => {
      state.refetchTeamsList = action.payload;
    },
    resetActiveTeam: () => ({ ...initialState, activeTeam: teamsInitialState() }),
    resetTeamActivationProcess: () => ({
      ...initialState,
      teamActivationProcess: {
        ShowTeamActivationDialogOnChat: false,
        activationTeam: teamsInitialState(),
      },
    }),
    resetUpdateTeamDetails: () => ({ ...initialState, updateTeamDetails: teamsInitialState() }),
    setIsTeamView: (state, action: PayloadAction<boolean>) => {
      state.isTeamView = action.payload;
    },
    setIsTeamOwner: (state, action: PayloadAction<boolean>) => {
      state.isTeamOwner = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  activeTeamMethod,
  teamActivationMethod,
  teamSharedUsers,
  updateTeam,
  confirmTeamUpdate,
  refreshTeamsList,
  resetActiveTeam,
  resetTeamActivationProcess,
  resetUpdateTeamDetails,
  setIsTeamView,
  setIsTeamOwner,
} = teamsSlice.actions;

export default teamsSlice.reducer;
